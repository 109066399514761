export interface NotificationDetails {
  header?: string;
  body?: string;
  type: NotificationType;
}

export enum NotificationType {
  Danger = "danger",
  Info = "info",
  Warning = "warning",
}
