import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useGetActivities } from "../../../../services/activities";
import { useGetMeetings } from "../../../../services/meetings";
import { useGetCourses } from "../../../../services/courses";
import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import { Card } from "../../../../components/Card/Card";
import { MeetingStatus } from "../../../../types/meeting";

type OverviewProps = React.HTMLAttributes<HTMLDivElement>;

export const Overview: FunctionComponent<OverviewProps> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);

  const [totalMeetings, setTotalMeetings] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);
  const [scheduledMeetings, setScheduledMeetings] = useState(0);
  const [finishedMeetings, setFinishedMeetings] = useState(0);
  const [avgParticipantsInMeeting, setAvgParticipantsInMeeting] = useState(0);
  const [totalCourses, setTotalCourses] = useState(0);
  const [avgParticipantsInCourse, setAvgParticipantsInCourse] = useState(0);

  const { data: meetings } = useGetMeetings({
    userId: signedInUserId,
    teamId: contextTeam?.id,
  });

  const { data: activities } = useGetActivities(contextTeam?.id);

  const { data: courses } = useGetCourses(contextTeam?.id);

  useEffect(() => {
    if (!meetings?.length) return;

    const meetingsStats = meetings.reduce(
      (acc, meeting) => {
        if (
          meeting.status === MeetingStatus.IN_PROGRESS ||
          meeting.status === MeetingStatus.SCHEDULED
        ) {
          acc.scheduledMeetings++;
        } else {
          acc.finishedMeetings++;
          acc.avgParticipantsInMeeting += meeting.participants?.length || 0;
        }
        acc.totalMeetings++;
        return acc;
      },
      {
        totalMeetings: 0,
        scheduledMeetings: 0,
        finishedMeetings: 0,
        avgParticipantsInMeeting: 0,
      }
    );

    meetingsStats.avgParticipantsInMeeting = +(
      meetingsStats.avgParticipantsInMeeting / meetingsStats.finishedMeetings
    ).toFixed(1);

    const {
      totalMeetings,
      scheduledMeetings,
      finishedMeetings,
      avgParticipantsInMeeting,
    } = meetingsStats;
    setTotalMeetings(totalMeetings);
    setScheduledMeetings(scheduledMeetings);
    setFinishedMeetings(finishedMeetings);
    setAvgParticipantsInMeeting(avgParticipantsInMeeting);
  }, [meetings]);

  useEffect(() => {
    setTotalActivities(activities?.length || 0);
  }, [activities]);

  useEffect(() => {
    if (!courses?.length) return;

    const courseStats = courses.reduce(
      (acc, course) => {
        const participantsCount = Object.keys(course.participants || {}).length;
        acc.totalCourses++;
        acc.avgParticipantsInCourse += participantsCount;
        return acc;
      },
      {
        totalCourses: 0,
        avgParticipantsInCourse: 0,
      }
    );
    courseStats.avgParticipantsInCourse = +(
      courseStats.avgParticipantsInCourse / courseStats.totalCourses
    ).toFixed(1);

    const { totalCourses, avgParticipantsInCourse } = courseStats;
    setTotalCourses(totalCourses);
    setAvgParticipantsInCourse(avgParticipantsInCourse);
  }, [courses]);

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Overview</h4>
        </div>
      </div>
      <Card className="mt-4 mt-md-2">
        <div className="row">
          <div className="col-12 col-md-4 text-center mb-4 mb-lg-0">
            <div className="font-weight-bold font-lg">
              {totalActivities || "N/A"}
            </div>
            <h4>Activities</h4>
          </div>
          <div className="col-12 col-md-4 text-center mb-4 mb-lg-0">
            <div>
              <div className="font-weight-bold font-lg">
                {totalMeetings || "N/A"}
              </div>
              <h4 className="mb-0">Meetings</h4>
            </div>
            <div className="pt-3 text-muted d-flex flex-column align-items-center">
              <div className="d-flex justify-content-between font-weight-bold mb-1 w-200px">
                <div>Scheduled</div>
                <div>{scheduledMeetings || "N/A"}</div>
              </div>
              <div className="d-flex justify-content-between font-weight-bold mb-1 w-200px">
                <div>Completed</div>
                <div>{finishedMeetings || "N/A"}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div className="font-weight-bold font-lg">
              {avgParticipantsInMeeting || "N/A"}
            </div>
            <h4>
              Avg. participants
              <br />
              in a meeting
            </h4>
          </div>
        </div>
      </Card>
      <Card className="mt-4 mt-md-2">
        <div className="row">
          <div className="col" />
          <div className="col-12 col-md-4 text-center mb-4 mb-lg-0">
            <div className="font-weight-bold font-lg">
              {totalCourses || "N/A"}
            </div>
            <h4>Courses</h4>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div>
              <div className="font-weight-bold font-lg">
                {avgParticipantsInCourse || "N/A"}
              </div>
              <h4>
                Avg. participants
                <br />
                in a course
              </h4>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
