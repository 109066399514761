import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface PageNotFoundProps extends React.HTMLAttributes<HTMLDivElement> {
  goTo?: string;
}

export const PageNotFound: FunctionComponent<PageNotFoundProps> = ({
  goTo = "/",
}) => {
  return (
    <div className="p-2">
      Page not found
      <br />
      <Link to={goTo}>Go home</Link>
    </div>
  );
};
