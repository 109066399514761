import { Timestamp } from "firebase/firestore";
import { Activity } from "./activities/activity";
import { DBDocumentID } from "./document";
import { UID, AppUser } from "./user";

export enum MeetingStatus {
  SCHEDULED = "SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}

export interface Meeting {
  id?: DBDocumentID;
  teamId: DBDocumentID;
  hostId: UID;
  name?: string;
  description?: string;
  activityIds?: string[];
  activities?: Activity[];
  participants?: AppUser[];
  status?: MeetingStatus;
  plannedStartTimestamp: number;
  plannedEndTimestamp: number;
  actualStartTimestamp?: number;
  actualEndTimestamp?: number;
  isCourse?: boolean;
  courseId?: DBDocumentID;
}

export interface MeetingMetadata {
  meetingId: DBDocumentID;
  metadata: Record<DBDocumentID, ActivityMetadata>;
}

export type ActivityMetadata =
  | QuestRoomActivityMetadata
  | FreeMapActivityMetadata
  | null;

export interface PuzzleActivityMetadata {
  puzzlesState: PuzzleState[];
  systemMessages: SystemMessages[];
}

export type QuestRoomActivityMetadata = PuzzleActivityMetadata;

export interface DiceRoll {
  number: number;
  lastRolledAt?: Timestamp;
}

export interface FreeMapActivityMetadata extends PuzzleActivityMetadata {
  meeples: Meeple[];
  diceRoll: DiceRoll;
}

export interface SystemMessages {
  uid: UID;
  hasSeenGreetingModal: boolean;
  hasSeenSuccessModal: boolean;
}

export enum SystemMessagesType {
  GREETING = "greeting",
  SUCCESS = "success",
}

export interface Meeple {
  imageSrc?: string;
  x: number;
  y: number;
  uid: UID;
  updatedAt: Timestamp;
}

export interface PuzzleState {
  isSolved: boolean;
  solvedAt?: number;
  solvedBy?: {
    uid: UID;
  };
}
