import React, { FunctionComponent, useEffect, useRef } from "react";
import { FileTypesList } from "../../types/input";

interface InputSelectProps extends React.InputHTMLAttributes<HTMLInputElement> {
  src: string;
  acceptFile: FileTypesList;
  display?: "block" | "inline";
}

export const InputFile: FunctionComponent<InputSelectProps> = ({
  className,
  children,
  src,
  acceptFile,
  display = "block",
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!src && fileInputRef.current) fileInputRef.current.value = "";
  }, [src]);

  return (
    <label className={`d-${display}`}>
      <input
        ref={fileInputRef}
        type="file"
        {...props}
        accept={acceptFile.join(", ")}
        className={`d-none ${className || ""}`}
      />
      {children}
    </label>
  );
};
