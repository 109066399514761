import { FunctionComponent, useEffect, useState, ReactNode } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { useGetCourseById, useSaveCourse } from "../../services/courses";
import { notificationsAtom } from "../../state/atoms/notifications";
import { NotificationType } from "../../types/notification";
import {
  useSaveMeeting,
  useStartMeeting,
  useAddUserMeeple,
  useEndMeeting,
} from "../../services/meetings";
import { Timestamp, Unsubscribe } from "firebase/firestore";
import { CoverPage } from "../../components/CoverPage/CoverPage";
import { Loading } from "../../components/Loading/Loading";
import { Button } from "../../components/Button/Button";
import { Meeting, MeetingMetadata, MeetingStatus } from "../../types/meeting";
import { ERROR_TOAST_DEFAULT_BODY } from "../../types/ui";
import { isEndCourseDialogOpenAtom } from "../../state/atoms/ui";
import {
  contextMeetingAtom,
  contextMeetingMetadataAtom,
} from "../../state/atoms/meetings";
import { useWindowDimensions } from "../../hooks";
import { AppUser } from "../../types/user";
import { Participant } from "../../components/Participant/Participant";
import {
  subToMeeting,
  subToMeetingMetadata,
  createMeetingMetadataRequest,
} from "../../services/firebase";
import { logger } from "../../services/logger";
import { DBDocument, DBDocumentID } from "../../types/document";
import { ActivityPlayer } from "../../components/ActivityPlayer/ActivityPlayer";
import { MeetingControlsContainer } from "../Meeting/MeetingControlsContainer";
import { Dialog } from "../../components/Dialog/Dialog";
import { AppRoutes, SubRoutes } from "../../services/consts";
import { useGetUser } from "../../services/users";
import { dateLocaleFormat } from "../../services/utils";

type CourseProps = React.HTMLAttributes<HTMLDivElement>;

export const Course: FunctionComponent<CourseProps> = () => {
  const { courseId, meetingId } = useParams();
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);
  const navigate = useNavigate();
  const setNotificationsDetails = useSetRecoilState(notificationsAtom);
  const [savedMeetingId, setSavedMeetingId] = useState<string>("");
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [isEndCourseDialogOpen, setIsEndCourseDialogOpen] = useRecoilState(
    isEndCourseDialogOpenAtom
  );

  const [contextMeeting, setContextMeeting] =
    useRecoilState(contextMeetingAtom);
  const setMeetingMetadata = useSetRecoilState(contextMeetingMetadataAtom);

  const { windowWidth, windowHeight } = useWindowDimensions();
  const minSide = Math.min(windowWidth, windowHeight);

  const participantSize = minSide / 8 - 2;
  const participantSizeVw = (participantSize / windowWidth) * 100;
  const containerWidth = ((windowHeight - participantSize - 64) * 12) / 9;

  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus | null>(
    null
  );
  const [meetingActivityId, setMeetingActivityId] = useState<string | "">("");
  const isStartCourse = courseId && !meetingId;
  const isCourseStarted = courseId && meetingId && signedInUser;

  const { data: course, refetch: refetchCourse } = useGetCourseById(
    courseId || "",
    { enabled: false }
  );

  const { mutateAsync: handleSaveCourse, isLoading: isSavingCourse } =
    useSaveCourse();

  const { mutateAsync: handleSaveMeeting, isLoading: isSavingMeeting } =
    useSaveMeeting();

  const { mutateAsync: handleStartMeeting, isLoading: isStartingMeeting } =
    useStartMeeting();

  const { mutateAsync: handleAddUserMeeple, isLoading: isAddingMeeple } =
    useAddUserMeeple(savedMeetingId);

  const { mutateAsync: handleEndMeeting, isLoading: isEnding } =
    useEndMeeting();

  const createAndStartCourseMeeting = async () => {
    try {
      if (!signedInUser || !course) return;

      const meeting: Meeting = {
        teamId: course.teamId,
        hostId: course.hostId,
        activityIds: course.activityIds,
        participants: [
          {
            ...signedInUser,
          },
        ],
        name: course.name,
        plannedStartTimestamp: course.startsAt,
        plannedEndTimestamp: course.endsAt,
        isCourse: true,
        courseId,
      };
      const savedMeeting = await handleSaveMeeting(meeting);
      setSavedMeetingId(savedMeeting.meetingId);

      if (savedMeeting?.meetingId) {
        const newParticipantMeeting = {
          meetingId: savedMeeting.meetingId,
          joinedAt: Timestamp.now(),
        };

        const updatedParticipants = {
          ...course.participants,
          [signedInUserId]: [
            ...(course.participants[signedInUserId] || []),
            newParticipantMeeting,
          ],
        };

        const updatedCourse = {
          ...course,
          participants: updatedParticipants,
        };

        await handleSaveCourse({
          course: updatedCourse,
          uid: signedInUserId,
        });

        await handleStartMeeting({ meetingId: savedMeeting.meetingId });

        await handleAddUserMeeple({
          uid: signedInUserId,
        });
      }
    } catch (error) {
      setNotificationsDetails((prevState) => [
        ...prevState,
        {
          header: "Error starting course meeting",
          type: NotificationType.Danger,
        },
      ]);
    }
  };

  const renderParticipant = (participant: AppUser) => {
    return (
      <div
        style={{
          width: `${participantSizeVw}vw`,
          height: `${participantSizeVw}vw`,
          padding: `${participantSizeVw * 0.05}vw`,
        }}
      >
        <Participant
          className="ar-1-1"
          user={participant}
          activityId={meetingActivityId}
        />
      </div>
    );
  };

  const handleEndCourse = async ({ meetingId }: { meetingId: string }) => {
    try {
      await handleEndMeeting({ meetingId });

      if (course?.participants && signedInUser) {
        const updatedParticipants = { ...course.participants };

        const participantMeetings = updatedParticipants[signedInUserId];

        participantMeetings[0].finishedAt = Timestamp.now();

        await handleSaveCourse({
          course: {
            ...course,
            participants: updatedParticipants,
          },
          uid: signedInUserId,
        });
      }
    } catch (error) {
      setNotificationsDetails((prevState) => [
        ...prevState,
        {
          header: "Error ending course",
          body: ERROR_TOAST_DEFAULT_BODY,
          type: NotificationType.Danger,
        },
      ]);
    }
  };

  const isLoading = isStartingMeeting || isAddingMeeple || isLoadingUser;
  const isStartingCourse = isSavingCourse || isSavingMeeting;
  const currentTime = new Date().getTime();
  const canJoinCourse =
    course && course.startsAt <= currentTime && course.endsAt >= currentTime;

  useEffect(() => {
    const handleCourseNavigation = async () => {
      const { isSuccess, data: refreshedCourse } = await refetchCourse();
      if (!isSuccess || !signedInUserId) return;

      const userMeeting = refreshedCourse.participants[signedInUserId];
      const targetRoute =
        userMeeting && userMeeting[0]?.meetingId
          ? `${AppRoutes.COURSE}/${courseId}${SubRoutes.ENV}/${userMeeting[0]?.meetingId}`
          : `${AppRoutes.COURSE}/${courseId}`;

      navigate(targetRoute);
    };

    if (signedInUserId && courseId) {
      handleCourseNavigation();
    }
  }, [signedInUserId, meetingId, courseId, navigate, refetchCourse]);

  useEffect(() => {
    if (savedMeetingId) {
      setIsNavigating(true);
      navigate(
        `${AppRoutes.COURSE}/${courseId}${SubRoutes.ENV}/${savedMeetingId}`
      );
    }
  }, [savedMeetingId, courseId, navigate]);

  useEffect(() => {
    if (contextMeeting?.activityIds?.[0]) {
      setMeetingActivityId(contextMeeting.activityIds[0]);
    }
  }, [contextMeeting]);

  useEffect(() => {
    let unsubFromMeeting: Unsubscribe;
    let unsubFromMeetingMetadata: Unsubscribe;

    const subToMeetingInfo = async () => {
      if (meetingId && signedInUserId) {
        unsubFromMeeting = subToMeeting(meetingId as DBDocumentID, (doc) => {
          if (doc.exists()) {
            const meeting = doc.data() as DBDocument<Meeting>;
            logger.debug("Meeting doc updated", doc.data());
            setMeetingStatus(meeting.status || null);
            setContextMeeting(meeting);
          } else {
            setMeetingStatus(MeetingStatus.DELETED);
          }
        });

        const subscribeToMeetingMetadata = () =>
          subToMeetingMetadata(meetingId as DBDocumentID, async (doc) => {
            if (doc.exists()) {
              const meetingMetadata = doc.data() as DBDocument<MeetingMetadata>;
              logger.debug("Meeting metadata doc updated", meetingMetadata);
              setMeetingMetadata(meetingMetadata);
            } else {
              await createMeetingMetadataRequest(meetingId);
              unsubFromMeetingMetadata = subscribeToMeetingMetadata();
            }
          });

        unsubFromMeetingMetadata = subscribeToMeetingMetadata();
      }
    };
    subToMeetingInfo();
    return () => {
      unsubFromMeeting?.();
      unsubFromMeetingMetadata?.();
    };
  }, [meetingId, signedInUserId, setContextMeeting, setMeetingMetadata]);

  if (!course) return <Loading fullscreen />;
  else if (isStartCourse) {
    return isLoading || isNavigating ? (
      <Loading fullscreen />
    ) : (
      <CoverPage>
        {canJoinCourse ? (
          <>
            <h2>This course has not started yet</h2>
            <Button
              btnSize="lg"
              className="mt-2"
              disabled={isStartingCourse}
              onClick={async () => {
                await createAndStartCourseMeeting();
              }}
            >
              {isStartingCourse ? <Loading /> : "Start Course"}
            </Button>
          </>
        ) : (
          <>
            <h2>This course is not available right now</h2>
            <h5>
              This course is available from{" "}
              {new Date(course.startsAt).toLocaleString(dateLocaleFormat)} to{" "}
              {new Date(course.endsAt).toLocaleString(dateLocaleFormat)}.
            </h5>
          </>
        )}
      </CoverPage>
    );
  } else if (isCourseStarted) {
    if (!meetingStatus) {
      return <Loading fullscreen />;
    }
    if (meetingStatus !== MeetingStatus.IN_PROGRESS) {
      let coverPageContent: ReactNode = <></>;
      switch (meetingStatus) {
        case MeetingStatus.COMPLETED:
          coverPageContent = (
            <>
              <h2>This course has ended</h2>
              <h4>
                <i>We hope to see you again soon!</i>
              </h4>
            </>
          );
          break;
        case MeetingStatus.DELETED:
          coverPageContent = (
            <>
              <h2>This course does not exist</h2>
              <h4>
                <i>Please contact the meeting host for details</i>
              </h4>
            </>
          );
          break;
        default:
          coverPageContent = (
            <>
              <h2>Something went completely wrong</h2>
              <h4>
                <i>Please contact the support to fix this</i>
              </h4>
            </>
          );
          break;
      }

      return <CoverPage>{coverPageContent}</CoverPage>;
    }

    return (
      <div className="p-0">
        <div className="meeting">
          <div className="align-self-center">
            <div
              className="d-flex justify-content-center p-0"
              style={{
                minHeight: `${participantSizeVw}vw`,
              }}
            >
              {renderParticipant(signedInUser)}
            </div>
            <div className="p-0">
              <div
                className="m-auto"
                style={{
                  maxWidth: `${containerWidth}px`,
                }}
              >
                <ActivityPlayer activityId={meetingActivityId} />
              </div>
            </div>
          </div>
        </div>
        <MeetingControlsContainer>
          <div
            style={{
              width: `${containerWidth}px`,
              padding: `${participantSizeVw * 0.04}vw`,
            }}
            className="position-relative m-auto"
          >
            <div className="row p-0 m-0">
              <div className="col p-0 align-self-end">
                <Button
                  btnType="danger"
                  className="float-end"
                  disabled={isEnding}
                  onClick={() => {
                    setIsEndCourseDialogOpen(true);
                  }}
                >
                  {isEnding ? <Loading /> : "End course"}
                </Button>
              </div>
            </div>
          </div>
        </MeetingControlsContainer>
        {isEndCourseDialogOpen && (
          <Dialog
            header="Are you sure you want to end the course?"
            state={isEndCourseDialogOpenAtom}
            onApprove={async () => {
              if (contextMeeting) {
                handleEndCourse({ meetingId });
              }
            }}
            approveText="Yes"
            cancelText="No"
          />
        )}
      </div>
    );
  }
  return <></>;
};
