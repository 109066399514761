import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  useDeleteMeeting,
  useGetMeetings,
} from "../../../../services/meetings";
import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import {
  isUpsertMeetingModalOpenAtom,
  isDeleteMeetingDialogOpenAtom,
} from "../../../../state/atoms/ui";
import { Button } from "../../../../components/Button/Button";
import { Card } from "../../../../components/Card/Card";
import { Loading } from "../../../../components/Loading/Loading";
import { notificationsAtom } from "../../../../state/atoms/notifications";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { upsertMeetingFormValuesAtom } from "../../../../state/atoms/meetings";
import { Activity } from "../../../../types/activities/activity";
import { Meeting, MeetingStatus } from "../../../../types/meeting";
import "./Meetings.css";
import {
  baseUrl,
  dateLocaleFormat,
  generateGoogleCalendarMeetingUrl,
} from "../../../../services/utils";
import { NotificationType } from "../../../../types/notification";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { AppRoutes } from "../../../../services/consts";
import { DBDocument } from "../../../../types/document";

type MeetingsProps = React.HTMLAttributes<HTMLDivElement>;

export const Meetings: FunctionComponent<MeetingsProps> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const setIsUpsertMeetingModalOpen = useSetRecoilState(
    isUpsertMeetingModalOpenAtom
  );
  const setUpsertMeetingFormValues = useSetRecoilState(
    upsertMeetingFormValuesAtom
  );
  const setIsDeleteMeetingDialogOpen = useSetRecoilState(
    isDeleteMeetingDialogOpenAtom
  );

  const setNotificationDetails = useSetRecoilState(notificationsAtom);
  const [chosenMeetingId, setChosenMeetingId] = useState<string | "">("");
  const [chosenMeetingStatusTab, setChosenMeetingStatusTab] =
    useState<MeetingStatus>(MeetingStatus.SCHEDULED);
  const [filteredMeetings, setFilteredMeetings] = useState<
    DBDocument<Meeting>[] | null
  >(null);

  const { isLoading, data: meetings } = useGetMeetings({
    userId: signedInUserId,
    teamId: contextTeam?.id,
  });

  const { mutateAsync: handleDeleteMeeting } = useDeleteMeeting();

  useEffect(() => {
    const handleFilterMeetings = () => {
      const filteredMeetings = meetings?.filter(
        ({ status }) => status === chosenMeetingStatusTab
      );
      if (filteredMeetings) setFilteredMeetings(filteredMeetings);
    };

    handleFilterMeetings();
  }, [meetings, chosenMeetingStatusTab]);

  const isParticipantsColumnShown =
    chosenMeetingStatusTab !== MeetingStatus.SCHEDULED;

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Meetings</h4>
        </div>
        <div className="col py-0">
          <Button
            className="float-end"
            onClick={() => setIsUpsertMeetingModalOpen(true)}
          >
            Create
          </Button>
        </div>
      </div>
      <nav className="mt-4 mt-md-2">
        <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
          <button
            className={`nav-link text-dark ${
              chosenMeetingStatusTab === MeetingStatus.SCHEDULED
                ? "active card rounded-0 rounded-top border-white"
                : ""
            }`}
            onClick={async () => {
              setChosenMeetingStatusTab(MeetingStatus.SCHEDULED);
            }}
          >
            Scheduled
          </button>
          <button
            className={`nav-link text-dark ${
              chosenMeetingStatusTab === MeetingStatus.IN_PROGRESS
                ? "active card rounded-0 rounded-top border-white"
                : ""
            }`}
            onClick={async () => {
              setChosenMeetingStatusTab(MeetingStatus.IN_PROGRESS);
            }}
          >
            In progress
          </button>
          <button
            className={`nav-link text-dark ${
              chosenMeetingStatusTab === MeetingStatus.COMPLETED
                ? "active card rounded-0 rounded-top border-white"
                : ""
            }`}
            onClick={async () => {
              setChosenMeetingStatusTab(MeetingStatus.COMPLETED);
            }}
          >
            Completed
          </button>
        </div>
      </nav>
      <Card
        className={`${
          chosenMeetingStatusTab === MeetingStatus.SCHEDULED
            ? "rounded-0 rounded-bottom"
            : ""
        }`}
      >
        {isLoading ? (
          <Loading />
        ) : filteredMeetings && filteredMeetings.length > 0 ? (
          <>
            <div className="row my-2">
              <div className="col-6 col-xl-2 font-weight-bold text-truncate">
                Title
              </div>
              <div
                className={`d-none d-xl-block ${
                  isParticipantsColumnShown ? "col-xl-2" : "col-xl-3"
                } font-weight-bold text-truncate`}
              >
                Activity
              </div>
              {isParticipantsColumnShown && (
                <div className="d-none d-xl-block col-xl-1 font-weight-bold text-truncate">
                  Participants
                </div>
              )}
              <div className="d-none d-xl-block col-xl-2 font-weight-bold text-truncate">
                Start time
              </div>
              <div className="d-none d-xl-block col-xl-2 font-weight-bold text-truncate">
                End time
              </div>
              <div className="col-6 col-xl-3 font-weight-bold text-truncate">
                Actions
              </div>
            </div>
            {filteredMeetings.map((meeting, idx) => {
              const {
                id,
                name: title,
                plannedStartTimestamp,
                plannedEndTimestamp,
                activities,
                participants,
              } = meeting;
              return (
                <div key={`meetings-${idx}-${id}`} className="row my-2">
                  <div className="col-6 col-xl-2 pt-1 align-self-center text-truncate">
                    {title || <i className="text-muted">Untitled meeting</i>}
                  </div>
                  <div
                    className={`d-none d-xl-block ${
                      isParticipantsColumnShown ? "col-xl-2" : "col-xl-3"
                    } text-truncate align-self-center`}
                  >
                    {activities?.map((activity: Activity) => (
                      <div key={`activity-${activity.id}`}>
                        {activity.name || (
                          <i className="text-muted">Untitled activity</i>
                        )}
                      </div>
                    ))}
                  </div>
                  {isParticipantsColumnShown && (
                    <div className="d-none d-xl-block col-xl-1 text-truncate align-self-center">
                      {participants?.length || 0}
                    </div>
                  )}
                  <div className="d-none d-xl-block col-xl-2 pt-1 align-self-center text-truncate">
                    {new Date(plannedStartTimestamp).toLocaleString(
                      dateLocaleFormat
                    )}
                  </div>
                  <div className="d-none d-xl-block col-xl-2 pt-1 align-self-center text-truncate">
                    {new Date(plannedEndTimestamp).toLocaleString(
                      dateLocaleFormat
                    )}
                  </div>
                  <div className="col-6 col-xl-3 align-self-center">
                    {chosenMeetingStatusTab !== MeetingStatus.COMPLETED && (
                      <>
                        <Link
                          to={`${AppRoutes.MEET}/${id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-0 m-0 border-0 btn btn-link"
                        >
                          <Button
                            btnSize="sm"
                            btnType="secondary"
                            tooltipProps={{ content: "Open" }}
                            showTooltip
                          >
                            <Icon icon={Icons.LINK} />
                          </Button>
                        </Link>
                        <Button
                          tooltipProps={{ content: "Copy meeting URL" }}
                          showTooltip
                          btnSize="sm"
                          btnType="secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${baseUrl}${AppRoutes.MEET}/${id}`
                            );
                            setNotificationDetails((prevState) => [
                              ...prevState,
                              {
                                header: "Meeting URL copied to clipboard",
                                type: NotificationType.Info,
                              },
                            ]);
                          }}
                        >
                          <Icon icon={Icons.COPY} />
                        </Button>
                        <a
                          href={generateGoogleCalendarMeetingUrl({
                            title,
                            plannedStartTimestamp,
                            plannedEndTimestamp,
                            id,
                          })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            tooltipProps={{ content: "Add to Google Calendar" }}
                            showTooltip
                            btnSize="sm"
                            btnType="secondary"
                          >
                            <Icon icon={Icons.SCHEDULE} />
                          </Button>
                        </a>
                      </>
                    )}
                    <Button
                      tooltipProps={{ content: "Edit" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setUpsertMeetingFormValues(meeting);
                        setIsUpsertMeetingModalOpen(true);
                      }}
                    >
                      <Icon icon={Icons.EDIT} />
                    </Button>
                    <Button
                      tooltipProps={{ content: "Delete" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setChosenMeetingId(id);
                        setIsDeleteMeetingDialogOpen(true);
                      }}
                    >
                      <Icon icon={Icons.DELETE} />
                    </Button>
                  </div>
                </div>
              );
            })}
            <Dialog
              header="Are you sure you want to delete this meeting?"
              state={isDeleteMeetingDialogOpenAtom}
              onApprove={async () => {
                if (chosenMeetingId) {
                  await handleDeleteMeeting({ meetingId: chosenMeetingId });
                }
              }}
              approveText="Delete"
              cancelText="Cancel"
              dangerous
            />
          </>
        ) : (
          <span className="text-muted text-center">
            <i>Click</i>
            <Button
              className="mx-2"
              onClick={() => setIsUpsertMeetingModalOpen(true)}
              btnSize="sm"
            >
              Create
            </Button>
            <i>to create a meeting</i>
          </span>
        )}
      </Card>
    </div>
  );
};
