import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { DBDocumentID, DBDocument } from "../types/document";
import { UID } from "../types/user";
import {
  getCoursesRequest,
  getCourseByIdRequest,
  saveCourseRequest,
  deleteCourseRequest,
} from "./firebase";
import { logger } from "./logger";
import { queryClient } from "..";
import { StatePrimaryKeys } from "./consts";
import { Course } from "../types/course";

export const useGetCourses = (
  teamId?: DBDocumentID,
  options?: UseQueryOptions<DBDocument<Course>[], Error>
) =>
  useQuery<DBDocument<Course>[], Error>(
    [StatePrimaryKeys.Courses],
    async () => {
      const courses = await getCoursesRequest(teamId);
      return courses;
    },
    {
      onError: (error) => {
        logger.error("Error in fetching courses", error);
      },
      ...options,
    }
  );

export const useGetCourseById = (
  courseId: DBDocumentID,
  options?: UseQueryOptions<DBDocument<Course>, Error>
) =>
  useQuery<DBDocument<Course>, Error>(
    [StatePrimaryKeys.Courses, courseId],
    () => getCourseByIdRequest(courseId),
    {
      onError: (error) => {
        logger.error("Error in fetching course", error);
      },
      enabled: false,
      ...options,
    }
  );

export const useSaveCourse = (
  options?: UseMutationOptions<
    { success: boolean },
    Error,
    { course: DBDocument<Course>; uid: UID }
  >
) => {
  return useMutation<
    { success: boolean },
    Error,
    { course: DBDocument<Course>; uid: UID }
  >(
    [StatePrimaryKeys.Courses],
    ({ course, uid }) => {
      if (!course || !uid) {
        throw new Error("Course data and UID are required to save.");
      }
      return saveCourseRequest(course, uid);
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([StatePrimaryKeys.Courses]);
      },
      onError: (error) => {
        logger.error("Error in creating new course", error);
      },
      ...options,
    }
  );
};

export const useDeleteCourse = () =>
  useMutation<{ success: boolean }, Error, { courseId: DBDocumentID }>(
    [StatePrimaryKeys.Courses],
    ({ courseId }: { courseId: DBDocumentID }) => {
      if (!courseId) throw new Error("No course data was provided");
      return deleteCourseRequest(courseId);
    },
    {
      onSuccess() {
        return queryClient.refetchQueries([StatePrimaryKeys.Courses]);
      },
      onError: (error, { courseId }) => {
        logger.error(`Error deleting course with id ${courseId}`, error);
      },
    }
  );
