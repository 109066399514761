import React, { FunctionComponent, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { logger } from "../../services/logger";
import { SignIn } from "../../components/SignIn/SignIn";
import { AppRoutes } from "../../services/consts";

type LandingProps = React.HTMLAttributes<HTMLDivElement>;

export const Landing: FunctionComponent<LandingProps> = () => {
  const navigate = useNavigate();
  const signedInUserId = useRecoilValue(signedInUserIdAtom);

  useEffect(() => {
    logger.debug("Landing.tsx, useEffect#1 fired");

    if (signedInUserId) {
      navigate(AppRoutes.MGMT_APP);
    }
  }, [signedInUserId, navigate]);

  return <SignIn />;
};
