import { useNavigate } from "react-router-dom";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { signedInUserIdAtom } from "../../../../state/atoms/auth";
import {
  isUpsertActivityTemplateModalOpenAtom,
  isDeleteActivityTemplateDialogOpenAtom,
} from "../../../../state/atoms/ui";
import { Button } from "../../../../components/Button/Button";
import { Card } from "../../../../components/Card/Card";
import { Loading } from "../../../../components/Loading/Loading";
import {
  useDeleteActivityTemplate,
  useGetActivityTemplates,
} from "../../../../services/activity-templates";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { upsertActivityTemplateFormValuesAtom } from "../../../../state/atoms/templates";

import { Icon, Icons } from "../../../../components/Icons/Icon";
import { TemplateTypeIcon } from "./TemplateTypeIcon";
import { AppRoutes } from "../../../../services/consts";
import { useGetUser } from "../../../../services/users";

type TemplatesProps = React.HTMLAttributes<HTMLDivElement>;

const deleteDialogText =
  "Are you sure you want to delete this activity template?";

export const Templates: FunctionComponent<TemplatesProps> = () => {
  const navigate = useNavigate();
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);

  useEffect(() => {
    if (signedInUser && !signedInUser.isCreator) {
      navigate(AppRoutes.MGMT_APP);
    }
  }, [signedInUser, navigate]);

  const setIsUpsertActivityTemplateModalOpen = useSetRecoilState(
    isUpsertActivityTemplateModalOpenAtom
  );
  const setUpsertActivityTemplateFormValues = useSetRecoilState(
    upsertActivityTemplateFormValuesAtom
  );
  const setIsDeleteActivityTemplateDialogOpen = useSetRecoilState(
    isDeleteActivityTemplateDialogOpenAtom
  );

  const [chosenActivityTemplateId, setChosenActivityTemplateId] = useState<
    string | null
  >(null);

  const { isLoading: isLoadingActivityTemplates, data: activityTemplates } =
    useGetActivityTemplates();

  const { mutateAsync: handleDeleteActivityTemplate } =
    useDeleteActivityTemplate();

  const isLoading = isLoadingActivityTemplates || isLoadingUser;

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Activity Templates</h4>
        </div>
        <div className="col py-0">
          <Button
            className="float-end"
            onClick={() => {
              setIsUpsertActivityTemplateModalOpen(true);
            }}
          >
            Create
          </Button>
        </div>
      </div>
      <Card className="mt-4 mt-md-2">
        {isLoading ? (
          <Loading />
        ) : activityTemplates && activityTemplates.length > 0 ? (
          <>
            <div className="row my-2">
              <div className="col-6 col-xl-3 font-weight-bold text-truncate">
                Title
              </div>
              <div className="d-none d-xl-block col-xl-3 font-weight-bold text-truncate">
                Created
              </div>
              <div className="d-none d-xl-block col-xl-4 font-weight-bold text-truncate">
                Last updated
              </div>
              <div className="col-6 col-xl-2 font-weight-bold text-truncate">
                Actions
              </div>
            </div>
            {activityTemplates.map((activityTemplate) => {
              const { id, name, createdAt, updatedAt, type } = activityTemplate;

              return (
                <div key={`activity-template-${id}`} className="row my-2">
                  <div className="col-6 col-xl-3 pt-1 align-self-center text-truncate d-flex">
                    <TemplateTypeIcon templateType={type} />
                    <span className="px-2 align-middle">
                      {name || (
                        <i className="text-muted">Untitled activity template</i>
                      )}
                    </span>
                  </div>
                  <div className="d-none d-xl-block col-xl-3 pt-1 align-self-center text-truncate">
                    {createdAt ? new Date(createdAt).toUTCString() : "-"}
                  </div>
                  <div className="d-none d-xl-block col-xl-4 pt-1 align-self-center text-truncate">
                    {updatedAt ? new Date(updatedAt).toUTCString() : "-"}
                  </div>
                  <div className="col-6 col-xl-2 align-self-center">
                    <Button
                      tooltipProps={{ content: "Edit" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setUpsertActivityTemplateFormValues(activityTemplate);
                        setIsUpsertActivityTemplateModalOpen(true);
                      }}
                    >
                      <Icon icon={Icons.EDIT} />
                    </Button>
                    <Button
                      tooltipProps={{ content: "Delete" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setChosenActivityTemplateId(activityTemplate.id);
                        setIsDeleteActivityTemplateDialogOpen(true);
                      }}
                    >
                      <Icon icon={Icons.DELETE} />
                    </Button>
                  </div>
                </div>
              );
            })}
            <Dialog
              header={deleteDialogText}
              state={isDeleteActivityTemplateDialogOpenAtom}
              onApprove={async () => {
                if (chosenActivityTemplateId) {
                  await handleDeleteActivityTemplate({
                    activityTemplateId: chosenActivityTemplateId,
                  });
                }
              }}
              approveText="Delete"
              cancelText="Cancel"
              dangerous
            />
          </>
        ) : (
          <span className="text-muted text-center">
            <i>Click</i>
            <Button
              className="mx-2"
              onClick={() => setIsUpsertActivityTemplateModalOpen(true)}
              btnSize="sm"
            >
              Create
            </Button>
            <i>to create an activity template</i>
          </span>
        )}
      </Card>
    </div>
  );
};
