export const HOUR_DIVIDER = 4;
export const TIME_OPTIONS_LENGTH = 24 * HOUR_DIVIDER;
export const MAP_NUM_ROWS = 9;
export const MAP_NUM_COLS = 12;

export const avatars = [
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/avatars%2Fdefault-avatars%2F1.png?alt=media&token=ac1b1505-8229-4850-adb2-9474f4271085&_gl=1*dae3yo*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1NDA4NjEuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/avatars%2Fdefault-avatars%2F2.png?alt=media&token=1d198513-8962-41ef-a52a-960568436aa1&_gl=1*1207nz5*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1NDA4NzAuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/avatars%2Fdefault-avatars%2F3.png?alt=media&token=1b17e3d3-a378-4b6f-a397-ad145d43817e&_gl=1*1i6yrpo*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1NDA4NzcuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/avatars%2Fdefault-avatars%2F4.png?alt=media&token=103dfdab-95c8-4f83-aa54-7445ff0facb0&_gl=1*h7o2ax*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1NDA4ODQuMC4wLjA.",
];
export const meeples = [
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F1.png?alt=media&token=36fe932a-318b-4cc3-aed5-890d2eb08e9c&_gl=1*12u129j*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk0NjkuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F10.png?alt=media&token=e3baa7d8-5893-427f-89bb-d0e458c3737c&_gl=1*15e4cx6*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk0ODEuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F2.png?alt=media&token=5fefd06c-1f01-446f-a91c-3912220a9d8f&_gl=1*glsq75*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk0OTEuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F3.png?alt=media&token=4d80a21b-ae9c-413a-8b0f-a67df2b796c5&_gl=1*1ub5kxk*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1MDQuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F4.png?alt=media&token=0bc02c48-94a7-4024-a933-0637b2e33f20&_gl=1*ulveqp*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1MTEuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F5.png?alt=media&token=20a56921-65d5-43e7-a98c-a92d5de3d122&_gl=1*ailll8*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1MTguMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F6.png?alt=media&token=484f8a07-9722-4f38-901d-1cc35151ea96&_gl=1*bl3jtp*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1MjYuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F7.png?alt=media&token=561546b2-6926-4299-aa87-c1227553fd9b&_gl=1*18k6q0u*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1MzIuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F8.png?alt=media&token=90dbaacb-0e7d-40a9-aff2-0851424f7543&_gl=1*1gxu3og*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1NDAuMC4wLjA.",
  "https://firebasestorage.googleapis.com/v0/b/go-cadu.appspot.com/o/free-map-assets%2Fmeeples%2F9.png?alt=media&token=d2bf7f00-b186-423d-9c76-e5a4dd05529e&_gl=1*1w35xot*_ga*MTY0NzkzMjk5Ny4xNjgxNjg3NjU4*_ga_CW55HF8NVT*MTY4NTUzOTM3MC4xNS4xLjE2ODU1Mzk1NDcuMC4wLjA.",
];

export enum StatePrimaryKeys {
  Activities = "activities",
  PublicActivities = "public-activities",
  Meetings = "meetings",
  ActivityTemplates = "activity-templates",
  Courses = "courses",
  Users = "users",
}

export enum MeetingsStateKeys {
  MeetingsMetadata = "meetingsMetadata",
  Meeples = "meeples",
}

type StateSubKeysType<PrimaryKeys extends string, SubKeys extends string> = {
  [K in PrimaryKeys]?: {
    [S in SubKeys]: S;
  };
};

type ExtendedStateSubKeys = StateSubKeysType<
  StatePrimaryKeys.Meetings,
  MeetingsStateKeys
>;

export const StateSubKeys: ExtendedStateSubKeys = {
  [StatePrimaryKeys.Meetings]: {
    [MeetingsStateKeys.MeetingsMetadata]: MeetingsStateKeys.MeetingsMetadata,
    [MeetingsStateKeys.Meeples]: MeetingsStateKeys.Meeples,
  },
};

export enum AppRoutes {
  MGMT_APP = "/app",
  ACTIVITIES = "/activities",
  MEETINGS = "/meetings",
  SETTINGS = "/settings",
  TEMPLATES = "/templates",
  COURSE = "/course",
  COURSES = "/courses",
  MEET = "/meet",
}

export enum RouteParams {
  MEETING_ID = "/:meetingId",
  COURSE_ID = "/:courseId",
}

export enum SubRoutes {
  ENV = "/env",
}
