import React, { FunctionComponent, useState, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import {
  isUpsertActivityModalOpenAtom,
  isDeleteActivityDialogOpenAtom,
  isUpsertMeetingModalOpenAtom,
  isUpsertCourseModalOpenAtom,
} from "../../../../state/atoms/ui";
import { Button } from "../../../../components/Button/Button";
import { Card } from "../../../../components/Card/Card";
import { Loading } from "../../../../components/Loading/Loading";
import {
  useDeleteActivity,
  useGetActivities,
  useGetPublicActivities,
} from "../../../../services/activities";
import { Dialog } from "../../../../components/Dialog/Dialog";
import {
  selectedActivityIdAtom,
  upsertActivityFormValuesAtom,
} from "../../../../state/atoms/activities";
import { Activity } from "../../../../types/activities/activity";

import { useGetMeetings } from "../../../../services/meetings";
import { MeetingStatus } from "../../../../types/meeting";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { useGetActivityTemplates } from "../../../../services/activity-templates";
import { ActivityTemplate } from "../../../../types/activities/common";
import { TemplateTypeIcon } from "../Templates/TemplateTypeIcon";
import { DBDocument } from "../../../../types/document";

type MeetingsProps = React.HTMLAttributes<HTMLDivElement>;

enum ActivityTab {
  TEAM = "Team",
  LIBRARY = "Library",
}

export const Activities: FunctionComponent<MeetingsProps> = () => {
  const [deleteDialogText, setDeleteDialogText] = useState(
    "Are you sure you want to delete this activity?"
  );
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const setIsUpsertActivityModalOpen = useSetRecoilState(
    isUpsertActivityModalOpenAtom
  );
  const setUpsertActivityFormValues = useSetRecoilState(
    upsertActivityFormValuesAtom
  );
  const setIsDeleteActivityDialogOpen = useSetRecoilState(
    isDeleteActivityDialogOpenAtom
  );
  const setSelectedActivityId = useSetRecoilState(selectedActivityIdAtom);
  const [chosenActivity, setChosenActivity] = useState<Activity | null>(null);

  const setIsUpsertMeetingModalOpen = useSetRecoilState(
    isUpsertMeetingModalOpenAtom
  );
  const setIsUpsertCourseModalOpen = useSetRecoilState(
    isUpsertCourseModalOpenAtom
  );

  const [activeTab, setActiveTab] = useState<ActivityTab>(ActivityTab.TEAM);
  const navLinkClass = "nav-link text-dark";
  const activeClass = "active card rounded-0 rounded-top border-white";

  const { isLoading: isLoadingActivities, data: activities } = useGetActivities(
    contextTeam?.id
  );

  const { isLoading: isLoadingPublicActivities, data: publicActivities } =
    useGetPublicActivities();

  const { isLoading: isLoadingActivityTemplates, data: templates } =
    useGetActivityTemplates();

  const { data: meetings } = useGetMeetings({
    userId: signedInUserId,
    teamId: contextTeam?.id,
    statuses: [MeetingStatus.IN_PROGRESS, MeetingStatus.SCHEDULED],
  });

  const { mutateAsync: handleDeleteActivity } = useDeleteActivity();

  const templatesMap: Record<string, ActivityTemplate> = useMemo(() => {
    return (
      templates?.reduce(
        (acc, currentTemplate) => {
          acc[currentTemplate.id] = currentTemplate;
          return acc;
        },
        {} as Record<string, ActivityTemplate>
      ) || {}
    );
  }, [templates]);

  const isLoading =
    isLoadingActivities ||
    (activeTab === ActivityTab.LIBRARY && isLoadingPublicActivities) ||
    isLoadingActivityTemplates;

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  const renderActivities = (
    activities: DBDocument<Activity>[],
    isLibrary: boolean
  ): React.JSX.Element => {
    return (
      <>
        <div className="row my-2">
          <div className="col font-weight-bold text-truncate">Title</div>
          <div className="col d-none d-xl-block font-weight-bold text-truncate">
            Template
          </div>
          <div className="col d-none d-xl-block font-weight-bold text-truncate">
            Created
          </div>
          <div className="col d-none d-xl-block font-weight-bold text-truncate">
            Last updated
          </div>
          <div className="col font-weight-bold text-truncate">Actions</div>
        </div>
        {activities.map((activity) => {
          const { id, name, createdAt, updatedAt, templateId } = activity;
          const template = templatesMap[templateId];

          return (
            <div key={`activity-${id}`} className="row my-2">
              <div className="col align-self-center text-truncate">
                {name || <i className="text-muted">Untitled activity</i>}
              </div>
              <div className="col d-none d-xl-block align-self-center text-truncate">
                {template && (
                  <div className="d-flex align-items-center">
                    <TemplateTypeIcon templateType={template.type} />
                    <span className="px-2">{template.name}</span>
                  </div>
                )}
              </div>
              <div className="col d-none d-xl-block align-self-center text-truncate">
                {createdAt && new Date(createdAt).toUTCString()}
              </div>
              <div className="col d-none d-xl-block align-self-center text-truncate">
                {updatedAt && new Date(updatedAt).toUTCString()}
              </div>
              <div className="col align-self-center">
                <Button
                  tooltipProps={{
                    content: "Create a meeting from this activity",
                  }}
                  showTooltip
                  btnSize="sm"
                  btnType="secondary"
                  onClick={() => {
                    setIsUpsertMeetingModalOpen(true);
                    setSelectedActivityId(activity.id);
                  }}
                >
                  <Icon icon={Icons.SCHEDULE} />
                </Button>
                <Button
                  tooltipProps={{
                    content: "Create a course from this activity",
                  }}
                  showTooltip
                  btnSize="sm"
                  btnType="secondary"
                  onClick={() => {
                    setIsUpsertCourseModalOpen(true);
                    setSelectedActivityId(activity.id);
                  }}
                >
                  <Icon icon={Icons.COURSES} />
                </Button>
                {!isLibrary && (
                  <>
                    <Button
                      tooltipProps={{ content: "Edit" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setUpsertActivityFormValues(activity);
                        setIsUpsertActivityModalOpen(true);
                      }}
                    >
                      <Icon icon={Icons.EDIT} />
                    </Button>
                    <Button
                      tooltipProps={{ content: "Delete" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setChosenActivity(activity);
                        const isActivityUsedInProgressMeeting = meetings?.some(
                          (meeting) =>
                            meeting.activityIds?.includes(activity.id) &&
                            (meeting.status === MeetingStatus.IN_PROGRESS ||
                              meeting.status === MeetingStatus.SCHEDULED)
                        );

                        if (isActivityUsedInProgressMeeting) {
                          setDeleteDialogText(
                            "This activity is currently in use in a meeting. Are you sure you want to delete this activity?"
                          );
                        } else {
                          setDeleteDialogText(
                            "Are you sure you want to delete this activity?"
                          );
                        }
                        setIsDeleteActivityDialogOpen(true);
                      }}
                    >
                      <Icon icon={Icons.DELETE} />
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Activities</h4>
        </div>
        {activeTab === ActivityTab.TEAM && (
          <div className="col py-0">
            <Button
              className="float-end"
              onClick={() => setIsUpsertActivityModalOpen(true)}
            >
              Create
            </Button>
          </div>
        )}
      </div>
      <nav className="mt-4 mt-md-2">
        <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
          <button
            className={`${navLinkClass} ${
              activeTab === ActivityTab.TEAM ? activeClass : ""
            }`}
            onClick={() => setActiveTab(ActivityTab.TEAM)}
          >
            Team
          </button>
          <button
            className={`${navLinkClass} ${
              activeTab === ActivityTab.LIBRARY ? activeClass : ""
            }`}
            onClick={() => setActiveTab(ActivityTab.LIBRARY)}
          >
            Library
          </button>
        </div>
      </nav>
      <Card
        className={`${
          activeTab === ActivityTab.TEAM ? "rounded-0 rounded-bottom" : ""
        }`}
      >
        {isLoading ? (
          <Loading />
        ) : activeTab === ActivityTab.TEAM &&
          activities &&
          activities.length > 0 ? (
          renderActivities(activities, false)
        ) : activeTab === ActivityTab.LIBRARY &&
          publicActivities &&
          publicActivities.length > 0 ? (
          renderActivities(publicActivities, true)
        ) : activeTab === ActivityTab.TEAM ? (
          <span className="text-muted text-center">
            <i>Click</i>
            <Button
              className="mx-2"
              onClick={() => setIsUpsertActivityModalOpen(true)}
              btnSize="sm"
            >
              Create
            </Button>
            <i>to create an activity</i>
          </span>
        ) : (
          <span className="text-muted text-center">
            <i>There are no public activities</i>
          </span>
        )}
      </Card>
      <Dialog
        header={deleteDialogText}
        state={isDeleteActivityDialogOpenAtom}
        onApprove={async () => {
          if (chosenActivity) {
            await handleDeleteActivity({ activity: chosenActivity });
          }
        }}
        approveText="Delete"
        cancelText="Cancel"
        dangerous
      />
    </div>
  );
};
