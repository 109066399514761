import { atom } from "recoil";

export const isUpsertMeetingModalOpenAtom = atom<boolean>({
  key: "isUpsertMeetingModalOpenAtom",
  default: false,
});

export const isEndMeetingDialogOpenAtom = atom<boolean>({
  key: "isEndMeetingDialogOpenAtom",
  default: false,
});

export const isDeleteMeetingDialogOpenAtom = atom<boolean>({
  key: "isDeleteMeetingDialogOpenAtom",
  default: false,
});

export const isUpsertActivityModalOpenAtom = atom<boolean>({
  key: "isUpsertActivityModalOpenAtom",
  default: false,
});

export const isDeleteActivityDialogOpenAtom = atom<boolean>({
  key: "isDeleteActivityDialogOpenAtom",
  default: false,
});

export const isQuestModalOpenAtom = atom<boolean>({
  key: "isQuestModalOpenAtom",
  default: false,
});

export const isLogoutDialogOpenAtom = atom<boolean>({
  key: "isLogoutDialogOpenAtom",
  default: false,
});

export const isSaveActivityApprovalDialogOpenAtom = atom<boolean>({
  key: "isSaveActivityApprovalDialogOpenAtom",
  default: false,
});

export const isCongratulationsModalOpenAtom = atom<boolean>({
  key: "isCongratulationsModalOpenAtom",
  default: false,
});

export const isGreetingModalOpenAtom = atom<boolean>({
  key: "isGreetingModalOpenAtom",
  default: false,
});

export const isActivityElementFormModalOpenAtom = atom<boolean>({
  key: "isActivityElementFormModalOpenAtom",
  default: false,
});

export const isFreeMapQuestionsModalOpenAtom = atom<boolean>({
  key: "isFreeMapQuestionsModalOpenAtom",
  default: false,
});

export const isDeleteQuestionDialogOpenAtom = atom<boolean>({
  key: "isDeleteQuestionDialogOpenAtom",
  default: false,
});

export const isUpsertActivityTemplateModalOpenAtom = atom<boolean>({
  key: "isUpsertActivityTemplateModalOpenAtom",
  default: false,
});

export const isDeleteActivityTemplateDialogOpenAtom = atom<boolean>({
  key: "isDeleteActivityTemplateDialogOpenAtom",
  default: false,
});

export const isUpsertCourseModalOpenAtom = atom<boolean>({
  key: "isUpsertCourseModalOpenAtom",
  default: false,
});

export const isDeleteCourseDialogOpenAtom = atom<boolean>({
  key: "isDeleteCourseDialogOpenAtom",
  default: false,
});

export const isEndCourseDialogOpenAtom = atom<boolean>({
  key: "isEndCourseDialogOpenAtom",
  default: false,
});

export const isDeleteParticipantDialogOpenAtom = atom<boolean>({
  key: "isDeleteParticipantDialogOpenAtom",
  default: false,
});
