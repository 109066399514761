import React, {
  FunctionComponent,
  useEffect,
  useState,
  CSSProperties,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import { logger } from "../../../../services/logger";
import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import {
  isActivityElementFormModalOpenAtom,
  isSaveActivityApprovalDialogOpenAtom,
  isUpsertActivityModalOpenAtom,
  isFreeMapQuestionsModalOpenAtom,
  isDeleteQuestionDialogOpenAtom,
} from "../../../../state/atoms/ui";
import { Modal } from "../../../../components/Modal/Modal";
import { InputText } from "../../../../components/Input/InputText";
import { Button } from "../../../../components/Button/Button";
import { Loading } from "../../../../components/Loading/Loading";
import {
  activityCongratulationsImageAtom,
  activityGreetingImageAtom,
  activityQuestionsImagesAtom,
  upsertActivityFormValuesAtom,
} from "../../../../state/atoms/activities";
import { ActivityType } from "../../../../types/activities/activity";
import { useGetActivityTemplates } from "../../../../services/activity-templates";
import {
  ActivityTemplate,
  InteractiveElement,
  InteractiveElementType,
  Puzzle,
  PuzzleType,
} from "../../../../types/activities/common";
import { FreeMapInteractiveElement as FreeMapInteractiveElementType } from "../../../../types/activities/free-map";
import { QuestRoomInteractiveElement as QuestRoomInteractiveElementType } from "../../../../types/activities/quest-room";
import { InputSelect } from "../../../../components/Input/InputSelect";
import { InputSwitch } from "../../../../components/Input/InputSwitch";
import { useSaveActivity } from "../../../../services/activities";
import { useGetMeetings } from "../../../../services/meetings";
import { MeetingStatus } from "../../../../types/meeting";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { ActivityMessagesForm } from "./ActivityMessagesForm";
import { Map } from "../../../../components/Map/Map";
import { QuestRoomInteractiveElement } from "../../../../components/InteractiveElement/QuestRoomInteractiveElement";
import { FreeMapInteractiveElement } from "../../../../components/InteractiveElement/FreeMapInteractiveElement";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { ActivityElementForm } from "./ActivityElementForm";
import { getRandomIntInclusive } from "../../../../services/utils";
import { ToggleButtonGroup } from "../../../../components/Button/ToggleButtonGroup";
import { useGetUser } from "../../../../services/users";

type UpsertActivityModalProps = React.HTMLAttributes<HTMLDivElement>;

type UpsertActivityFormProps = React.HTMLAttributes<HTMLDivElement>;

const getNewEmptyPuzzle = (): Puzzle => {
  return {
    type: PuzzleType.SingleChoice,
    title: "",
    question: { text: "", imgSrc: "" },
    answers: [],
  };
};

export const InteractiveElementInUpsertForm: FunctionComponent<{
  activityType: ActivityType;
  element: InteractiveElement;
  index: number;
  showSolved?: boolean;
  style?: CSSProperties;
}> = ({ activityType, element, index, showSolved = true, style, children }) => {
  const [currDiceNumber, setCurrDiceNumber] = useState<number>(4);

  switch (activityType) {
    case ActivityType.FREE_MAP:
      const {
        images,
        rectangle: freeMapItemRectangle,
        type,
      } = element as unknown as FreeMapInteractiveElementType;
      return (
        <FreeMapInteractiveElement
          type={type}
          currDiceNumber={currDiceNumber}
          images={images}
          index={index}
          rectangle={freeMapItemRectangle}
          className="pointer"
          onClick={() => {
            setCurrDiceNumber(getRandomIntInclusive(0, 5));
          }}
          style={style}
        >
          {children}
        </FreeMapInteractiveElement>
      );
    case ActivityType.QUEST_ROOM:
    default:
      const {
        imageUnsolved,
        imageSolved,
        rectangle: questRoomItemRectangle,
      } = element as unknown as QuestRoomInteractiveElementType;
      return (
        <QuestRoomInteractiveElement
          index={index}
          imageSolved={imageSolved}
          imageUnsolved={imageUnsolved}
          rectangle={questRoomItemRectangle}
          showSolved={showSolved}
          style={style}
        >
          {children}
        </QuestRoomInteractiveElement>
      );
  }
};

export const UpsertFreeMapQuestionsForm: FunctionComponent<{
  closeFunction: () => void;
  setElementIdxFunction: (index: number) => void;
}> = ({ closeFunction, setElementIdxFunction }) => {
  const [upsertActivityFormValues, setUpsertActivityFormValuesAtom] =
    useRecoilState(upsertActivityFormValuesAtom);

  const setIsElementFormModalOpen = useSetRecoilState(
    isActivityElementFormModalOpenAtom
  );

  const setIsDeleteQuestionDialogOpenAtom = useSetRecoilState(
    isDeleteQuestionDialogOpenAtom
  );

  const [questionToDeleteIdx, setQuestionToDeleteIdx] = useState<null | number>(
    null
  );

  const [localQuestions, setLocalQuestions] = useState<Array<Puzzle>>([]);

  useEffect(() => {
    setLocalQuestions(upsertActivityFormValues?.puzzles || []);
  }, [upsertActivityFormValues?.puzzles]);

  const onSubmitQuestions = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    closeFunction();
  };

  const handleDeleteQuestion = () => {
    if (questionToDeleteIdx === null || !upsertActivityFormValues) return;
    setUpsertActivityFormValuesAtom({
      ...upsertActivityFormValues,
      puzzles: upsertActivityFormValues?.puzzles.filter((_, idx) => {
        return idx !== questionToDeleteIdx;
      }),
    });
    setQuestionToDeleteIdx(null);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !upsertActivityFormValues) return;

    const newPuzzleArray = Array.from(localQuestions);
    const [draggedItem] = newPuzzleArray.splice(result.source.index, 1);
    newPuzzleArray.splice(result.destination.index, 0, draggedItem);
    setLocalQuestions(newPuzzleArray);
    setUpsertActivityFormValuesAtom({
      ...upsertActivityFormValues,
      puzzles: newPuzzleArray,
    });
  };

  if (!upsertActivityFormValues) return <></>;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <form onSubmit={onSubmitQuestions}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {localQuestions.map((puzzle: Puzzle, puzzleIdx: number) => (
                <Draggable
                  key={puzzleIdx}
                  draggableId={puzzleIdx.toString()}
                  index={puzzleIdx}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      className="d-flex justify-content-between shadow-sm bg-light py-3 mb-2 p-1 rounded gap-2 align-items-center"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <Icon icon={Icons.DRAGGABLE} className="opacity-50" />
                        <div className="text-truncate">
                          {puzzle.question.text}
                        </div>
                      </div>

                      <div>
                        <Button
                          tooltipProps={{
                            content: "Edit question",
                          }}
                          showTooltip
                          type="button"
                          btnSize="sm"
                          btnType="secondary"
                          onClick={() => {
                            setElementIdxFunction(puzzleIdx);
                            setIsElementFormModalOpen(true);
                          }}
                        >
                          <Icon icon={Icons.EDIT} />
                        </Button>
                        <Button
                          tooltipProps={{
                            content: "Delete question",
                          }}
                          showTooltip
                          type="button"
                          btnSize="sm"
                          btnType="secondary"
                          onClick={() => {
                            setQuestionToDeleteIdx(puzzleIdx);
                            setIsDeleteQuestionDialogOpenAtom(true);
                          }}
                        >
                          <Icon icon={Icons.DELETE} />
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Button
          className="w-100 mb-5 d-flex justify-content-center align-items-center"
          type="button"
          onClick={() => {
            setUpsertActivityFormValuesAtom({
              ...upsertActivityFormValues,
              puzzles: [
                ...upsertActivityFormValues.puzzles,
                getNewEmptyPuzzle(),
              ],
            });
            setIsElementFormModalOpen(true);
            setElementIdxFunction(upsertActivityFormValues.puzzles.length);
          }}
        >
          <Icon icon={Icons.ADD} />
          Add
        </Button>
        <div className="d-flex justify-content-end">
          <Button btnType="secondary" type="submit">
            Close
          </Button>
        </div>
        <Dialog
          header="Are you sure you want to delete this question?"
          state={isDeleteQuestionDialogOpenAtom}
          onApprove={() => {
            handleDeleteQuestion();
          }}
          approveText="Delete"
          cancelText="Cancel"
          dangerous
        />
      </form>
    </DragDropContext>
  );
};

export const UpsertActivityForm: FunctionComponent<
  UpsertActivityFormProps
> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const [isUpsertActivityModalOpen, setIsUpsertActivityModalOpenAtom] =
    useRecoilState(isUpsertActivityModalOpenAtom);
  const [upsertActivityFormValues, setUpsertActivityFormValuesAtom] =
    useRecoilState(upsertActivityFormValuesAtom);
  const [activityQuestionsImages, setActivityQuestionsImages] = useRecoilState(
    activityQuestionsImagesAtom
  );
  const [activityCongratulationsImage, setActivityCongratulationsImage] =
    useRecoilState(activityCongratulationsImageAtom);

  const [activityGreetingsImage, setActivityGreetingsImage] = useRecoilState(
    activityGreetingImageAtom
  );
  const setIsSaveActivityApprovalDialogOpen = useSetRecoilState(
    isSaveActivityApprovalDialogOpenAtom
  );
  const [isElementFormModalOpen, setIsElementFormModalOpen] = useRecoilState(
    isActivityElementFormModalOpenAtom
  );
  const [isFreeMapQuestionsModalOpen, setIsFreeMapQuestionsModalOpen] =
    useRecoilState(isFreeMapQuestionsModalOpenAtom);

  const [selectedTemplate, setSelectedTemplate] =
    useState<ActivityTemplate | null>(null);
  const [isShowMarkers, setIsShowMarkers] = useState(true);
  const [isShowSolved, setIsShowSolved] = useState(false);
  const [elementToEditIdx, setElementToEditIdx] = useState<number | null>(null);

  const isFreeMap = upsertActivityFormValues?.type === ActivityType.FREE_MAP;
  const isEditing = !!upsertActivityFormValues?.id;
  const [publishedTemplates, setPublishedTemplates] = useState<
    ActivityTemplate[]
  >([]);

  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);

  const [
    isSavingInProgressActivityApproved,
    setIsSavingInProgressActivityApproved,
  ] = useState(false);

  const { data: meetings } = useGetMeetings({
    userId: signedInUserId,
    teamId: contextTeam?.id,
    statuses: [MeetingStatus.IN_PROGRESS],
  });

  const { data: templates, isLoading: isFetchingTemplates } =
    useGetActivityTemplates();

  const { mutateAsync: saveActivity, isLoading: isSavingActivity } =
    useSaveActivity(contextTeam?.id || "");

  const elements = isEditing
    ? upsertActivityFormValues.puzzles
    : selectedTemplate?.views
        .flatMap(({ interactiveElements }) => interactiveElements)
        .filter((element) => element.type === InteractiveElementType.Puzzle);

  useEffect(() => {
    if (!templates) return;
    const templateToSelect = templates.find(
      (template) => template.id === upsertActivityFormValues?.templateId
    );
    if (!templateToSelect) return;
    setSelectedTemplate(templateToSelect);
  }, [upsertActivityFormValues?.templateId, templates]);

  useEffect(() => {
    if (templates) {
      const filteredTemplates = templates.filter(
        (template) => template.isPublished
      );
      setPublishedTemplates(filteredTemplates);
    }
  }, [templates]);

  useEffect(() => {
    if (isSavingInProgressActivityApproved) {
      onSaveActivity();
      setIsSavingInProgressActivityApproved(false);
    }
    // eslint-disable-next-line
  }, [isSavingInProgressActivityApproved]);

  useEffect(() => {
    if (!upsertActivityFormValues?.templateId) return;

    if (isEditing) {
      setUpsertActivityFormValuesAtom({
        ...upsertActivityFormValues,
        puzzles: upsertActivityFormValues.puzzles,
      });
      return;
    }

    const puzzles: Puzzle[] =
      elements?.map(() => ({
        type: PuzzleType.SingleChoice,
        title: "",
        image: "",
        question: {
          text: "",
          imgSrc: "",
        },
        answers: [],
      })) || [];

    setUpsertActivityFormValuesAtom({
      ...upsertActivityFormValues,
      puzzles,
    });
    // eslint-disable-next-line
  }, [selectedTemplate, upsertActivityFormValues?.id]);

  useEffect(() => {
    logger.debug("CreatingMeetingModal.tsx, useEffect#1 fired");
    if (!upsertActivityFormValues)
      setUpsertActivityFormValuesAtom({
        templateId: "",
        name: "",
        description: "",
        type: ActivityType.QUEST_ROOM,
        puzzles: [],
        isDeleted: false,
        congratulationsMessage: { imgSrc: "", text: "" },
        greetingMessage: { imgSrc: "", text: "" },
        isPublic: false,
      });
  }, [
    isUpsertActivityModalOpen,
    setUpsertActivityFormValuesAtom,
    upsertActivityFormValues,
  ]);

  const canSaveActivity = !!upsertActivityFormValues?.templateId;
  const onSaveActivity = async (evt?: React.FormEvent<HTMLFormElement>) => {
    if (!signedInUser || !canSaveActivity) return;

    evt?.preventDefault();

    if (
      isEditing &&
      !isSavingInProgressActivityApproved &&
      upsertActivityFormValues.id
    ) {
      const isActivityUsedInProgressMeeting = meetings?.some(
        (meeting) =>
          meeting.activityIds?.includes(upsertActivityFormValues.id ?? "") &&
          meeting.status === MeetingStatus.IN_PROGRESS
      );

      if (isActivityUsedInProgressMeeting) {
        setIsSaveActivityApprovalDialogOpen(true);
        return;
      }
    }

    await saveActivity({
      newActivityData: upsertActivityFormValues,
      imagesList: activityQuestionsImages,
      isForcedChange: isSavingInProgressActivityApproved,
      congratulationsImage: activityCongratulationsImage,
      greetingImage: activityGreetingsImage,
    });
    setUpsertActivityFormValuesAtom(null);
    setSelectedTemplate(null);
    setActivityQuestionsImages([]);
    setIsUpsertActivityModalOpenAtom(false);
    setIsSavingInProgressActivityApproved(false);
    setActivityCongratulationsImage(null);
    setActivityGreetingsImage(null);
  };

  const { backgroundImage, interactiveElements } =
    selectedTemplate?.views[0] || {};

  const handleSolvedButtonClick = (value: string): void => {
    setIsShowSolved(value === "solved");
  };

  const handleMarkersButtonClick = (value: string): void => {
    setIsShowMarkers(value === "show");
  };

  const isReady = !isFetchingTemplates && !isLoadingUser && !!signedInUser;

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return upsertActivityFormValues ? (
    <>
      {isReady ? (
        <>
          <form className="form" onSubmit={onSaveActivity}>
            <div>
              Name
              <InputText
                className="mt-1 mb-3"
                disabled={isSavingActivity}
                value={upsertActivityFormValues.name}
                onChange={(evt) =>
                  setUpsertActivityFormValuesAtom({
                    ...upsertActivityFormValues,
                    name: evt.target.value,
                  })
                }
              />
            </div>
            <div>
              Template
              <InputSelect
                disabled={isSavingActivity || !!upsertActivityFormValues.id}
                className="mb-3"
                value={upsertActivityFormValues.templateId}
                onChange={(evt) => {
                  const templateId = evt.target.value;
                  const template = templates?.find(
                    (template) => template.id === templateId
                  );
                  if (!template) return;
                  setUpsertActivityFormValuesAtom({
                    ...upsertActivityFormValues,
                    templateId,
                    type: template.type,
                  });
                }}
              >
                <option value="">Select a template</option>
                {upsertActivityFormValues.templateId
                  ? templates?.map((template, idx) => (
                      <option
                        key={`template-option-${idx}`}
                        value={template.id}
                        title={template.name}
                      >
                        {template.name}
                      </option>
                    ))
                  : publishedTemplates?.map((template, idx) => (
                      <option
                        key={`template-option-${idx}`}
                        value={template.id}
                        title={template.name}
                      >
                        {template.name}
                      </option>
                    ))}
              </InputSelect>
            </div>
            {selectedTemplate && (
              <>
                <div className="d-flex gap-1 mb-2">
                  <ToggleButtonGroup
                    isDisabled={isSavingActivity || isFreeMap}
                    buttons={[
                      {
                        value: "unsolved",
                        children: "Unsolved",
                        tooltipProps: {
                          content: "There are no solvable elements to show",
                        },
                      },
                      {
                        value: "solved",
                        children: "Solved",
                        tooltipProps: {
                          content: "There are no solvable elements to show",
                        },
                      },
                    ]}
                    onChange={handleSolvedButtonClick}
                  />
                  <ToggleButtonGroup
                    isDisabled={isSavingActivity}
                    buttons={[
                      {
                        value: "show",
                        children: "Show markers",
                      },
                      {
                        value: "hide",
                        children: "Hide markers",
                      },
                    ]}
                    onChange={handleMarkersButtonClick}
                  />
                </div>

                <div className="position-relative w-75 mb-2 d-flex flex-column">
                  <Map className="mb-1" backgroundImage={backgroundImage || ""}>
                    {interactiveElements?.map((element, index) => {
                      const isElementFormFilled =
                        (upsertActivityFormValues?.puzzles[index]?.question
                          .imgSrc ||
                          upsertActivityFormValues?.puzzles[index]?.question
                            .text) &&
                        upsertActivityFormValues?.puzzles[index]?.answers
                          .length;

                      return (
                        <InteractiveElementInUpsertForm
                          key={`interactive-element-${index}`}
                          activityType={selectedTemplate.type}
                          element={element}
                          index={index}
                          showSolved={isShowSolved}
                        >
                          {isShowMarkers &&
                            selectedTemplate.type ===
                              ActivityType.QUEST_ROOM && (
                              <Button
                                outline={!isElementFormFilled}
                                className="center-absolute rounded-full ar-1-1 p-05 m-0"
                                type="button"
                                disabled={isSavingActivity}
                                onClick={() => {
                                  setIsElementFormModalOpen(true);
                                  setElementToEditIdx(index);
                                }}
                              >
                                <div className="w-24px h-24px text-small d-flex justify-content-center align-items-center">
                                  {index + 1}
                                </div>
                              </Button>
                            )}

                          {element.type === InteractiveElementType.Deck && (
                            <Button
                              outline={
                                upsertActivityFormValues.puzzles.length === 0
                              }
                              className="center-absolute position-relative d-flex align-items-center gap-1"
                              type="button"
                              disabled={isSavingActivity}
                              onClick={() =>
                                setIsFreeMapQuestionsModalOpen(true)
                              }
                            >
                              {upsertActivityFormValues.puzzles.length}
                              <Icon icon={Icons.EDIT} />
                            </Button>
                          )}
                        </InteractiveElementInUpsertForm>
                      );
                    })}
                  </Map>
                </div>
                <ActivityMessagesForm isAdding={isSavingActivity} />
              </>
            )}

            <div className="d-flex gap-4 justify-content-end align-items-center">
              {signedInUser.isPublisher && (
                <InputSwitch
                  id="publishActivityCheckbox"
                  checked={upsertActivityFormValues?.isPublic}
                  onChange={(event) => {
                    setUpsertActivityFormValuesAtom({
                      ...upsertActivityFormValues,
                      isPublic: event.target.checked,
                    });
                  }}
                >
                  Publish
                </InputSwitch>
              )}
              <Button
                className="float-end"
                disabled={
                  isSavingActivity || !upsertActivityFormValues.templateId
                }
                type="submit"
              >
                {isSavingActivity ? (
                  <Loading />
                ) : (
                  <span>{upsertActivityFormValues.id ? "Save" : "Create"}</span>
                )}
              </Button>
            </div>
          </form>
          <Dialog
            header="This activity is currently in use in an ongoing meeting. Are you sure you want to override it anyway?"
            onApprove={async () => {
              setIsSaveActivityApprovalDialogOpen(false);
              setIsSavingInProgressActivityApproved(true);
            }}
            state={isSaveActivityApprovalDialogOpenAtom}
            approveText="Override"
          />

          <Modal
            isOpen={isFreeMapQuestionsModalOpen}
            header={<h3>Questions</h3>}
            body={
              <UpsertFreeMapQuestionsForm
                closeFunction={() => setIsFreeMapQuestionsModalOpen(false)}
                setElementIdxFunction={(idx: number) =>
                  setElementToEditIdx(idx)
                }
              />
            }
            onClose={() => setIsFreeMapQuestionsModalOpen(false)}
          />
          <Modal
            isOpen={isElementFormModalOpen}
            header={
              <h3>{`${
                selectedTemplate?.type === ActivityType.FREE_MAP
                  ? "Question"
                  : "Element"
              } ${(elementToEditIdx || 0) + 1}`}</h3>
            }
            body={<ActivityElementForm elementIdx={elementToEditIdx || 0} />}
            onClose={() => {
              setIsElementFormModalOpen(false);
              setElementToEditIdx(null);
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  ) : (
    <></>
  );
};

export const UpsertActivityModal: FunctionComponent<
  UpsertActivityModalProps
> = () => {
  const [upsertActivityFormValues, setUpsertActivityFormValuesAtom] =
    useRecoilState(upsertActivityFormValuesAtom);
  const isEditing = !!upsertActivityFormValues?.id;
  const [isUpsertActivityModalOpen, setIsUpsertActivityModalOpenAtom] =
    useRecoilState(isUpsertActivityModalOpenAtom);
  return (
    <Modal
      preventCloseOnBackdropClick
      isOpen={isUpsertActivityModalOpen}
      header={<h3>{isEditing ? "Edit activity" : "New activity"}</h3>}
      body={<UpsertActivityForm />}
      onClose={() => {
        setIsUpsertActivityModalOpenAtom(false);
        setUpsertActivityFormValuesAtom(null);
      }}
    />
  );
};
