import { FunctionComponent } from "react";
import {
  InteractiveElement,
  InteractiveElementProps,
} from "./InteractiveElement";

interface QuestRoomInteractiveElementProps
  extends Omit<InteractiveElementProps, "visibility"> {
  imageSolved?: string;
  imageUnsolved?: string;
  showSolved: boolean;
}

export const QuestRoomInteractiveElement: FunctionComponent<
  QuestRoomInteractiveElementProps
> = ({ imageSolved, imageUnsolved, showSolved, children, ...otherProps }) => {
  const images: { imgSrc?: string; type: "solved" | "unsolved" }[] = [
    { imgSrc: imageSolved, type: "solved" },
    { imgSrc: imageUnsolved, type: "unsolved" },
  ];
  return (
    <>
      {images.map(({ imgSrc, type: imageType }, idx) => (
        <InteractiveElement
          key={`interactive-element-${idx}`}
          visibility={`${
            imageType === "solved"
              ? showSolved
                ? "visible"
                : "hidden"
              : showSolved
                ? "hidden"
                : "visible"
          }`}
          image={imgSrc}
          className="position-relative"
          {...otherProps}
        >
          {children}
        </InteractiveElement>
      ))}
    </>
  );
};
