import React, { FunctionComponent, useEffect, useState, useMemo } from "react";
import { Timestamp } from "firebase/firestore";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import DateTimeRangePicker from "../../../../components/DateTimeRangePicker/DateTimeRangePicker";

import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import {
  isUpsertCourseModalOpenAtom,
  isDeleteParticipantDialogOpenAtom,
} from "../../../../state/atoms/ui";
import { HOURS, SECONDS } from "../../../../types/time";
import { Modal } from "../../../../components/Modal/Modal";
import { InputText } from "../../../../components/Input/InputText";
import { Button } from "../../../../components/Button/Button";
import { Loading } from "../../../../components/Loading/Loading";
import { InputSelect } from "../../../../components/Input/InputSelect";
import {
  useGetActivities,
  useGetPublicActivities,
} from "../../../../services/activities";
import { notificationsAtom } from "../../../../state/atoms/notifications";
import { NotificationType } from "../../../../types/notification";
import { Team } from "../../../../types/team";
import { AppUser } from "../../../../types/user";
import { useNavigate } from "react-router-dom";
import { selectedActivityIdAtom } from "../../../../state/atoms/activities";
import { useSaveCourse } from "../../../../services/courses";
import { upsertCourseFormValuesAtom } from "../../../../state/atoms/courses";
import { useGetUser, useGetUsersByIds } from "../../../../services/users";
import Accordion from "../../../../components/Accordion/Accordion";
import {
  useGetMeetingsMetadata,
  useGetMeetingsByIds,
  useDeleteMeeting,
} from "../../../../services/meetings";
import "./UpsertCourseModal.css";
import { Dialog } from "../../../../components/Dialog/Dialog";
import ParticipantRow from "./ParticipantRow";
import { Activity } from "../../../../types/activities/activity";
import { AppRoutes } from "../../../../services/consts";
import { DBDocument } from "../../../../types/document";
import { Course } from "../../../../types/course";

type UpsertCourseModalProps = React.HTMLAttributes<HTMLDivElement>;

type UpsertCourseFormProps = React.HTMLAttributes<HTMLDivElement>;

export const getInitialUpsertCourseFormValues = (
  team: Team,
  user: AppUser,
  course?: DBDocument<Course> | null
): DBDocument<Course> => ({
  id: course?.id || "",
  participants: course?.participants || {},
  teamId: team.id,
  hostId: user.uid,
  name: course?.name || "",
  activityIds: course?.activityIds || [],
  startsAt: course?.startsAt || Timestamp.now().seconds * SECONDS + 1 * HOURS,
  endsAt:
    course?.endsAt ||
    Timestamp.fromMillis(Timestamp.now().seconds * SECONDS + 2 * HOURS)
      .seconds * SECONDS,
  createdBy: "",
  updatedBy: "",
});

export const UpsertCourseForm: FunctionComponent<
  UpsertCourseFormProps
> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const [isUpsertCourseModalOpen, setIsUpsertCourseModalOpen] = useRecoilState(
    isUpsertCourseModalOpenAtom
  );
  const [upsertCourseFormValues, setUpsertCourseFormValues] = useRecoilState(
    upsertCourseFormValuesAtom
  );
  const setNotificationDetails = useSetRecoilState(notificationsAtom);
  const participants = useMemo(
    () => upsertCourseFormValues?.participants ?? {},
    [upsertCourseFormValues]
  );
  const allMeetingIds = Object.values(participants).flatMap(
    (participantMeetings) =>
      participantMeetings.map((meeting) => meeting.meetingId)
  );
  const hasParticipants = Object.keys(participants).length > 0;
  const activityId = upsertCourseFormValues?.activityIds?.[0];
  const [isDeleteParticipantDialogOpen, setIsDeleteParticipantDialogOpen] =
    useRecoilState(isDeleteParticipantDialogOpenAtom);
  const [participantToDelete, setParticipantToDelete] = useState<string | null>(
    null
  );
  const selectedActivityId = useRecoilValue(selectedActivityIdAtom);

  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);

  useEffect(() => {
    if (isUpsertCourseModalOpen && signedInUser && contextTeam) {
      const initialValues = getInitialUpsertCourseFormValues(
        contextTeam,
        signedInUser,
        upsertCourseFormValues
      );

      const updatedFormValues: DBDocument<Course> = {
        ...initialValues,
        activityIds: selectedActivityId
          ? [selectedActivityId]
          : initialValues.activityIds,
      };

      setUpsertCourseFormValues(updatedFormValues);
    }
    // eslint-disable-next-line
  }, [
    isUpsertCourseModalOpen,
    selectedActivityId,
    contextTeam,
    setUpsertCourseFormValues,
    signedInUser,
  ]);

  const { data: meetings } = useGetMeetingsByIds(allMeetingIds);

  const { data: meetingsMetadata } = useGetMeetingsMetadata(allMeetingIds);

  const { isLoading: isFetchingActivities, data: activities } =
    useGetActivities(contextTeam?.id);

  const { isLoading: isFetchingPublicActivities, data: publicActivities } =
    useGetPublicActivities();

  const allActivities: Activity[] = [
    ...(activities || []),
    ...(publicActivities || []),
  ];

  const { mutateAsync: handleSaveCourse, isLoading: isSaving } =
    useSaveCourse();

  const { mutateAsync: handleDeleteMeeting, isLoading: isDeleting } =
    useDeleteMeeting();

  const participantIds = useMemo(
    () => Object.keys(participants),
    [participants]
  );
  const { data: participantsDetails } = useGetUsersByIds(participantIds);

  const navigate = useNavigate();

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!signedInUser || !upsertCourseFormValues) return;
    if (!upsertCourseFormValues.activityIds?.length) {
      setNotificationDetails((prevState) => [
        ...prevState,
        {
          header: "Missing required fields",
          body: "Please choose an activity",
          type: NotificationType.Danger,
        },
      ]);
      return;
    }
    await handleSaveCourse({
      course: upsertCourseFormValues,
      uid: signedInUser.uid,
    });
    setUpsertCourseFormValues(null);
    setIsUpsertCourseModalOpen(false);
    navigate(`${AppRoutes.MGMT_APP}${AppRoutes.COURSES}`);
  };

  const handleDeleteParticipant = async (participantId: string) => {
    if (!participants[participantId] || !signedInUser || !contextTeam) return;
    const meetingIds = participants[participantId].map(
      (meeting) => meeting.meetingId
    );
    await Promise.all(
      meetingIds.map((meetingId) => handleDeleteMeeting({ meetingId }))
    );
    const updatedParticipants = { ...participants };
    delete updatedParticipants[participantId];

    setUpsertCourseFormValues((course) => {
      if (!course) {
        return getInitialUpsertCourseFormValues(
          contextTeam,
          signedInUser,
          upsertCourseFormValues
        );
      }
      return {
        ...course,
        participants: updatedParticipants,
      };
    });
  };

  const handleApproveDeleteParticipant = async () => {
    if (participantToDelete) {
      try {
        await handleDeleteParticipant(participantToDelete);
        setIsDeleteParticipantDialogOpen(false);
        setParticipantToDelete(null);
      } catch (error) {
        console.error("Failed to delete participant:", error);
      }
    }
  };

  const isLoading =
    isFetchingActivities ||
    isFetchingPublicActivities ||
    isSaving ||
    isDeleting ||
    isLoadingUser;

  const isSubmitDisabled = !activityId;

  useEffect(() => {
    setIsDeleteParticipantDialogOpen(!!participantToDelete);
  }, [participantToDelete, setIsDeleteParticipantDialogOpen]);

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return upsertCourseFormValues ? (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div>
          Title
          <InputText
            className="mt-1 mb-3"
            value={upsertCourseFormValues.name}
            disabled={isLoading}
            onChange={(evt) =>
              setUpsertCourseFormValues({
                ...upsertCourseFormValues,
                name: evt.target.value,
              })
            }
          />
        </div>
        <div>
          Activity
          <InputSelect
            className="mt-1 mb-3"
            disabled={!!upsertCourseFormValues?.id || isLoading}
            value={activityId || ""}
            onChange={(evt) => {
              const activityId = evt.target.value;
              const selectedActivity = allActivities.find(
                (activity) => activity.id === activityId
              );
              if (!selectedActivity) return;
              setUpsertCourseFormValues({
                ...upsertCourseFormValues,
                activityIds: evt.target.value ? [evt.target.value] : [],
              });
            }}
          >
            <option value="">Select an activity</option>
            {allActivities.map((activity) => (
              <option
                key={`activity-${activity.id}`}
                value={activity.id}
                title={activity.name}
                className={activity.name ? "" : "text-muted fst-italic"}
              >
                {activity.name || "Untitled activity"}
              </option>
            ))}
          </InputSelect>
        </div>
        <div className="mt-1 mb-3">
          <DateTimeRangePicker
            startTime={upsertCourseFormValues.startsAt}
            endTime={upsertCourseFormValues.endsAt}
            onChange={(startTime: number, endTime: number) => {
              setUpsertCourseFormValues({
                ...upsertCourseFormValues,
                startsAt: startTime,
                endsAt: endTime,
              });
            }}
            disabled={isLoading}
            startTooltipContent="This course will be available for participants to join starting this date"
            endTooltipContent="This course will be available for participants to join till this date"
          />
        </div>
        <div className="mb-3 mt-3">
          Participants
          {!hasParticipants ? (
            <p className="mt-3 text-muted fst-italic">
              This course does not have any participants at the moment.
            </p>
          ) : (
            <Accordion
              items={[
                {
                  header: "Participants",
                  body: (
                    <table className="table">
                      <thead>
                        <tr>
                          <th />
                          <th>No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Progress</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participantsDetails?.map((participant, index) => (
                          <ParticipantRow
                            key={`participant-row-${index}`}
                            participant={participant}
                            index={index}
                            activityId={activityId}
                            course={upsertCourseFormValues}
                            meetingsMetadata={meetingsMetadata}
                            meetings={meetings}
                            onDeleteClick={(participantId) => {
                              setParticipantToDelete(participantId);
                            }}
                          />
                        ))}
                      </tbody>
                    </table>
                  ),
                },
              ]}
            />
          )}
          <Button
            className="float-end mt-3"
            disabled={isLoading || isSubmitDisabled}
            type="submit"
          >
            {isSaving ? (
              <Loading />
            ) : (
              <span>{upsertCourseFormValues.id ? "Save" : "Create"}</span>
            )}
          </Button>
        </div>
      </form>
      {isDeleteParticipantDialogOpen && (
        <Dialog
          header="Are you sure you want to delete this participant?"
          state={isDeleteParticipantDialogOpenAtom}
          onApprove={handleApproveDeleteParticipant}
          onCancel={() => setParticipantToDelete(null)}
          approveText="Yes"
          cancelText="No"
        />
      )}
    </>
  ) : (
    <></>
  );
};

export const UpsertCourseModal: FunctionComponent<
  UpsertCourseModalProps
> = () => {
  const [upsertCourseFormValues, setUpsertCourseFormValues] = useRecoilState(
    upsertCourseFormValuesAtom
  );
  const setSelectedActivityId = useSetRecoilState(selectedActivityIdAtom);
  const [isUpsertCourseModalOpen, setIsUpsertCourseModalOpen] = useRecoilState(
    isUpsertCourseModalOpenAtom
  );

  const onClose = () => {
    setIsUpsertCourseModalOpen(false);
    setUpsertCourseFormValues(null);
    setSelectedActivityId(null);
  };
  const isEditing = !!upsertCourseFormValues?.id;

  return (
    <Modal
      preventCloseOnBackdropClick
      isOpen={isUpsertCourseModalOpen}
      header={<h3>{isEditing ? "Edit course" : "New course"}</h3>}
      body={<UpsertCourseForm />}
      onClose={() => onClose()}
    />
  );
};
