import React, { FunctionComponent } from "react";
import "./MainPane.css";

type MainPaneProps = React.HTMLAttributes<HTMLDivElement>;

export const MainPane: FunctionComponent<MainPaneProps> = ({
  children,
  ...props
}) => {
  return (
    <div {...props} className="main-pane row m-0 p-0 justify-content-center">
      <div className="p-3 w-100">{children}</div>
    </div>
  );
};
