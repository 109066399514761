import { DBDocumentID } from "../document";
import { Puzzle } from "./common";

export enum ActivityType {
  QUEST_ROOM = "QUEST_ROOM",
  FREE_MAP = "FREE_MAP",
}

export enum ActivityMessageModalTypes {
  CONGRATULATIONS_MESSAGE = "CONGRATULATIONS_MESSAGE",
  GREETING_MESSAGE = "GREETING_MESSAGE",
}

export type Activity = {
  id?: string;
  templateId: DBDocumentID;
  name?: string;
  description?: string;
  type: ActivityType;
  puzzles: Puzzle[];
  isDeleted: boolean;
  greetingMessage: ActivityMessage;
  congratulationsMessage: ActivityMessage;
  isPublic?: boolean;
};

export interface PreuploadImageFile {
  elementIdx: number;
  file: File;
}

export interface ActivityMessage {
  text: string;
  imgSrc?: string;
}
