import React, { FunctionComponent } from "react";

type InputSelectProps = React.InputHTMLAttributes<HTMLSelectElement>;

export interface InputSelectOption {
  value: string;
  label?: string;
}

export const InputSelect: FunctionComponent<InputSelectProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <select className={`form-select ${className}`} {...props}>
      {children}
    </select>
  );
};
