import { FunctionComponent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Map } from "../Map/Map";
import { chosenElementIdxAtom } from "../../state/atoms/activities";
import {
  contextMeetingAtom,
  contextMeetingMetadataAtom,
} from "../../state/atoms/meetings";
import { isQuestModalOpenAtom } from "../../state/atoms/ui";
import { QuestRoomInteractiveElement as QuestRoomInteractiveElementType } from "../../types/activities/quest-room";
import { QuestRoomActivityMetadata } from "../../types/meeting";
import { QuestRoomInteractiveElement } from "../InteractiveElement/QuestRoomInteractiveElement";
import { Activity } from "../../types/activities/activity";

interface QuestRoomActivityPlayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
  interactiveElements: QuestRoomInteractiveElementType[];
  activity: Activity;
}

export const QuestRoomActivityPlayer: FunctionComponent<
  QuestRoomActivityPlayerProps
> = ({ backgroundImage, interactiveElements, activity }) => {
  const activityId = activity.id || "";
  const contextMeeting = useRecoilValue(contextMeetingAtom);
  const contextMeetingMetadata = useRecoilValue(contextMeetingMetadataAtom);
  const setIsQuestModalOpen = useSetRecoilState(isQuestModalOpenAtom);
  const setChosenElementIdx = useSetRecoilState(chosenElementIdxAtom);
  if (!contextMeeting) return <></>;

  return (
    <Map backgroundImage={backgroundImage}>
      {interactiveElements.map(
        ({ imageUnsolved, imageSolved, rectangle }, index) => {
          const isSolved = (
            contextMeetingMetadata?.metadata?.[
              activityId
            ] as QuestRoomActivityMetadata
          )?.puzzlesState?.[index]?.isSolved;

          const { question } = activity.puzzles[index] || {};
          const isEmptyQuestion = !question?.text && !question?.imgSrc;
          const isClickable = !isSolved && !isEmptyQuestion;

          return (
            <QuestRoomInteractiveElement
              key={`quest-room-interactive-element-${index}`}
              index={index}
              rectangle={rectangle}
              className={`${isClickable ? "pointer" : ""}`}
              showSolved={isSolved}
              imageSolved={imageSolved}
              imageUnsolved={imageUnsolved}
              onClick={() => {
                if (!isClickable) return;
                setChosenElementIdx(index);
                setIsQuestModalOpen(true);
              }}
            />
          );
        }
      )}
    </Map>
  );
};
