import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getRandomIntInclusive } from "../../services/utils";
import {
  contextMeetingAtom,
  contextMeetingMetadataAtom,
  movingMeepleIndexAtom,
} from "../../state/atoms/meetings";
import { FreeMapInteractiveElement as FreeMapInteractiveElementType } from "../../types/activities/free-map";
import { InteractiveElementType } from "../../types/activities/common";
import {
  DiceRoll,
  FreeMapActivityMetadata,
  Meeple as MeepleType,
} from "../../types/meeting";
import { Meeple } from "../Meeple/Meeple";
import { useRectMeasure } from "../../hooks";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { DBDocumentID } from "../../types/document";
import { updateDiceRollRequest } from "../../services/firebase";
import { Map } from "../Map/Map";
import { FreeMapInteractiveElement } from "../InteractiveElement/FreeMapInteractiveElement";
import { Activity } from "../../types/activities/activity";
import { isQuestModalOpenAtom } from "../../state/atoms/ui";
import { chosenElementIdxAtom } from "../../state/atoms/activities";
import { useAddUserMeeple } from "../../services/meetings";

interface FreeMapActivityPlayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
  interactiveElements: FreeMapInteractiveElementType[];
  activity: Activity;
}

export const FreeMapActivityPlayer: FunctionComponent<
  FreeMapActivityPlayerProps
> = ({ backgroundImage, interactiveElements, activity }) => {
  const activityId = activity.id || "";
  const contextMeeting = useRecoilValue(contextMeetingAtom);
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextMeetingMetadata = useRecoilValue(contextMeetingMetadataAtom);
  const movingMeepleIndex = useRecoilValue(movingMeepleIndexAtom);
  const setIsQuestModalOpen = useSetRecoilState(isQuestModalOpenAtom);
  const setChosenElementIdx = useSetRecoilState(chosenElementIdxAtom);
  const [unsolvedIndex, setUnsolvedIndex] = useState<number>(0);
  const { callbackRef, rect: mapRect } = useRectMeasure();
  const [meeples, setMeeples] = useState<MeepleType[] | null>(null);
  const [lastUpdatedMeepleId, setLastUpdatedMeepleId] =
    useState<DBDocumentID | null>(null);

  const [isFirstTimeEntering, setIsFirstTimeEntering] = useState(true);
  const [currDiceRoll, setCurrDiceRoll] = useState<DiceRoll>();

  const gridCellWidth = (mapRect?.width || 0) / 12;
  const meepleSize = gridCellWidth * 0.9;

  const { metadata } = (contextMeetingMetadata || {}) as {
    metadata?: Record<DBDocumentID, FreeMapActivityMetadata>;
  };

  const { mutateAsync: handleAddUserMeeple } = useAddUserMeeple(
    contextMeetingMetadata?.meetingId
  );

  useEffect(() => {
    const addUserMeepleIfNotExist = async () => {
      if (!contextMeetingMetadata?.meetingId || !signedInUserId) return;
      if (
        !metadata?.[activityId]?.meeples?.find(
          (meeple: MeepleType) => meeple.uid === signedInUserId
        )
      ) {
        await handleAddUserMeeple({
          uid: signedInUserId,
        });
      }

      const currUnsolvedIdx =
        metadata?.[activityId]?.puzzlesState?.findIndex(
          (puzzle) => !puzzle.isSolved
        ) ?? -1;
      setUnsolvedIndex(currUnsolvedIdx);
    };
    addUserMeepleIfNotExist();
  }, [
    contextMeetingMetadata,
    signedInUserId,
    activityId,
    handleAddUserMeeple,
    metadata,
  ]);

  useEffect(() => {
    if (!metadata?.[activityId]?.meeples) return;
    setMeeples(metadata[activityId].meeples);
  }, [metadata, activityId]);

  useEffect(() => {
    if (isFirstTimeEntering) {
      setCurrDiceRoll(metadata?.[activityId].diceRoll);
      setIsFirstTimeEntering(false);
    } else {
      if (
        currDiceRoll?.lastRolledAt?.seconds !==
        metadata?.[activityId].diceRoll?.lastRolledAt?.seconds
      ) {
        setCurrDiceRoll(metadata?.[activityId].diceRoll);
      }
    }
  }, [metadata, activityId, currDiceRoll, isFirstTimeEntering]);

  useEffect(() => {
    if (!meeples?.length) return;

    const lastUpdatedMeeple: MeepleType | null = meeples.reduce(
      (prev: MeepleType, curr: MeepleType) => {
        if (!prev) return curr;
        return prev.updatedAt?.seconds > curr.updatedAt?.seconds ? prev : curr;
      }
    );

    setLastUpdatedMeepleId(lastUpdatedMeeple?.uid || null);
  }, [meeples]);

  if (!contextMeeting) return <></>;

  const rollDice = async () => {
    if (!contextMeeting.id) return;

    const newDiceRoll = getRandomIntInclusive(0, 5);

    await updateDiceRollRequest(contextMeeting.id, activityId, newDiceRoll);
  };

  const hasUnsolvedPuzzles =
    activity.puzzles.length > 0 && unsolvedIndex !== -1;

  const dragMeeple = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((!movingMeepleIndex && movingMeepleIndex !== 0) || !meeples || !mapRect)
      return;

    const meepleWidthPercentage = (meepleSize * 100) / (mapRect.width || 1);
    const meepleHeightPercentage = (meepleSize * 100) / (mapRect.height || 1);

    const posX = ev.clientX - mapRect.left;
    const posY = ev.clientY - mapRect.top;
    const posXPercentage =
      (posX * 100) / (mapRect.width || 1) - meepleWidthPercentage / 2;
    const posYPercentage =
      (posY * 100) / (mapRect.height || 1) - meepleHeightPercentage / 2;
    setMeeples((prevMeeples) =>
      (prevMeeples || []).map((meeple, index) => {
        if (index !== movingMeepleIndex) return meeple;
        const updatedMeeple = { ...meeple };
        const { x, y } = meeple;
        updatedMeeple.x =
          posXPercentage < 0 || posXPercentage > 100 - meepleWidthPercentage
            ? x
            : posXPercentage;

        updatedMeeple.y =
          posYPercentage < 0 || posYPercentage > 100 - meepleHeightPercentage
            ? y
            : posYPercentage;

        return updatedMeeple;
      })
    );
  };

  return (
    <Map
      onMouseMove={dragMeeple}
      backgroundImage={backgroundImage}
      ref={callbackRef}
    >
      {interactiveElements.map(({ type, images, rectangle }, index) => {
        const isClickable =
          type === InteractiveElementType.Dice || hasUnsolvedPuzzles;

        return (
          <FreeMapInteractiveElement
            key={`interactive-element-${index}`}
            type={type}
            currDiceNumber={currDiceRoll?.number || 0}
            images={images}
            index={index}
            rectangle={rectangle}
            className={isClickable ? "pointer" : ""}
            onClick={async () => {
              if (!isClickable) return;
              if (type === InteractiveElementType.Dice) {
                await rollDice();
              } else {
                setChosenElementIdx(unsolvedIndex);
                setIsQuestModalOpen(true);
              }
            }}
          />
        );
      })}
      {meeples?.map(({ imageSrc, x, y, uid }, index) => (
        <Meeple
          key={`meeple-${index}`}
          imageSrc={imageSrc}
          x={x}
          y={y}
          meepleIndex={index}
          meepleSize={meepleSize}
          uid={uid}
          isLastUpdated={lastUpdatedMeepleId === uid}
        />
      ))}
    </Map>
  );
};
