import { atom } from "recoil";
import { PreuploadImageFile } from "../../types/activities/activity";
import { DBDocument } from "../../types/document";
import { ActivityTemplate } from "../../types/activities/common";

export const upsertActivityTemplateFormValuesAtom =
  atom<DBDocument<ActivityTemplate> | null>({
    key: "upsertActivityTemplateFormValuesAtom",
    default: null,
  });

export const questRoomInteractiveElementSolvedImagesAtom = atom<
  PreuploadImageFile[]
>({
  key: "questRoomInteractiveElementSolvedImagesAtom",
  default: [],
});

export const questRoomInteractiveElementUnsolvedImagesAtom = atom<
  PreuploadImageFile[]
>({
  key: "questRoomInteractiveElementUnsolvedImagesAtom",
  default: [],
});

export const activityTemplateBackgroundImageAtom =
  atom<PreuploadImageFile | null>({
    key: "activityTemplateBackgroundImageAtom",
    default: null,
  });
