import React, { FunctionComponent } from "react";
import "./Card.css";

type CardProps = React.HTMLAttributes<HTMLDivElement>;

export const Card: FunctionComponent<CardProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={`card border-0 p-4 ${className}`}>
      {children}
    </div>
  );
};
