import React, { FunctionComponent, useState } from "react";
import { Button, ButtonProps } from "../Button/Button";

interface ToggleButtonGroupProps {
  buttons: (ButtonProps & { value: string })[];
  onChange: (value: string) => void;
  className?: string;
  isDisabled?: boolean;
}

export const ToggleButtonGroup: FunctionComponent<ToggleButtonGroupProps> = ({
  className,
  buttons,
  onChange,
  isDisabled: isButtonGroupDisabled = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(buttons[0].value);

  const handleButtonClick = (value: string): void => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div
      className={`btn-group ${className} ${isButtonGroupDisabled ? "disabled" : ""}`}
    >
      {buttons.map(
        ({ value, children, disabled: isButtonDisabled, ...rest }, index) => {
          const isDisabled = isButtonGroupDisabled || isButtonDisabled;
          return (
            <Button
              key={`toggle-button-${index}`}
              className={`${
                isDisabled ? "disabled pointer-events-on-disable" : ""
              }`}
              btnType="secondary"
              btnSize="sm"
              type="button"
              outline={value !== selectedValue}
              onClick={() => handleButtonClick(value)}
              showTooltip={isDisabled}
              disabled={isButtonDisabled}
              {...rest}
            >
              {children}
            </Button>
          );
        }
      )}
    </div>
  );
};
