import React, {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
  SetStateAction,
} from "react";
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from "recoil";
import { InputFile } from "../../../../components/Input/InputFile";
import { InputTextArea } from "../../../../components/Input/InputTextArea";
import {
  activityCongratulationsImageAtom,
  activityGreetingImageAtom,
  upsertActivityFormValuesAtom,
} from "../../../../state/atoms/activities";
import { FileTypes } from "../../../../types/input";
import {
  ActivityMessage,
  PreuploadImageFile,
} from "../../../../types/activities/activity";
import { ImagePreview } from "../../../../components/ImagePreview/ImagePreview";

export const ActivityMessagesForm: FunctionComponent<{
  isAdding: boolean;
}> = ({ isAdding }) => {
  const [upsertActivityFormValues, setUpsertActivityFormValues] =
    useRecoilState(upsertActivityFormValuesAtom);

  const [greetingMessage, setGreetingMessage] = useState<ActivityMessage>(
    upsertActivityFormValues?.greetingMessage || {
      text: "",
      imgSrc: "",
    }
  );

  const setActivityGreetingImage = useSetRecoilState(activityGreetingImageAtom);

  const [congratulationsMessage, setCongratulationsMessage] =
    useState<ActivityMessage>(
      upsertActivityFormValues?.congratulationsMessage || {
        text: "",
        imgSrc: "",
      }
    );

  const setActivityCongratulationsImage = useSetRecoilState(
    activityCongratulationsImageAtom
  );

  useEffect(() => {
    if (!upsertActivityFormValues?.templateId) return;
    setUpsertActivityFormValues({
      ...upsertActivityFormValues,
      greetingMessage,
      congratulationsMessage,
    });
    // eslint-disable-next-line
  }, [greetingMessage, congratulationsMessage]);

  return (
    <>
      <ActivityMessageForm
        inputLabel="Greeting message"
        isLoading={isAdding}
        activityMessage={greetingMessage}
        setActivityMessage={setGreetingMessage}
        setActivityImage={setActivityGreetingImage}
      />
      <ActivityMessageForm
        inputLabel="Successful finishing message"
        isLoading={isAdding}
        activityMessage={congratulationsMessage}
        setActivityMessage={setCongratulationsMessage}
        setActivityImage={setActivityCongratulationsImage}
      />
    </>
  );
};

const ActivityMessageForm: FunctionComponent<{
  inputLabel: string;
  isLoading: boolean;
  activityMessage: ActivityMessage;
  setActivityMessage: React.Dispatch<SetStateAction<ActivityMessage>>;
  setActivityImage: SetterOrUpdater<PreuploadImageFile | null>;
}> = ({
  inputLabel,
  isLoading,
  activityMessage,
  setActivityMessage,
  setActivityImage,
}) => {
  return (
    <div className="mb-4">
      {inputLabel}
      <div className="mt-2">
        <InputTextArea
          disabled={isLoading}
          value={activityMessage.text}
          onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
            setActivityMessage((prevState) => ({
              ...prevState,
              text: evt.target.value,
            }));
          }}
          className="mb-2"
        />
        {activityMessage.imgSrc ? (
          <ImagePreview
            imgSrc={activityMessage.imgSrc}
            onRemove={() => {
              setActivityMessage((prevState) => ({
                ...prevState,
                imgSrc: "",
              }));
              setActivityImage(null);
            }}
            disableRemove={isLoading}
          />
        ) : (
          <InputFile
            src={activityMessage.imgSrc || ""}
            acceptFile={[FileTypes.IMAGE]}
            disabled={isLoading}
            onChange={async (ev) => {
              if (ev.target.files) {
                const files = Array.from(ev.target.files);
                if (files.length === 0) return;
                setActivityMessage((prevState) => ({
                  ...prevState,
                  imgSrc: URL.createObjectURL(files[0]),
                }));
                setActivityImage({
                  elementIdx: 0,
                  file: files[0],
                });
              }
            }}
          >
            <p
              className={`btn btn-outline-secondary w-100 ${
                isLoading ? "disabled" : ""
              }`}
            >
              Upload image
            </p>
          </InputFile>
        )}
      </div>
    </div>
  );
};
