import { atom } from "recoil";

import { Team } from "../../types/team";
import { UID } from "../../types/user";

export const waitingForAuthAtom = atom<boolean>({
  key: "waitingForAuthAtom",
  default: true,
});

export const signedInUserIdAtom = atom<UID>({
  key: "signedInUserIdAtom",
  default: "",
});

export const contextTeamAtom = atom<Team | null>({
  key: "contextTeamIdAtom",
  default: null,
});
