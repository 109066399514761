import { FunctionComponent, MouseEventHandler } from "react";
import { Button } from "../Button/Button";
import { Icon, Icons } from "../Icons/Icon";
import { Image } from "../Image/Image";

export const ImagePreview: FunctionComponent<{
  disableRemove?: boolean;
  imgSrc: string;
  onRemove: MouseEventHandler<HTMLButtonElement>;
  removeButtonPosition?: "on-top" | "side-by-side";
}> = ({ disableRemove, imgSrc, onRemove, removeButtonPosition = "on-top" }) => {
  return (
    <div className="row justify-content-center w-100 m-0 mt-2">
      <div
        className={`position-relative d-flex align-items-center w-auto p-0 ${removeButtonPosition === "side-by-side" ? "flex-row-reverse" : ""}`}
      >
        <Button
          btnType="danger"
          btnSize="sm"
          disabled={disableRemove}
          className={`${removeButtonPosition === "side-by-side" ? "m-2" : "position-absolute top-0 end-0 w-auto m-2"}`}
          onClick={onRemove}
        >
          <Icon icon={Icons.DELETE} />
        </Button>
        <Image className="rounded mb-1 w-auto max-height-300px" src={imgSrc} />
      </div>
    </div>
  );
};
