import React, { FunctionComponent } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import "./Button.css";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: "primary" | "secondary" | "danger";
  outline?: boolean;
  btnSize?: "sm" | "md" | "lg";
  tooltipProps?: TippyProps;
  showTooltip?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  btnType = "primary",
  outline = false,
  btnSize,
  showTooltip = false,
  tooltipProps,
  ...props
}) => {
  return showTooltip && tooltipProps ? (
    <Tippy {...tooltipProps}>
      <button
        className={`btn btn${outline ? "-outline-" : "-"}${btnType} py-1 ${
          btnSize ? `btn-${btnSize}` : ""
        } ${className}`}
        {...props}
      >
        {children}
      </button>
    </Tippy>
  ) : (
    <button
      className={`btn btn${outline ? "-outline-" : "-"}${btnType} py-1 ${
        btnSize ? `btn-${btnSize}` : ""
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
