import React, { FunctionComponent } from "react";
import "./CoverPage.css";

type CoverPageProps = React.HTMLAttributes<HTMLDivElement>;

export const CoverPage: FunctionComponent<CoverPageProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`cover-page-fullscreen d-flex text-center align-items-center justify-content-center z-index-highest ${
        className || ""
      }`}
    >
      <div>{children}</div>
    </div>
  );
};
