import React, { FunctionComponent } from "react";
import "./NavigationPane.css";

interface NavigationPaneButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  selected: boolean;
  isHidden?: boolean;
}

export const NavigationPaneButton: FunctionComponent<
  NavigationPaneButtonProps
> = ({ children, className, selected, isHidden = false, ...props }) => {
  return (
    <div
      {...props}
      className={`navigation-pane-button ${
        selected ? "navigation-pane-button-selected" : ""
      } m-0 text-white text-center pointer ${className || ""} ${
        isHidden ? "d-none" : ""
      }`}
    >
      {children}
    </div>
  );
};
