import { FunctionComponent } from "react";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { ActivityType } from "../../../../types/activities/activity";
import Tippy from "@tippyjs/react";

interface TemplateTypeIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  templateType: ActivityType;
}

export const TemplateTypeIcon: FunctionComponent<TemplateTypeIconProps> = ({
  templateType,
  className,
}) => {
  const templateTypeTooltip =
    templateType === ActivityType.QUEST_ROOM ? "Quest room" : "Free map";

  return (
    <div className="d-flex align-items-center">
      <Tippy content={templateTypeTooltip}>
        <div className="d-flex">
          {templateType === ActivityType.FREE_MAP ? (
            <Icon icon={Icons.MAP} className={className} />
          ) : (
            <Icon icon={Icons.SOLVED} className={className} />
          )}
        </div>
      </Tippy>
    </div>
  );
};
