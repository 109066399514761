import React, { useState, useEffect } from "react";
import { HOUR_DIVIDER, TIME_OPTIONS_LENGTH } from "../../services/consts";
import { getCurrentFullTime, makeTimestamp } from "../../services/utils";
import Tippy from "@tippyjs/react";
import { Icon, Icons } from "../Icons/Icon";

const DateTimeRangePicker: React.FC<{
  startTime: number;
  endTime: number;
  onChange: (startTime: number, endTime: number) => void | Promise<void>;
  disabled: boolean;
  startTooltipContent?: string;
  endTooltipContent?: string;
}> = ({
  startTime: startTimeProp,
  endTime: endTimeProp,
  onChange,
  disabled,
  startTooltipContent,
  endTooltipContent,
}) => {
  const { currentYear: startYear, currentTimeIndex: startTimeIndex } =
    getCurrentFullTime(startTimeProp);
  const { currentYear: endYear, currentTimeIndex: endTimeIndex } =
    getCurrentFullTime(endTimeProp);

  const timeOptions = Array.from({ length: TIME_OPTIONS_LENGTH }, (_, i) => {
    const hour = Math.floor(i / HOUR_DIVIDER);
    const minute = (i % HOUR_DIVIDER) * 15;
    const label = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    return { value: i, label };
  });

  const [startTime, setStartTime] = useState({
    year: startYear,
    month: new Date(startTimeProp).getMonth(),
    day: new Date(startTimeProp).getDate(),
    time: timeOptions[startTimeIndex].label,
  });

  const [endTime, setEndTime] = useState({
    year: endYear,
    month: new Date(endTimeProp).getMonth(),
    day: new Date(endTimeProp).getDate(),
    time: timeOptions[endTimeIndex].label,
  });

  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();

  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = currentYear + i;
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  });

  const daysInMonth = new Date(
    startTime.year,
    startTime.month + 1,
    0
  ).getDate();

  const dayOptions = Array.from({ length: daysInMonth }, (_, i) => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ));

  const [startTimeValidationError, setStartTimeValidationError] =
    useState<string>("");
  const [endTimeValidationError, setEndTimeValidationError] =
    useState<string>("");

  useEffect(() => {
    const startTimestamp = makeTimestamp({
      year: startTime.year,
      month: startTime.month,
      day: startTime.day,
      time: startTime.time,
    });

    const endTimestamp = makeTimestamp({
      year: endTime.year,
      month: endTime.month,
      day: endTime.day,
      time: endTime.time,
    });

    let isErrored = false;
    if (startTimestamp < Date.now()) {
      setStartTimeValidationError("Start time must be in the future");
      isErrored = true;
    } else {
      setStartTimeValidationError("");
    }

    if (startTimestamp >= endTimestamp) {
      setEndTimeValidationError("Start time must be before end time");
      isErrored = true;
    } else {
      setEndTimeValidationError("");
    }

    if (isErrored) {
      return;
    }

    onChange(startTimestamp, endTimestamp);
    // eslint-disable-next-line
  }, [startTime, endTime]);

  return (
    <div>
      <div className="d-flex gap-1">
        Starts at
        {startTooltipContent && (
          <Tippy content={startTooltipContent}>
            <div>
              <Icon icon={Icons.INFO} />
            </div>
          </Tippy>
        )}
      </div>
      <div className="mt-1 mb-4 row g-1 p-0 border-0">
        <label className="d-flex flex-column text-muted small col-12 col-sm-4">
          Month
          <select
            className="form-select"
            value={startTime.month}
            onChange={(ev) =>
              setStartTime({
                ...startTime,
                month: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {months.map((name, index) => (
              <option key={index} value={index}>
                {name}
              </option>
            ))}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-2">
          Day
          <select
            className="form-select"
            value={startTime.day}
            onChange={(ev) =>
              setStartTime({
                ...startTime,
                day: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {dayOptions}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-3">
          Year
          <select
            className="form-select"
            value={startTime.year}
            onChange={(ev) =>
              setStartTime({
                ...startTime,
                year: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {yearOptions}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-3">
          Time
          <select
            className="form-select"
            value={startTime.time}
            onChange={(ev) =>
              setStartTime({
                ...startTime,
                time: ev.target.value,
              })
            }
            disabled={disabled}
          >
            {timeOptions.map((o) => (
              <option key={o.value} value={o.label}>
                {o.label}
              </option>
            ))}
          </select>
        </label>
        {startTimeValidationError && (
          <p className="m-0 text-danger">{startTimeValidationError}</p>
        )}
      </div>
      <div className="d-flex gap-1">
        Ends at
        {endTooltipContent && (
          <Tippy content={endTooltipContent}>
            <div>
              <Icon icon={Icons.INFO} />
            </div>
          </Tippy>
        )}
      </div>
      <div className="mt-1 mb-2 row g-1 p-0 border-0">
        <label className="d-flex flex-column text-muted small col-12 col-sm-4">
          Month
          <select
            className="form-select"
            value={endTime.month}
            onChange={(ev) =>
              setEndTime({
                ...endTime,
                month: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {months.map((name, index) => (
              <option key={index} value={index}>
                {name}
              </option>
            ))}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-2">
          Day
          <select
            className="form-select"
            value={endTime.day}
            onChange={(ev) =>
              setEndTime({
                ...endTime,
                day: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {dayOptions}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-3">
          Year
          <select
            className="form-select"
            value={endTime.year}
            onChange={(ev) =>
              setEndTime({
                ...endTime,
                year: +ev.target.value,
              })
            }
            disabled={disabled}
          >
            {yearOptions}
          </select>
        </label>
        <label className="d-flex flex-column text-muted small col-12 col-sm-3">
          Time
          <select
            className="form-select"
            value={endTime.time}
            onChange={(ev) =>
              setEndTime({
                ...endTime,
                time: ev.target.value,
              })
            }
            disabled={disabled}
          >
            {timeOptions.map((o) => (
              <option key={o.value} value={o.label}>
                {o.label}
              </option>
            ))}
          </select>
        </label>
      </div>
      {endTimeValidationError && (
        <p className="m-0 text-danger">{endTimeValidationError}</p>
      )}
    </div>
  );
};

export default DateTimeRangePicker;
