import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AppUser, ProfileSettings, UID } from "../types/user";
import {
  getUserRequest,
  getUsersByIds,
  saveProfileSettingsRequest,
} from "./firebase";
import { logger } from "./logger";
import { StatePrimaryKeys } from "./consts";
import { DBDocument } from "../types/document";
import { queryClient } from "..";

export const useGetUsersByIds = (
  uids: UID[],
  options?: UseQueryOptions<DBDocument<AppUser>[], Error>
) =>
  useQuery<DBDocument<AppUser>[], Error>(
    [StatePrimaryKeys.Users, uids],
    async () => {
      const users = await getUsersByIds(uids);
      return users;
    },
    {
      onError: (error) => {
        logger.error("Error in fetching users", error);
      },
      ...options,
    }
  );

export const useGetUser = (
  uid?: UID,
  options?: UseQueryOptions<DBDocument<AppUser> | null, Error>
) =>
  useQuery<DBDocument<AppUser> | null, Error>(
    [StatePrimaryKeys.Users, uid],
    () => getUserRequest(uid),
    {
      onError: (error) => {
        logger.error("Error in fetching user", error);
      },
      ...options,
    }
  );

export const useSaveProfileSettings = (
  uid: UID,
  options?: UseMutationOptions<{ success: boolean }, Error, ProfileSettings>
) => {
  return useMutation<{ success: boolean }, Error, ProfileSettings>(
    [StatePrimaryKeys.Users, uid],
    (profileSetting) => {
      return saveProfileSettingsRequest(profileSetting);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([StatePrimaryKeys.Users, uid]);
      },
      onError: (error) => {
        logger.error("Error in saving profile settings", error);
      },
      ...options,
    }
  );
};
