import React from "react";
import Tippy from "@tippyjs/react";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import {
  MeetingMetadata,
  Meeting,
  MeetingStatus,
} from "../../../../types/meeting";
import { DBDocument } from "../../../../types/document";
import { AppUser } from "../../../../types/user";
import { Course } from "../../../../types/course";

const meetingStatusDisplay: { [key in MeetingStatus]: string } = {
  [MeetingStatus.SCHEDULED]: "Scheduled",
  [MeetingStatus.IN_PROGRESS]: "In progress",
  [MeetingStatus.COMPLETED]: "Completed",
  [MeetingStatus.DELETED]: "Deleted",
};

type ParticipantRowProps = {
  participant: DBDocument<AppUser>;
  index: number;
  activityId?: string;
  course: DBDocument<Course>;
  meetingsMetadata?: MeetingMetadata[];
  meetings?: Meeting[];
  onDeleteClick: (participantId: string) => void;
};

const ParticipantRow: React.FC<ParticipantRowProps> = ({
  participant,
  index,
  activityId,
  course,
  meetingsMetadata,
  meetings,
  onDeleteClick,
}) => {
  const currParticipant = course.participants[participant.uid];
  const currMeetingId = currParticipant[0].meetingId;
  const meetingMetadata = meetingsMetadata?.find(
    (metadata) => metadata.meetingId === currMeetingId
  );

  const currActivityMetadata = activityId
    ? meetingMetadata?.metadata?.[activityId]?.puzzlesState
    : null;

  const totalPuzzles = currActivityMetadata ? currActivityMetadata.length : 0;
  const solvedPuzzles = currActivityMetadata
    ? currActivityMetadata.filter(({ isSolved }) => isSolved).length
    : 0;

  const currMeeting = meetings?.find((meeting) => meeting.id === currMeetingId);
  const status = currMeeting?.status;
  const displayStatus = status ? meetingStatusDisplay[status] : "Unknown";

  return (
    <tr key={`participant-row-${index}`}>
      <td>
        <Tippy content="Delete participant">
          <div
            className="pointer text-danger"
            onClick={() => onDeleteClick(participant.uid)}
          >
            <Icon icon={Icons.DELETE} />
          </div>
        </Tippy>
      </td>
      <td>{index + 1}</td>
      <td className="table-cell">
        {participant.displayName || "Unknown User"}
      </td>
      <td className="table-cell">{participant.email}</td>
      <td>{displayStatus}</td>
      <td>{`${solvedPuzzles} out of ${totalPuzzles} questions`}</td>
    </tr>
  );
};

export default ParticipantRow;
