import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RecoilState, useRecoilState } from "recoil";

import { logger } from "../../services/logger";
import { Button } from "../Button/Button";
import { Animation } from "../Animation/Animation";

interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
  state: RecoilState<boolean>;
  header: ReactNode;
  consentOnly?: boolean;
  onApprove: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  approveText?: string;
  cancelText?: string;
  dangerous?: boolean;
}

export const Dialog: FunctionComponent<DialogProps> = ({
  state,
  header,
  consentOnly = false,
  onApprove,
  onCancel,
  approveText = "Ok",
  cancelText = "Cancel",
  dangerous = false,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useRecoilState(state);
  const [isInProcess, setIsInProcess] = useState(false);

  const onApproveWrapped = useCallback(async () => {
    try {
      setIsInProcess(true);
      await onApprove();
      setIsDialogOpen(false);
    } catch (err) {
      logger.error("Error occurred while approving dialog", err);
    } finally {
      setIsInProcess(false);
    }
  }, [onApprove, setIsDialogOpen]);

  const onCancelWrapped = useCallback(async () => {
    try {
      setIsInProcess(true);
      await onCancel?.();
      setIsDialogOpen(false);
    } catch (err) {
      logger.error("Error occurred while cancelling dialog", err);
    } finally {
      setIsInProcess(false);
    }
  }, [onCancel, setIsDialogOpen]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onCancelWrapped();
      }
    },
    [onCancelWrapped]
  );

  useEffect(() => {
    logger.debug("Dialog.tsx, useEffect#1 fired");
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return isDialogOpen ? (
    <Animation animateOn={`dialog-${isDialogOpen ? "show" : "hide"}`}>
      <div className="modal-backdrop show" />
      <div
        className="modal d-block show"
        onClick={() => setIsDialogOpen(false)}
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered h-auto"
          onClick={(evt) => evt.stopPropagation()}
        >
          <div className="modal-content">
            <div className="modal-header border-0 d-block">
              <div className="float-left">{header || <></>}</div>
            </div>
            <div className="modal-footer border-0 text-center">
              {!consentOnly && (
                <button
                  className="btn text-secondary p-1 px-2 ml-1"
                  disabled={isInProcess}
                  onClick={() => onCancelWrapped()}
                >
                  {cancelText}
                </button>
              )}
              <Button
                btnType={`${dangerous ? "danger" : "primary"}`}
                className="p-1 px-2"
                disabled={isInProcess}
                onClick={() => onApproveWrapped()}
              >
                {approveText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Animation>
  ) : (
    <></>
  );
};
