import { selector } from "recoil";
import { upsertMeetingFormValuesAtom } from "../atoms/meetings";

export const canUpsertMeetingAtom = selector<boolean>({
  key: "canUpsertMeetingAtom",
  get: ({ get }) => {
    const formValues = get(upsertMeetingFormValuesAtom);

    if (!formValues) {
      return false;
    }

    const { plannedStartTimestamp, plannedEndTimestamp, activityIds } =
      formValues;

    return (
      !!plannedStartTimestamp && !!plannedEndTimestamp && !!activityIds?.length
    );
  },
});
