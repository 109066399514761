import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { Activity } from "../types/activities/activity";
import { DBDocument, DBDocumentID } from "../types/document";
import {
  Meeting,
  MeetingMetadata,
  MeetingStatus,
  SystemMessagesType,
} from "../types/meeting";
import { UID } from "../types/user";
import {
  getMeetingsRequest,
  saveMeetingRequest,
  changeMeetingStatusRequest,
  getMeetingByIdRequest,
  getActivitiesRequest,
  markQuestionAsAnsweredRequest,
  changeMeeplePositionRequest,
  addUserMeepleRequest,
  getMeetingsMetadataRequest,
  getMeetingsByIdsRequest,
  markSystemMessageAsSeenRequest,
} from "./firebase";
import { logger } from "./logger";
import { queryClient } from "..";
import { StatePrimaryKeys, MeetingsStateKeys, StateSubKeys } from "./consts";

export const useGetMeetings = (
  data: {
    userId: UID;
    teamId?: DBDocumentID;
    statuses?: MeetingStatus[];
  },
  options?: UseQueryOptions<DBDocument<Meeting>[], Error>
) =>
  useQuery<DBDocument<Meeting>[], Error>(
    [StatePrimaryKeys.Meetings],
    async () => {
      const {
        userId,
        teamId,
        statuses = [
          MeetingStatus.SCHEDULED,
          MeetingStatus.IN_PROGRESS,
          MeetingStatus.COMPLETED,
        ],
      } = data;

      const meetings = await getMeetingsRequest(userId, teamId, statuses);
      const activities = await getActivitiesRequest(teamId);
      const meetingsWithActivities = meetings.map((meeting) => {
        return {
          ...meeting,
          activities: meeting.activityIds?.map((activityId) => {
            return {
              ...activities.find(({ id }) => id === activityId),
            } as Activity;
          }),
        };
      });

      return meetingsWithActivities;
    },
    {
      onError: (error) => {
        logger.error("Error in fetching meetings", error);
      },
      ...options,
    }
  );

export const useGetMeetingById = (
  meetingId: DBDocumentID,
  options?: UseQueryOptions<DBDocument<Meeting>, Error>
) =>
  useQuery<DBDocument<Meeting>, Error>(
    [StatePrimaryKeys.Meetings, meetingId],
    () => getMeetingByIdRequest(meetingId),
    {
      onError: (error) => {
        logger.error("Error in fetching meeting", error);
      },
      enabled: false,
      ...options,
    }
  );

export const useGetMeetingsByIds = (
  meetingsId: DBDocumentID[],
  options?: UseQueryOptions<DBDocument<Meeting>[], Error>
) =>
  useQuery<DBDocument<Meeting>[], Error>(
    [StatePrimaryKeys.Meetings, meetingsId],
    () => getMeetingsByIdsRequest(meetingsId),
    {
      onError: (error) => {
        logger.error("Error in fetching meetings", error);
      },
      ...options,
    }
  );

export const useGetMeetingsMetadata = (
  meetingsId: DBDocumentID[],
  options?: UseQueryOptions<MeetingMetadata[], Error>
) =>
  useQuery<MeetingMetadata[], Error>(
    [
      StatePrimaryKeys.Meetings,
      meetingsId,
      StateSubKeys[StatePrimaryKeys.Meetings]?.[
        MeetingsStateKeys.MeetingsMetadata
      ],
    ],
    () => getMeetingsMetadataRequest(meetingsId),
    {
      onError: (error) => {
        logger.error("Error in fetching meetings metadata", error);
      },
      ...options,
    }
  );

export const useSaveMeeting = (
  options?: UseMutationOptions<{ meetingId: DBDocumentID }, Error, Meeting>
) =>
  useMutation<{ meetingId: DBDocumentID }, Error, Meeting>(
    [StatePrimaryKeys.Meetings],
    (newMeetingData: Meeting) => {
      if (!newMeetingData) throw new Error("No meeting data was provided");
      return saveMeetingRequest(newMeetingData);
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([StatePrimaryKeys.Meetings]);
      },
      onError: (error) => {
        logger.error("Error in creating new meeting", error);
      },
      ...options,
    }
  );

export const useStartMeeting = (
  options?: UseMutationOptions<
    { success: boolean },
    Error,
    { meetingId: DBDocumentID }
  >
) =>
  useMutation<{ success: boolean }, Error, { meetingId: DBDocumentID }>(
    [StatePrimaryKeys.Meetings],
    ({ meetingId }: { meetingId: DBDocumentID }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return changeMeetingStatusRequest(meetingId, MeetingStatus.IN_PROGRESS);
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([StatePrimaryKeys.Meetings]);
      },
      onError: (error, { meetingId }) => {
        logger.error(`Error starting meeting with id ${meetingId}`, error);
      },
      ...options,
    }
  );

export const useEndMeeting = (
  options?: UseMutationOptions<
    { success: boolean },
    Error,
    { meetingId: DBDocumentID }
  >
) =>
  useMutation<{ success: boolean }, Error, { meetingId: DBDocumentID }>(
    [StatePrimaryKeys.Meetings],
    ({ meetingId }: { meetingId: DBDocumentID }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return changeMeetingStatusRequest(meetingId, MeetingStatus.COMPLETED);
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([StatePrimaryKeys.Meetings]);
      },
      onError: (error, { meetingId }) => {
        logger.error(`Error ending meeting with id ${meetingId}`, error);
      },
      ...options,
    }
  );

export const useDeleteMeeting = () =>
  useMutation<{ success: boolean }, Error, { meetingId: DBDocumentID }>(
    [StatePrimaryKeys.Meetings],
    ({ meetingId }: { meetingId: DBDocumentID }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return changeMeetingStatusRequest(meetingId, MeetingStatus.DELETED);
    },
    {
      onSuccess() {
        return queryClient.refetchQueries([StatePrimaryKeys.Meetings]);
      },
      onError: (error, { meetingId }) => {
        logger.error(`Error deleting meeting with id ${meetingId}`, error);
      },
    }
  );

export const useMarkSystemMessageAsSeen = (
  uid: UID,
  systemMessageType: SystemMessagesType,
  meetingId?: DBDocumentID,
  activityId?: DBDocumentID,
  options?: UseMutationOptions<{ success: boolean }, Error>
) =>
  useMutation<{ success: boolean }, Error>(
    [
      StatePrimaryKeys.Meetings,
      meetingId,
      StateSubKeys[StatePrimaryKeys.Meetings]?.[
        MeetingsStateKeys.MeetingsMetadata
      ],
    ],
    () => {
      if (!meetingId || !activityId)
        throw new Error("Missing meeting id or activity id");
      return markSystemMessageAsSeenRequest(
        uid,
        systemMessageType,
        meetingId,
        activityId
      );
    },
    {
      onSuccess() {
        return queryClient.invalidateQueries([
          StatePrimaryKeys.Meetings,
          meetingId,
          StateSubKeys[StatePrimaryKeys.Meetings]?.[
            MeetingsStateKeys.MeetingsMetadata
          ],
        ]);
      },
      onError: (error) => {
        logger.error(
          `Error marking greeting modal as seen in meeting with id ${meetingId}`,
          error
        );
      },
      ...options,
    }
  );

export const useMarkQuestionAsAnswered = (
  meetingId?: DBDocumentID,
  options?: UseMutationOptions<
    { success: boolean },
    Error,
    {
      activityId: DBDocumentID;
      questionIdx: number;
      uid: UID;
    }
  >
) =>
  useMutation<
    { success: boolean },
    Error,
    {
      activityId: DBDocumentID;
      questionIdx: number;
      uid: UID;
    }
  >(
    [
      StatePrimaryKeys.Meetings,
      meetingId,
      StateSubKeys[StatePrimaryKeys.Meetings]?.[
        MeetingsStateKeys.MeetingsMetadata
      ],
    ],
    ({
      activityId,
      questionIdx,
      uid,
    }: {
      activityId: DBDocumentID;
      questionIdx: number;
      uid: UID;
    }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return markQuestionAsAnsweredRequest(
        meetingId,
        activityId,
        questionIdx,
        uid
      );
    },
    {
      onSuccess() {
        return queryClient.refetchQueries([
          StatePrimaryKeys.Meetings,
          meetingId,
          StateSubKeys[StatePrimaryKeys.Meetings]?.[
            MeetingsStateKeys.MeetingsMetadata
          ],
        ]);
      },
      onError: (error) => {
        logger.error(
          `Error marking question as answered in meeting with id ${meetingId}`,
          error
        );
      },
      ...options,
    }
  );

export const useChangeMeeplePosition = (
  meetingId?: DBDocumentID,
  options?: UseMutationOptions<
    void,
    Error,
    {
      meepleIndex: number;
      position: { x: number; y: number };
    }
  >
) =>
  useMutation<
    void,
    Error,
    {
      meepleIndex: number;
      position: { x: number; y: number };
    }
  >(
    [
      StatePrimaryKeys.Meetings,
      meetingId,
      StateSubKeys[StatePrimaryKeys.Meetings]?.[
        MeetingsStateKeys.MeetingsMetadata
      ],
      StateSubKeys[StatePrimaryKeys.Meetings]?.[MeetingsStateKeys.Meeples],
    ],
    ({
      meepleIndex,
      position,
    }: {
      meepleIndex: number;
      position: { x: number; y: number };
    }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return changeMeeplePositionRequest(meetingId, meepleIndex, position);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([
          StatePrimaryKeys.Meetings,
          meetingId,
          StateSubKeys[StatePrimaryKeys.Meetings]?.[
            MeetingsStateKeys.MeetingsMetadata
          ],
          StateSubKeys[StatePrimaryKeys.Meetings]?.[MeetingsStateKeys.Meeples],
        ]);
      },
      onError: (error) => {
        logger.error(
          `Error changing meeple position in meeting with id ${meetingId}`,
          error
        );
      },
      ...options,
    }
  );

export const useAddUserMeeple = (
  meetingId?: DBDocumentID,
  options?: UseMutationOptions<
    void,
    Error,
    {
      uid: UID;
    }
  >
) =>
  useMutation<
    void,
    Error,
    {
      uid: UID;
    }
  >(
    [
      StatePrimaryKeys.Meetings,
      meetingId,
      StateSubKeys[StatePrimaryKeys.Meetings]?.[
        MeetingsStateKeys.MeetingsMetadata
      ],
      StateSubKeys[StatePrimaryKeys.Meetings]?.[MeetingsStateKeys.Meeples],
    ],
    ({ uid }: { uid: UID }) => {
      if (!meetingId) throw new Error("No meeting data was provided");
      return addUserMeepleRequest(meetingId, uid);
    },
    {
      onSuccess() {
        return queryClient.refetchQueries([
          StatePrimaryKeys.Meetings,
          meetingId,
          StateSubKeys[StatePrimaryKeys.Meetings]?.[
            MeetingsStateKeys.MeetingsMetadata
          ],
          StateSubKeys[StatePrimaryKeys.Meetings]?.[MeetingsStateKeys.Meeples],
        ]);
      },
      onError: (error) => {
        logger.error(`Error adding meeple in meeting ${meetingId}`, error);
      },
      ...options,
    }
  );
