import { SHA256 } from "crypto-js";
import { Activity, ActivityType } from "../types/activities/activity";
import { ActivityMetadata } from "../types/meeting";
import { HOUR_DIVIDER } from "./consts";

export const baseUrl = process.env.REACT_APP_CADU_BASE_URL;
export const dateLocaleFormat = "en-Uk";

export function deepCopy<T>(obj: T): T {
  const copy: T = JSON.parse(JSON.stringify(obj));
  return copy;
}

/** ************Date handle functions**************/

export function makeTimestamp({
  year,
  month,
  day,
  time,
}: {
  year: number;
  month: number;
  day: number;
  time: string;
}) {
  return new Date(
    year,
    month,
    day,
    +time.split(":")[0],
    +time.split(":")[1]
  ).getTime();
}

export function getCurrentFullTime(startTimestamp: number) {
  const currentYear = new Date(startTimestamp).getFullYear();
  const currentMinutes = new Date(startTimestamp).getMinutes();
  const currentHour = new Date(startTimestamp).getHours();
  const roundedMinutes = Math.floor(currentMinutes / 15) * 15;
  const currentTimeIndex =
    currentHour * HOUR_DIVIDER + Math.floor(roundedMinutes / 15);
  return {
    currentYear,
    currentTimeIndex,
  };
}

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}${month}${day}T${hours}${minutes}${seconds}`;
}

export function generateGoogleCalendarMeetingUrl({
  title,
  plannedStartTimestamp,
  plannedEndTimestamp,
  id,
}: {
  title?: string;
  plannedStartTimestamp: number;
  plannedEndTimestamp: number;
  id: string;
}) {
  return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(
    (title || "Cadu meeting") + "&nbsp;[Cadu]"
  )}&dates=${formatTimestamp(plannedStartTimestamp)}/${formatTimestamp(
    plannedEndTimestamp
  )}&location=${baseUrl}/meet/${id}&details=You have been invited to Cadu meeting%0A${baseUrl}/meet/${id}&sprop=website:${baseUrl}&sprop=name:Cadu&details=EventDetail&sf=true&output=xml`;
}

export function getRandomIntInclusive(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function createActivityMetadata(activity: Activity): ActivityMetadata {
  const { type: activityType, puzzles } = activity;
  const puzzlesState = puzzles.map(() => ({
    isSolved: false,
  }));
  switch (activityType) {
    case ActivityType.FREE_MAP:
      return {
        meeples: [],
        puzzlesState,
        systemMessages: [],
      };
    case ActivityType.QUEST_ROOM:
    default:
      return {
        puzzlesState,
        systemMessages: [],
      };
  }
}

export function getRandomFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function hashString(string: string): number {
  const hashValue = parseInt(SHA256(string).toString(), 16);
  return hashValue;
}
