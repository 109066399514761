export const getRandomColor = ({
  luminosity,
}: {
  luminosity: "light" | "dark";
}): string => {
  if (luminosity === "light") {
    return `hsl(${Math.random() * 360}, 100%, 75%)`;
  } else {
    return `hsl(${Math.random() * 360}, 100%, 25%)`;
  }
};
