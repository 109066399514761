import React, { FunctionComponent } from "react";
import "./SignIn.css";

import { signUserInWithGoogle } from "../../services/firebase";

import { Image } from "../Image/Image";
import logo from "../../assets/images/logo.png";
import hero1 from "../../assets/images/hero/hero-1.png";
import hero2 from "../../assets/images/hero/hero-2.png";
import hero3 from "../../assets/images/hero/hero-3.png";

type SignInProps = React.HTMLAttributes<HTMLDivElement>;

export const SignIn: FunctionComponent<SignInProps> = () => {
  return (
    <div className="d-flex flex-column min-vh-100 sign-in-font">
      <div className="container">
        <header>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div className="col-lg-1 d-none d-lg-block">
              <Image src={logo} className="img-fluid ar-1-1" />
            </div>
            <div className="w-25 d-block d-lg-none">
              <Image src={logo} className="img-fluid ar-1-1" />
            </div>
            <div>
              <button
                className="sign-in-button text-light d-block d-lg-none"
                onClick={signUserInWithGoogle}
              >
                Sign In
              </button>
            </div>
          </div>
        </header>
        <div className="d-flex pt-4 pb-5 justify-content-between gap-4">
          <h1 className="w-bold w-75 welcome-text">
            Welcome to Cadu — Your Ultimate Online Learning Platform!
          </h1>
          <div>
            <button
              className="sign-in-button text-light d-none d-lg-block"
              onClick={signUserInWithGoogle}
            >
              Sign In
            </button>
          </div>
        </div>
        <div className="row gap-5 gap-lg-0">
          <div className="col-lg-4">
            <div>
              <h5 className="fw-bold">Online Collaboration & Activities</h5>
              <h6 className="fw-medium mb-3 mb-lg-5">
                Cadu provides a seamless online collaboration environment that
                allows you to conduct group activities and enhance team spirit
              </h6>
              <Image
                src={hero1}
                className="d-block d-lg-none img-fluid rounded-4 w-100"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h5 className="fw-bold">Seamless Teaching Experience</h5>
              <h6 className="fw-medium mb-3 mb-lg-5">
                Utilize the interactive whiteboard, screen sharing capabilities,
                and integrated chat features to create an inclusive and dynamic
                virtual classroom
              </h6>
              <Image
                src={hero3}
                className="d-block d-lg-none img-fluid rounded-4 w-100"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h5 className="fw-bold">Personalized Learning Paths</h5>
              <h6 className="fw-medium mb-3 mb-lg-5">
                Personalized learning paths tailored to each student's needs and
                interests no matter what subject it is, Cadu offers a vast
                library of educational resources, activities, and assessments
              </h6>
              <Image
                src={hero2}
                className="d-block d-lg-none img-fluid rounded-4 w-100"
              />
            </div>
          </div>
        </div>
        <div className="row d-none d-lg-flex">
          <div className="col-lg-4">
            <div>
              <Image src={hero1} className="img-fluid rounded-4 w-100" />
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <Image src={hero3} className="img-fluid rounded-4 w-100" />
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <Image src={hero2} className="img-fluid rounded-4 w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow-1" />
      <footer className="mt-5 socials-container d-flex justify-content-center p-5 text-light gap-3">
        <div className="d-flex flex-column flex-sm-row gap-5">
          <div>
            <h6 className="sign-in-text fw-bold fs-5 text-uppercase">
              Resourses
            </h6>
            <div className="sign-in-text fs-6">
              <div>Articles </div>
              <div>Our blog</div>
            </div>
          </div>
          <div>
            <h6 className="sign-in-text fw-bold fs-5 text-uppercase">
              Socials
            </h6>
            <div className="sign-in-text d-flex gap-2 fs-6">
              <div>YouTube</div>
              <div>Twitter</div>
              <div>TikTok</div>
            </div>
            <div className="sign-in-text d-flex gap-2 fs-6">
              <div>Instagram</div>
              <div>Facebook</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
