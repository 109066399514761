import { FunctionComponent } from "react";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { RecoilState, useRecoilState } from "recoil";
import {
  isCongratulationsModalOpenAtom,
  isGreetingModalOpenAtom,
} from "../../state/atoms/ui";
import {
  ActivityMessage,
  ActivityMessageModalTypes,
} from "../../types/activities/activity";
import { Image } from "../Image/Image";

const getModalOpenAtom = (
  type: ActivityMessageModalTypes
): RecoilState<boolean> => {
  switch (type) {
    case ActivityMessageModalTypes.CONGRATULATIONS_MESSAGE:
      return isCongratulationsModalOpenAtom;
    case ActivityMessageModalTypes.GREETING_MESSAGE:
    default:
      return isGreetingModalOpenAtom;
  }
};

export const ActivityMessageModal: FunctionComponent<{
  messageData?: ActivityMessage;
  type: ActivityMessageModalTypes;
}> = ({ messageData, type }) => {
  const isModalOpenAtom = getModalOpenAtom(type);
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);

  return (
    <Modal
      body={
        <ModalBody
          messageData={messageData}
          onClose={() => setIsModalOpen(false)}
        />
      }
      header=""
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    />
  );
};

const ModalBody: FunctionComponent<{
  messageData?: ActivityMessage;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ messageData, onClose }) => {
  const { imgSrc, text } = messageData || {};

  return (
    <div>
      {(text || imgSrc) && (
        <>
          <h3>{text}</h3>
          {imgSrc && <Image className="w-100 mb-3" src={imgSrc} alt="" />}
          <div>
            <Button className="float-end" type="button" onClick={onClose}>
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
