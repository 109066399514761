import { FunctionComponent, useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsAtom } from "../../state/atoms/notifications";
import { NotificationType } from "../../types/notification";
import { ERROR_TOAST_SHOW_TIME, INFO_TOAST_SHOW_TIME } from "../../types/ui";
import { Toast } from "../Toast/Toast";

export const NotificationHandler: FunctionComponent = () => {
  const [notificationDetails, setNotificationDetails] =
    useRecoilState(notificationsAtom);

  useEffect(() => {
    const removeNotification = () => {
      const notificationListToUpdate = [...notificationDetails];
      notificationListToUpdate.pop();
      setNotificationDetails(notificationListToUpdate);
    };

    let intervalTime;
    if (notificationDetails.length) {
      intervalTime =
        notificationDetails[notificationDetails.length - 1].type ===
        NotificationType.Danger
          ? ERROR_TOAST_SHOW_TIME
          : INFO_TOAST_SHOW_TIME;
    }
    const interval = setInterval(() => {
      if (notificationDetails.length) {
        removeNotification();
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [notificationDetails, setNotificationDetails]);

  return (
    <div
      className="d-flex flex-column-reverse position-fixed w-auto"
      style={{ bottom: 0, right: 0, zIndex: 1060 }}
    >
      {notificationDetails.map(({ header, body, type }, index) => (
        <Toast
          key={index}
          type={type}
          header={header || "Oops, there seems to be a problem"}
          body={body}
        />
      ))}
    </div>
  );
};
