import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import {
  getActivityTemplateByIdRequest,
  getTemplatesRequest,
  saveActivityTemplateRequest,
  deleteActivityTemplateRequest,
  SaveActivityTemplateParams,
} from "./firebase";
import { logger } from "./logger";
import { queryClient } from "..";
import { StatePrimaryKeys } from "./consts";
import { DBDocument } from "../types/document";
import { ActivityTemplate } from "../types/activities/common";

export const useGetActivityTemplates = (
  options?: UseQueryOptions<DBDocument<ActivityTemplate>[], Error>
) =>
  useQuery<DBDocument<ActivityTemplate>[], Error>(
    [StatePrimaryKeys.ActivityTemplates],
    () => getTemplatesRequest(),
    {
      onError: (error) => {
        logger.error("Error in fetching templates", error);
      },
      ...options,
    }
  );

export const useGetActivityTemplateById = (
  templateId: string,
  options?: UseQueryOptions<DBDocument<ActivityTemplate>, Error>
) =>
  useQuery<DBDocument<ActivityTemplate>, Error>(
    [StatePrimaryKeys.ActivityTemplates, templateId],
    () => getActivityTemplateByIdRequest(templateId),
    {
      onError: (error) => {
        logger.error("Error in fetching template", error);
      },
      enabled: false,
      ...options,
    }
  );

export const useSaveActivityTemplate = (
  options?: UseMutationOptions<
    { success: boolean },
    Error,
    SaveActivityTemplateParams
  >
) => {
  return useMutation<{ success: boolean }, Error, SaveActivityTemplateParams>(
    [StatePrimaryKeys.ActivityTemplates],
    (activityTemplateData) => {
      return saveActivityTemplateRequest(activityTemplateData);
    },
    {
      onSuccess: () => {
        return queryClient.refetchQueries([StatePrimaryKeys.ActivityTemplates]);
      },
      onError: (error) => {
        logger.error("Error in creating new activity template", error);
      },
      ...options,
    }
  );
};

export const useDeleteActivityTemplate = () => {
  return useMutation<
    { success: boolean },
    Error,
    { activityTemplateId: string }
  >(
    [StatePrimaryKeys.ActivityTemplates],
    (mutationData: { activityTemplateId: string }) => {
      const { activityTemplateId } = mutationData;
      if (!activityTemplateId)
        throw new Error("No activity template id was provided");
      return deleteActivityTemplateRequest(activityTemplateId);
    },
    {
      onSuccess() {
        return queryClient.refetchQueries([StatePrimaryKeys.ActivityTemplates]);
      },
      onError: (error, mutationData) => {
        const { activityTemplateId } = mutationData;
        logger.error(
          `Error deleting activity template with id ${activityTemplateId}`,
          error
        );
      },
    }
  );
};
