import React, { FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { RecoilRoot, useRecoilState, useSetRecoilState } from "recoil";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "tippy.js/dist/tippy.css";
import "./style/index.css";
import reportWebVitals from "./reportWebVitals";

import { logger } from "./services/logger";
import {
  onAuthStateChangedHook,
  createUser,
  getUser,
  getTeamsByUid,
} from "./services/firebase";
import {
  waitingForAuthAtom,
  signedInUserIdAtom,
  contextTeamAtom,
} from "./state/atoms/auth";
import { Landing } from "./apps/Landing/Landing";
import { Management } from "./apps/Management/Management";
import { Loading } from "./components/Loading/Loading";
import { PageNotFound } from "./components/PageNotFound/PageNotFound";
import { Meeting } from "./apps/Meeting/Meeting";
import { notificationsAtom } from "./state/atoms/notifications";
import { JoinMeeting } from "./apps/Meeting/JoinMeeting";
import { NotificationHandler } from "./components/NotificationHandling/NotificationHandling";
import { ERROR_TOAST_DEFAULT_BODY } from "./types/ui";
import { NotificationType } from "./types/notification";
import { JoinCourse } from "./apps/Course/JoinCourse";
import { Course } from "./apps/Course/Course";
import { AppRoutes, RouteParams, SubRoutes } from "./services/consts";

export enum SystemErrors {
  CourseNotFound = "Course not found",
  MeetingNotFound = "Meeting not found",
}

export const queryClient = new QueryClient();

const Index: FunctionComponent = () => {
  const [waitingForAuth, setWaitingForAuth] =
    useRecoilState(waitingForAuthAtom);
  const [signedInUserId, setSignedInUserId] =
    useRecoilState(signedInUserIdAtom);
  const [contextTeam, setContextTeam] = useRecoilState(contextTeamAtom);
  const setNotificationDetails = useSetRecoilState(notificationsAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const handleError = (error: unknown) => {
      let message = "An error occurred";
      let body = ERROR_TOAST_DEFAULT_BODY;

      if (error instanceof Error) {
        message = error.message;
      }

      switch (message) {
        case SystemErrors.CourseNotFound:
          body = "Please enter a valid course ID";
          navigate(AppRoutes.COURSE);
          break;
        case SystemErrors.MeetingNotFound:
          body = "Please enter a valid meeting ID";
          break;
        default:
          break;
      }
      setNotificationDetails((prevState) => [
        ...prevState,
        {
          header: message,
          body,
          type: NotificationType.Danger,
        },
      ]);
    };

    const queryCache = queryClient.getQueryCache();
    const mutationCache = queryClient.getMutationCache();

    queryCache.config.onError = (error) => handleError(error);
    mutationCache.config.onError = (error) => handleError(error);
  }, [navigate, setNotificationDetails]);

  useEffect(() => {
    logger.debug("index.tsx, useEffect#1 fired");
    onAuthStateChangedHook(async (authUser) => {
      try {
        setWaitingForAuth(true);
        if (!authUser) {
          setSignedInUserId("");
          setContextTeam(null);
          return;
        }

        const { uid, displayName, email, phoneNumber, isAnonymous } = authUser;

        const dbUser = await getUser(uid);

        // Create user if does not exist
        if (!dbUser) {
          await createUser({
            uid,
            displayName,
            email,
            phoneNumber,
            isAnonymous,
            isCreator: false,
            isPublisher: false,
          });
        }

        // Get user's teams
        const { teams } = await getTeamsByUid(uid);
        if (!teams || teams.length === 0) {
          throw new Error("No team found");
        }

        setSignedInUserId(uid);
        setContextTeam(teams[0]);
      } catch (err) {
        logger.error("Error in onAuthStateChangedHook", err);
        setSignedInUserId("");
        setContextTeam(null);
        setNotificationDetails((prevState) => [
          ...prevState,
          {
            header: "Error logging in",
            body: ERROR_TOAST_DEFAULT_BODY,
            type: NotificationType.Danger,
          },
        ]);
      } finally {
        setWaitingForAuth(false);
      }
    });
  }, [
    setSignedInUserId,
    setContextTeam,
    setNotificationDetails,
    setWaitingForAuth,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      {waitingForAuth && <Loading fullscreen />}
      <Routes>
        {signedInUserId ? (
          <>
            <Route path="/" element={<Landing />} />
            <Route
              path={`${AppRoutes.MGMT_APP}/*`}
              element={contextTeam ? <Management /> : <Loading fullscreen />}
            />
            <Route
              path={`${AppRoutes.MEET}${RouteParams.MEETING_ID}`}
              element={<Meeting />}
            />
            <Route path={AppRoutes.MEET} element={<JoinMeeting />} />
            <Route path={AppRoutes.COURSE} element={<JoinCourse />} />
            <Route
              path={`${AppRoutes.COURSE}${RouteParams.COURSE_ID}`}
              element={<Course />}
            />
            <Route
              path={`${AppRoutes.COURSE}${RouteParams.COURSE_ID}${SubRoutes.ENV}${RouteParams.MEETING_ID}`}
              element={<Course />}
            />
            <Route path="*" element={<PageNotFound />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Landing />} />
          </>
        )}
      </Routes>
      <NotificationHandler />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// TODO: Send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
