import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { InputText } from "../../components/Input/InputText";
import { signUserOut } from "../../services/firebase";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { AppRoutes } from "../../services/consts";

export const JoinCourse: FunctionComponent = () => {
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const signedInUserId = useRecoilValue(signedInUserIdAtom);

  useEffect(() => {
    if (isSubmitted && signedInUserId) {
      navigate(`${AppRoutes.COURSE}/${courseId}`);
    }
  }, [courseId, isSubmitted, signedInUserId, navigate]);

  return (
    <div className="container vh-100 d-flex">
      <div className="row w-100 vh-25 m-0 align-self-center justify-content-center">
        <form
          className="form d-flex flex-column w-50"
          onSubmit={async (e) => {
            e.preventDefault();
            if (!courseId) return;
            setIsSubmitted(true);
          }}
        >
          <div>
            <span>Enter course ID</span>
            <InputText
              placeholder="Course ID"
              onChange={(e) => setCourseId(e.target.value)}
              className="form-control text-truncate mt-1 mb-3"
            />
          </div>
          <button className="btn btn-primary">Join</button>
          <div
            className="mt-4 btn btn-link align-self-center"
            onClick={signUserOut}
          >
            Logout
          </div>
        </form>
      </div>
    </div>
  );
};
