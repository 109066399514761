import { atom } from "recoil";
import { Activity, PreuploadImageFile } from "../../types/activities/activity";

export const upsertActivityFormValuesAtom = atom<Activity | null>({
  key: "upsertActivityFormValuesAtom",
  default: null,
});

export const activityQuestionsImagesAtom = atom<PreuploadImageFile[]>({
  key: "activityQuestionsImagesAtom",
  default: [],
});

export const activityCongratulationsImageAtom = atom<PreuploadImageFile | null>(
  {
    key: "activityCongratulationsImageAtom",
    default: null,
  }
);

export const activityGreetingImageAtom = atom<PreuploadImageFile | null>({
  key: "activityGreetingImageAtom",
  default: null,
});

export const chosenElementIdxAtom = atom<number | null>({
  key: "chosenElementIdxAtom",
  default: null,
});

export const selectedActivityIdAtom = atom<string | null>({
  key: "selectedActivityIdAtom",
  default: null,
});
