import { FunctionComponent } from "react";
import {
  InteractiveElement,
  InteractiveElementProps,
} from "./InteractiveElement";
import { FreeMapElementImage } from "../../types/activities/free-map";
import { InteractiveElementType } from "../../types/activities/common";

interface FreeMapINteractiveElementProps
  extends Omit<InteractiveElementProps, "visibility" | "image"> {
  type: InteractiveElementType;
  currDiceNumber?: number;
  images?: FreeMapElementImage[];
}

const getElementVisibility = (
  type: InteractiveElementType,
  index: number,
  currDiceNumber?: number
) => {
  switch (type) {
    case InteractiveElementType.Dice:
      if (index === currDiceNumber) return "visible";
      return "hidden";
    case InteractiveElementType.Deck:
      if (index === 0) return "visible";
      return "hidden";
    default:
      return "visible";
  }
};

export const FreeMapInteractiveElement: FunctionComponent<
  FreeMapINteractiveElementProps
> = ({ type, index, currDiceNumber, children, images, ...otherProps }) => {
  return (
    <>
      {images?.map(({ image }, imageIndex) => (
        <InteractiveElement
          key={`interactive-element-${imageIndex}`}
          visibility={getElementVisibility(type, imageIndex, currDiceNumber)}
          image={image}
          index={index}
          {...otherProps}
        >
          {children}
        </InteractiveElement>
      ))}
    </>
  );
};
