import { useState, FunctionComponent } from "react";
import { isQuestModalOpenAtom } from "../../state/atoms/ui";
import { Modal } from "../Modal/Modal";
import { Puzzle } from "../../types/activities/common";
import { InputText } from "../Input/InputText";
import { Button } from "../Button/Button";
import { useSetRecoilState, useRecoilState } from "recoil";
import { Image } from "../Image/Image";

const QusetionModalBody: FunctionComponent<{
  puzzle: Puzzle;
  onSolveQuestion: () => void;
}> = ({ puzzle, onSolveQuestion }) => {
  const setIsQuestModalOpen = useSetRecoilState(isQuestModalOpenAtom);

  const [userAnswer, setUserAnswer] = useState("");
  const { question, answers } = puzzle;
  const correctAnswer = answers.find((answer) => answer.isCorrect);

  const [questionStateMsg, setQuestionStateMsg] = useState<{
    type: "success" | "danger";
    msg: string | null;
  }>({
    type: "danger",
    msg: null,
  });

  const onSubmitAnswer = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (
      userAnswer.trim().toLowerCase() ===
      correctAnswer?.text.trim().toLowerCase()
    ) {
      onSolveQuestion();
      setQuestionStateMsg({
        type: "success",
        msg: puzzle.successMessage
          ? puzzle.successMessage
          : "This is correct! Good job!",
      });
    } else {
      setQuestionStateMsg({
        type: "danger",
        msg: puzzle.failureMessage
          ? puzzle.failureMessage
          : "Nope. Better luck next time.",
      });
    }
  };
  const { msg, type } = questionStateMsg;
  return (
    <div className="text-start">
      <pre className="h4 pre-wrap">{question.text}</pre>
      {question.imgSrc && (
        <Image
          className="max-height-50vh d-block mx-auto"
          src={question.imgSrc}
          alt=""
        />
      )}
      <form className="form mt-4" onSubmit={onSubmitAnswer}>
        <div>
          Your answer
          <InputText
            className="mt-1 mb-3"
            value={userAnswer}
            onChange={(ev) => setUserAnswer(ev.target.value)}
          />
          <div
            className={`text-small text-${type} opacity-${msg ? "1" : "0"}`}
            style={{ height: "20px" }}
          >
            {msg}
          </div>
        </div>
        <div>
          {type === "success" ? (
            <Button
              className="float-end"
              type="button"
              onClick={() => setIsQuestModalOpen(false)}
            >
              Close
            </Button>
          ) : (
            <Button className="float-end" type="submit">
              Answer
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export const QuestionModal: FunctionComponent<{
  puzzle: Puzzle;
  onSolveQuestion: () => void;
}> = ({ puzzle, onSolveQuestion }) => {
  const [isQuestModalOpen, setIsQuestModalOpen] =
    useRecoilState(isQuestModalOpenAtom);

  return (
    <Modal
      body={
        <QusetionModalBody puzzle={puzzle} onSolveQuestion={onSolveQuestion} />
      }
      header=""
      isOpen={isQuestModalOpen}
      onClose={() => setIsQuestModalOpen(false)}
    />
  );
};
