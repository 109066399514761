import React, { useState, ReactNode } from "react";

type AccordionProps = {
  items: { header: string; body: ReactNode }[];
};

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion mt-3">
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`heading-${index}`}>
            <button
              className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
              type="button"
              onClick={() => toggleItem(index)}
              aria-expanded={openIndex === index ? "true" : "false"}
              aria-controls={`collapse-${index}`}
            >
              {item.header}
            </button>
          </h2>
          <div
            id={`collapse-${index}`}
            className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
            aria-labelledby={`heading-${index}`}
          >
            <div className="accordion-body">{item.body}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
