import { FC, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  contextMeetingAtom,
  movingMeepleIndexAtom,
} from "../../state/atoms/meetings";
import { useChangeMeeplePosition } from "../../services/meetings";
import { UID } from "../../types/user";
import { meeples } from "../../services/consts";
import { hashString } from "../../services/utils";

interface MeepleProps {
  imageSrc?: string;
  meepleIndex: number;
  x: number;
  y: number;
  meepleSize: number;
  uid: UID;
  isLastUpdated: boolean;
}

export const Meeple: FC<MeepleProps> = ({
  imageSrc,
  x,
  y,
  meepleIndex,
  uid,
  meepleSize,
  isLastUpdated,
}) => {
  const [isMoving, setIsMoving] = useState(false);
  const meepleImage = imageSrc ?? meeples[hashString(uid) % meeples.length];

  const contextMeeting = useRecoilValue(contextMeetingAtom);
  const setMovingMeepleIndex = useSetRecoilState(movingMeepleIndexAtom);
  const { mutateAsync: handleChangeMeeplePosition } = useChangeMeeplePosition(
    contextMeeting?.id
  );

  const onUpdateMeeplePosition = async () => {
    setMovingMeepleIndex(null);
    if (!contextMeeting?.id) return;
    await handleChangeMeeplePosition({
      meepleIndex,
      position: { x, y },
    });
  };

  const startDragging = () => {
    setMovingMeepleIndex(meepleIndex);
    setIsMoving(true);
  };

  const stopDragging = () => {
    onUpdateMeeplePosition();
    setIsMoving(false);
  };

  return (
    <div
      onMouseDown={startDragging}
      onMouseUp={stopDragging}
      className="pointer position-absolute"
      style={{
        backgroundImage: `url(${meepleImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        left: `${x}%`,
        top: `${y}%`,
        width: `${meepleSize}px`,
        height: `${meepleSize}px`,
        zIndex: isLastUpdated ? 2 : 1,
        transition: `${isMoving ? "none" : "all 500ms"}`,
      }}
    />
  );
};
