import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
} from "react";

import { logger } from "../../services/logger";
import { Button } from "../Button/Button";
import { Animation } from "../Animation/Animation";
import { Icon, Icons } from "../Icons/Icon";
import "./Modal.css";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  header: ReactNode;
  body: ReactNode;
  preventCloseOnBackdropClick?: boolean;
  onClose: () => void;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  header,
  body,
  onClose,
  preventCloseOnBackdropClick,
}) => {
  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onCloseModal();
      }
    },
    [onCloseModal]
  );

  useEffect(() => {
    logger.debug("Modal.tsx, useEffect#1 fired");
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return isOpen ? (
    <Animation animateOn={`modal-${isOpen ? "show" : "hide"}`}>
      <div
        className={`modal-backdrop show ${
          preventCloseOnBackdropClick ? "modal-backdrop-disabled" : ""
        }`}
      />
      <div
        className="modal d-block show"
        onClick={() => {
          if (preventCloseOnBackdropClick) return;
          onCloseModal();
        }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered h-auto"
          onClick={(evt) => evt.stopPropagation()}
        >
          <div
            className={`modal-content ${
              preventCloseOnBackdropClick ? "shadow" : ""
            }`}
          >
            <div className="modal-header border-0 d-block">
              <div className="float-start">{header || <></>}</div>
              <Button
                btnType="secondary"
                className="float-end p-1 px-2"
                onClick={onCloseModal}
              >
                <Icon icon={Icons.CLOSE} />
              </Button>
            </div>
            {body && <div className="modal-body">{body}</div>}
          </div>
        </div>
      </div>
    </Animation>
  ) : (
    <></>
  );
};
