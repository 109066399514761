import { FunctionComponent, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { signedInUserIdAtom } from "../../../../state/atoms/auth";
import { InputText } from "../../../../components/Input/InputText";
import { InputFile } from "../../../../components/Input/InputFile";
import { Button } from "../../../../components/Button/Button";
import { Loading } from "../../../../components/Loading/Loading";
import { FileTypes } from "../../../../types/input";
import { ProfileSettings } from "../../../../types/user";
import { Card } from "../../../../components/Card/Card";
import { ImagePreview } from "../../../../components/ImagePreview/ImagePreview";
import { useGetUser, useSaveProfileSettings } from "../../../../services/users";

type SettingsProps = React.HTMLAttributes<HTMLDivElement>;

export const Settings: FunctionComponent<SettingsProps> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);

  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);
  const [profileSettings, setProfileSettings] = useState<ProfileSettings>({
    uid: "",
    displayName: "",
    avatarUrl: null,
    shouldDeleteAvatar: false,
  });

  const [avatarUrlState, setAvatarUrlState] = useState("");

  const {
    mutateAsync: handleSaveProfileSettings,
    isLoading: isSavingProfileSettings,
  } = useSaveProfileSettings(signedInUserId || "");

  const onSubmit = async () => {
    await handleSaveProfileSettings(profileSettings);
    setProfileSettings((prevState) => ({
      ...prevState,
      avatarUrl: null,
      shouldDeleteAvatar: false,
    }));
  };

  useEffect(() => {
    if (signedInUser) {
      setAvatarUrlState(signedInUser.avatarUrl || "");
      setProfileSettings({
        uid: signedInUser.uid,
        displayName: signedInUser.displayName || "",
        shouldDeleteAvatar: false,
      });
    }
  }, [signedInUser]);

  if (!signedInUserId) return <></>;

  const { displayName, shouldDeleteAvatar } = profileSettings;
  const isFormDirty =
    displayName === signedInUser?.displayName &&
    !avatarUrlState &&
    !shouldDeleteAvatar;

  const isLoading = isLoadingUser || isSavingProfileSettings;

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Settings</h4>
        </div>
        <div className="col py-0">
          <Button
            className="float-end"
            disabled={isFormDirty || isLoading}
            onClick={onSubmit}
          >
            {isLoading ? <Loading /> : <span>Save</span>}
          </Button>
        </div>
      </div>
      <Card className="mt-4 mt-md-2">
        <div className="col-12 col-md-4">
          <div>
            Full name
            <InputText
              value={displayName}
              className="mb-3"
              onChange={(ev) =>
                setProfileSettings((prevState) => ({
                  ...prevState,
                  displayName: ev.target.value,
                }))
              }
            />
          </div>
          <div>
            Email
            <InputText
              className="mb-3"
              disabled
              value={signedInUser?.email || ""}
            />
          </div>
          <div>
            Avatar
            {avatarUrlState ? (
              <ImagePreview
                imgSrc={avatarUrlState}
                onRemove={() => {
                  setProfileSettings((prevState) => ({
                    ...prevState,
                    avatarUrl: null,
                    shouldDeleteAvatar: !!signedInUser?.avatarUrl,
                  }));
                  setAvatarUrlState("");
                }}
                disableRemove={isLoading}
              />
            ) : (
              <InputFile
                className="mb-3"
                src=""
                acceptFile={[FileTypes.IMAGE]}
                disabled={isLoading}
                onChange={async (ev) => {
                  if (ev.target.files) {
                    const files = Array.from(ev.target.files);
                    if (files.length === 0) return;
                    setAvatarUrlState(URL.createObjectURL(files[0]));
                    setProfileSettings((prevState) => ({
                      ...prevState,
                      avatarUrl: files[0],
                      shouldDeleteAvatar: false,
                    }));
                  }
                }}
              >
                <div className="btn btn-outline-secondary w-100 mt-2">
                  Upload image
                </div>
              </InputFile>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
