import React, { FunctionComponent, useState } from "react";
import fallbackImage from "../../assets/images/card-image.svg";

type ImageProps = React.InputHTMLAttributes<HTMLImageElement>;

export const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  className,
  ...props
}) => {
  const [isFallbackImage, setIsFallbackImage] = useState<boolean>(false);

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    target.src = fallbackImage;
    setIsFallbackImage(true);
  };

  return (
    <img
      src={src}
      alt={alt}
      className={`object-fit-contain p-0 max-w-100 ${isFallbackImage ? "h-300px w-300px" : ""} ${className}`}
      {...props}
      onError={onError}
    />
  );
};
