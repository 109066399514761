import { forwardRef } from "react";
import "./Map.css";
import { MAP_NUM_COLS, MAP_NUM_ROWS } from "../../services/consts";

interface MapProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
}

export const Map = forwardRef<HTMLDivElement, MapProps>(
  ({ children, backgroundImage, className, ...otherProps }, ref) => {
    return (
      <div
        {...otherProps}
        ref={ref}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          aspectRatio: `${MAP_NUM_COLS / MAP_NUM_ROWS}`,
        }}
        className={`map w-100 ${className}`}
      >
        {children}
      </div>
    );
  }
);
