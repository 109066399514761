import React, { FunctionComponent, ReactNode } from "react";
import { NotificationType } from "../../types/notification";
import { Icon, Icons } from "../Icons/Icon";
import "./Toast.css";

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  body?: ReactNode;
  type: NotificationType;
}

export const Toast: FunctionComponent<ToastProps> = ({
  header,
  body,
  type,
}) => {
  const getIcon = () =>
    type === "info" ? (
      <Icon icon={Icons.INFO} />
    ) : type === "warning" ? (
      <Icon icon={Icons.WARNING} />
    ) : (
      <Icon icon={Icons.WARNING} />
    );

  return header || body ? (
    <div className="toast show mb-1">
      <div className={`toast-header border-0 text-${type} pb-0`}>
        <h5 className="m-0 align-middle d-inline">{getIcon()}</h5>
        <h6 className="m-0 mx-1 align-baseline d-inline">{header}</h6>
      </div>
      {body && (
        <div className="toast-body p-1 bg-white">
          <small>{body}</small>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
