import React, { FunctionComponent } from "react";
import { motion } from "framer-motion";

interface AnimationProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: "default";
  animateOn: string;
}

export const Animation: FunctionComponent<AnimationProps> = ({
  type = "default",
  animateOn,
  children,
}) => {
  return type === "default" ? (
    <motion.div
      initial="hidden"
      animate="visible"
      key={animateOn}
      variants={{
        hidden: {
          scale: 1,
          opacity: 0,
        },
        visible: {
          scale: 1,
          opacity: 1,
          transition: {
            delay: 0,
          },
        },
      }}
    >
      {children}
    </motion.div>
  ) : (
    <>{children}</>
  );
};
