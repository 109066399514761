import React, { FunctionComponent } from "react";
import "./NavigationPane.css";

type NavigationPaneProps = React.HTMLAttributes<HTMLDivElement>;

export const NavigationPane: FunctionComponent<NavigationPaneProps> = ({
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className="navigation-pane sticky-top row m-0 p-0 justify-content-center"
    >
      {children}
    </div>
  );
};
