import { atom } from "recoil";
import { Meeting, MeetingMetadata } from "../../types/meeting";

export const upsertMeetingFormValuesAtom = atom<Meeting | null>({
  key: "upsertMeetingFormValuesAtom",
  default: null,
});

export const contextMeetingAtom = atom<Meeting | null>({
  key: "contextMeetingAtom",
  default: null,
});

export const contextMeetingMetadataAtom = atom<MeetingMetadata | null>({
  key: "contextMeetinMetaDatagAtom",
  default: null,
});

export const movingMeepleIndexAtom = atom<number | null>({
  key: "movingMeepleIndexAtom",
  default: null,
});
