import { ActivityType } from "./activity";

export interface ActivityTemplate {
  id: string;
  name: string;
  description: string;
  type: ActivityType;
  complexity: number;
  views: View[];
  isDeleted?: boolean;
  isPublished: boolean;
}

export interface View {
  backgroundImage: string;
  interactiveElements: InteractiveElement[];
}

export interface InteractiveElement extends Lockable {
  type: InteractiveElementType;
  rectangle: Rectangle;
}

export interface Lockable {
  unlocksBy?: Reward;
  messageUntilUnlocked?: string;
}

export enum InteractiveElementType {
  Puzzle = "puzzle",
  Item = "item",
  Dice = "dice",
  Deck = "deck",
}

export interface Rectangle {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

export interface Reward {
  image?: string;
  title?: string;
  description?: string;
}

export interface Room extends Lockable {
  views: View[];
}

export interface InteractiveItem {
  title: string;
  image: string;
  description: string;
  reward?: Reward;
}

export interface PuzzleTemplate {
  reward?: InteractiveItem;
}

export interface Puzzle {
  type: PuzzleType;
  title: string;
  image?: string;
  question: Question;
  answers: Answer[];
  successMessage?: string;
  failureMessage?: string;
}

export enum PuzzleType {
  MultipleChoice = "multiple-choice",
  SingleChoice = "single-choice",
  OpenQuestion = "open-question",
}

export interface Answer {
  text: string;
  isCorrect: boolean;
}

export interface Question {
  text: string;
  imgSrc: string;
}
