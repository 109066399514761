import { useState, useEffect, useCallback, useLayoutEffect } from "react";

import { logger } from "../services/logger";

function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  return {
    windowWidth,
    windowHeight,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    logger.debug("hooks/index.ts, useEffect#1 fired");
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const useRectMeasure = <T extends HTMLElement | null>() => {
  const [rect, setRect] = useState<DOMRect>();

  const [node, setNode] = useState<T>();

  const callbackRef = useCallback((node: T) => {
    if (node !== null) {
      setNode(node);
      setRect(node.getBoundingClientRect());
    }
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        setRect(node.getBoundingClientRect());
      };

      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node]);

  return { rect, callbackRef };
};
