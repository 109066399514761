import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  NextOrObserver,
  User,
  UserCredential,
  Unsubscribe as AuthUnsubscribe,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Unsubscribe,
  setDoc,
  updateDoc,
  orderBy,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  connectStorageEmulator,
} from "firebase/storage";

import { DBDocument, DBDocumentID } from "../types/document";
import { AppUser, ProfileSettings, UID } from "../types/user";
import {
  FreeMapActivityMetadata,
  Meeting,
  MeetingMetadata,
  MeetingStatus,
  SystemMessagesType,
  SystemMessages,
} from "../types/meeting";
import { logger } from "./logger";
import { ActivityTemplate } from "../types/activities/common";
import {
  Activity,
  ActivityType,
  PreuploadImageFile,
} from "../types/activities/activity";
import { createActivityMetadata, deepCopy, getRandomFloat } from "./utils";
import { SECONDS } from "../types/time";
import { Course } from "../types/course";
import { SystemErrors } from "..";
import { Team } from "../types/team";
import { QuestRoomTemplate } from "../types/activities/quest-room";

interface InteractiveElementUploadParams {
  template: ActivityTemplate;
  images: PreuploadImageFile[];
  solved?: boolean;
  index?: number;
  itemType?: string;
}

export interface SaveActivityTemplateParams {
  template: DBDocument<ActivityTemplate> | null;
  backgroundImages: PreuploadImageFile[] | null;
  solvedImages: PreuploadImageFile[][];
  unsolvedImages: PreuploadImageFile[][];
  user: AppUser | null;
}

type ImageLink = { elementIdx: number; downloadLink: string };

const firebaseConfig = {
  apiKey: "AIzaSyA5YQ16gwTxjMPdkskBCqfY3_vxt42dpT8",
  authDomain: "go-cadu.firebaseapp.com",
  projectId: "go-cadu",
  storageBucket: "go-cadu.appspot.com",
  messagingSenderId: "1050896011599",
  appId: "1:1050896011599:web:b46496f3c16957a079353a",
  measurementId: "G-P1V01Z5DPL",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

firebaseFunctions.region = "europe-west3";

if (process.env.REACT_APP_CONNECT_EMULATORS === "true") {
  logger.warn("Running with emulators");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
  connectStorageEmulator(firebaseStorage, "localhost", 5003);
} else {
  logger.debug("Running using production environment");
}

// --------------------------------------------------------
// Auth
// --------------------------------------------------------
export const signUserInWithGoogle = (): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(firebaseAuth, provider);
};

export const signUserOut = (): Promise<void> => {
  return signOut(firebaseAuth);
};

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

// --------------------------------------------------------
// Functions
// --------------------------------------------------------
const createUserFunction = httpsCallable(
  firebaseFunctions,
  "createUserFunction"
);
export const createUser = async (
  user: AppUser
): Promise<{ uid: UID; teamId?: DBDocumentID }> => {
  const { data } = await createUserFunction({ user });
  if (!data) {
    throw new Error("Error creating user");
  }
  const { uid, teamId } = data as { uid: UID; teamId?: DBDocumentID };
  return { uid, teamId };
};

const saveMeetingFunction = httpsCallable(
  firebaseFunctions,
  "saveMeetingFunction"
);
export const saveMeetingRequest = async (
  meeting: Meeting
): Promise<{ meetingId: DBDocumentID }> => {
  const { data } = await saveMeetingFunction({ meeting });
  if (!data) {
    throw new Error("Error creating meeting");
  }
  const { meetingId } = data as { meetingId: DBDocumentID };
  return { meetingId };
};

const changeMeetingStatusFunction = httpsCallable(
  firebaseFunctions,
  "changeMeetingStatusFunction"
);
export const changeMeetingStatusRequest = async (
  meetingId: DBDocumentID,
  status: MeetingStatus
): Promise<{ success: boolean }> => {
  const { data } = await changeMeetingStatusFunction({ meetingId, status });
  if (!data) {
    throw new Error("Error changing meeting status");
  }
  const { success } = data as { success: boolean };
  return { success };
};

const toggleMeetingParticipationFunction = httpsCallable(
  firebaseFunctions,
  "toggleMeetingParticipationFunction"
);
export const toggleMeetingParticipation = async (
  uid: UID,
  meetingId: DBDocumentID,
  isParticipating: boolean
): Promise<{ success: boolean }> => {
  const { data } = await toggleMeetingParticipationFunction({
    meetingId,
    isParticipating,
  });
  if (!data) {
    throw new Error("Error toggling meeting participation");
  }
  const { success } = data as { success: boolean };

  return { success };
};

export const markSystemMessageAsSeenRequest = async (
  uid: UID,
  systemMessageType: SystemMessagesType,
  meetingId: DBDocumentID,
  activityId: DBDocumentID
): Promise<{ success: boolean }> => {
  const meeting = await getMeetingByIdRequest(meetingId);
  if (!meeting || !meeting.activityIds?.length) {
    return { success: false };
  }

  const meetingMetadata = await getMeetingMetadataRequest(meetingId);
  const { metadata } = meetingMetadata;

  if (metadata) {
    const activityMetadata = metadata[activityId];

    if (activityMetadata) {
      if (!activityMetadata.systemMessages) {
        activityMetadata.systemMessages = [];
      }
      const systemMessagesIndex = activityMetadata.systemMessages.findIndex(
        (n) => n.uid === uid
      );
      if (systemMessagesIndex > -1) {
        if (systemMessageType === SystemMessagesType.GREETING) {
          activityMetadata.systemMessages[
            systemMessagesIndex
          ].hasSeenGreetingModal = true;
        } else if (systemMessageType === SystemMessagesType.SUCCESS) {
          activityMetadata.systemMessages[
            systemMessagesIndex
          ].hasSeenSuccessModal = true;
        }
      } else {
        const newSystemMessage: SystemMessages = {
          uid,
          hasSeenGreetingModal: false,
          hasSeenSuccessModal: false,
        };

        if (systemMessageType === SystemMessagesType.GREETING) {
          newSystemMessage.hasSeenGreetingModal = true;
        } else if (systemMessageType === SystemMessagesType.SUCCESS) {
          newSystemMessage.hasSeenSuccessModal = true;
        }

        activityMetadata.systemMessages.push(newSystemMessage);
      }
    }
  }

  const meetingMetadataRef = doc(meetingsMetadataCollection, meetingId);

  await updateDoc(meetingMetadataRef, {
    metadata,
  });

  return { success: true };
};

export const changeMeeplePositionRequest = async (
  meetingId: DBDocumentID,
  meepleIndex: number,
  position: { x: number; y: number }
) => {
  const meeting = await getMeetingByIdRequest(meetingId);
  const meetingMetadata = await getMeetingMetadataRequest(meetingId);

  const { metadata } = meetingMetadata as {
    metadata: Record<DBDocumentID, FreeMapActivityMetadata>;
  };

  if (!metadata[meeting?.activityIds?.[0] || ""]?.meeples?.[meepleIndex])
    throw new Error("No meeple was found");

  metadata[meeting?.activityIds?.[0] || ""].meeples[meepleIndex].x = position.x;
  metadata[meeting?.activityIds?.[0] || ""].meeples[meepleIndex].y = position.y;
  metadata[meeting?.activityIds?.[0] || ""].meeples[meepleIndex].updatedAt =
    Timestamp.now();

  const meetingMetaDataRef = doc(meetingsMetadataCollection, meetingId);

  await updateDoc(meetingMetaDataRef, {
    metadata,
  });
};

export const markQuestionAsAnsweredRequest = async (
  meetingId: DBDocumentID,
  activityId: DBDocumentID,
  questionIdx: number,
  uid: UID
): Promise<{ success: boolean }> => {
  const meetingMetadata = await getMeetingMetadataRequest(meetingId);
  if (!meetingMetadata) throw new Error("Error getting meeting metadata");

  const { metadata } = meetingMetadata;

  if (metadata) {
    const activityMetadata = metadata[activityId];

    if (activityMetadata) {
      activityMetadata.puzzlesState[questionIdx] = {
        isSolved: true,
        solvedAt: Date.now(),
        solvedBy: { uid },
      };
    }
  }
  const meetingMetaDataRef = doc(meetingsMetadataCollection, meetingId);

  await updateDoc(meetingMetaDataRef, {
    metadata,
  });
  return { success: true };
};

export const updateDiceRollRequest = async (
  meetingId: DBDocumentID,
  activityId: DBDocumentID,
  newDiceRoll: number
): Promise<{ success: boolean }> => {
  const meetingMetadataRef = doc(meetingsMetadataCollection, meetingId);

  await updateDoc(meetingMetadataRef, {
    [`metadata.${activityId}.diceRoll`]: {
      number: newDiceRoll,
      lastRolledAt: serverTimestamp() as Timestamp,
    },
  });
  return { success: true };
};

const saveActivityFunction = httpsCallable(
  firebaseFunctions,
  "saveActivityFunction"
);

export const saveActivityRequest = async (
  teamId: string,
  activity: Activity,
  imagesList: PreuploadImageFile[],
  isForcedChange = false,
  congratulationsImage: PreuploadImageFile | null,
  greetingImage: PreuploadImageFile | null
): Promise<{ activityId: DBDocumentID }> => {
  if (!activity || !activity.puzzles) {
    throw new Error("Error saving activity");
  }
  const puzzleImagesLinks = await uploadPuzzleImages(teamId, imagesList);
  const activityToAdd = deepCopy(activity);
  if (congratulationsImage) {
    const { downloadLink } = await uploadActivityMessageImage(
      teamId,
      congratulationsImage
    );
    activityToAdd.congratulationsMessage.imgSrc = downloadLink;
  }
  if (greetingImage) {
    const { downloadLink } = await uploadActivityMessageImage(
      teamId,
      greetingImage
    );
    activityToAdd.greetingMessage.imgSrc = downloadLink;
  }
  puzzleImagesLinks.forEach((link) => {
    if (!link || !activityToAdd?.puzzles[link.elementIdx]?.question) {
      // Do nothing if link or question is missing
    } else {
      activityToAdd.puzzles[link.elementIdx].question.imgSrc =
        link.downloadLink;
    }
  });
  const { data } = await saveActivityFunction({
    activity: activityToAdd,
    isForcedChange,
  });
  if (!data) {
    throw new Error("Error saving activity");
  }
  const { activityId } = data as { activityId: DBDocumentID };
  return { activityId };
};
const getTeamsByUidFunction = httpsCallable(
  firebaseFunctions,
  "getTeamsByUidFunction"
);

export const getTeamsByUid = async (
  teamId: string
): Promise<{ teams: DBDocument<Team>[] | null }> => {
  const { data } = await getTeamsByUidFunction(teamId);
  const { teams } = data as { teams: DBDocument<Team>[] | null };

  return { teams };
};

const saveProfileSettingsFunction = httpsCallable(
  firebaseFunctions,
  "saveProfileSettingsFunction"
);

export const saveProfileSettingsRequest = async (
  profileSettings: ProfileSettings
): Promise<{ success: boolean; avatarUrl: string | null }> => {
  const { uid, avatarUrl, ...restUser } = profileSettings;

  let newAvatarUrl: string | null = null;
  if (avatarUrl) {
    const { downloadLink } = await uploadAvatarImage(uid, avatarUrl);
    newAvatarUrl = downloadLink;
  }

  const { data } = await saveProfileSettingsFunction({
    ...restUser,
    avatarUrl: newAvatarUrl,
  });

  if (!data) {
    throw new Error("Error saving settings");
  }
  const { success } = data as { success: boolean };
  return { success, avatarUrl: newAvatarUrl };
};

// --------------------------------------------------------
// Firestore
// --------------------------------------------------------
const usersCollection = collection(firebaseFirestore, "users");
const meetingsCollection = collection(firebaseFirestore, "meetings");
const templatesCollection = collection(firebaseFirestore, "activityTemplates");
const activitiesCollection = collection(firebaseFirestore, "activities");
const meetingsMetadataCollection = collection(
  firebaseFirestore,
  "meetingsMetadata"
);
const coursesCollection = collection(firebaseFirestore, "courses");

export const getUser = async (
  uid: UID
): Promise<DBDocument<AppUser> | null> => {
  const user = (
    await getDoc(doc(usersCollection, uid))
  ).data() as DBDocument<AppUser> | null;
  return user || null;
};

export const getUserRequest = async (
  uid?: UID
): Promise<DBDocument<AppUser> | null> => {
  if (!uid) {
    return null;
  }
  const user = await getUser(uid);
  if (!user) {
    throw new Error("User not found");
  }
  return user;
};

export const subToUser = (
  id: DBDocumentID,
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(usersCollection, id);

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

export const getUsersByIds = async (
  uids: UID[]
): Promise<DBDocument<AppUser>[]> => {
  if (uids.length === 0) {
    return [];
  }
  const q = query(usersCollection, where("uid", "in", uids));
  const querySnapshot = await getDocs(q);
  const users: DBDocument<AppUser>[] = [];
  querySnapshot.forEach((doc) => {
    const user = doc.data() as DBDocument<AppUser>;
    users.push(user);
  });
  return users;
};

export const getMeetingsRequest = async (
  _uid: UID,
  teamId?: DBDocumentID,
  statuses: MeetingStatus[] = [MeetingStatus.SCHEDULED]
): Promise<DBDocument<Meeting>[]> => {
  if (!teamId) {
    return [];
  }
  const q = query(
    meetingsCollection,
    where("teamId", "==", teamId),
    where("status", "in", statuses),
    orderBy("plannedStartTimestamp", "desc")
  );
  const querySnapshot = await getDocs(q);
  const meetings: DBDocument<Meeting>[] = [];
  querySnapshot.forEach((doc) => {
    const meeting = doc.data() as DBDocument<Meeting>;
    if (!meeting.isDeleted) {
      meetings.push(meeting);
    }
  });
  return meetings;
};

export const getTemplatesRequest = async (): Promise<
  DBDocument<QuestRoomTemplate>[]
> => {
  const q = query(templatesCollection, orderBy("name"));
  const querySnapshot = await getDocs(q);
  const templates: DBDocument<QuestRoomTemplate>[] = [];
  querySnapshot.forEach((doc) => {
    const template = doc.data() as DBDocument<QuestRoomTemplate>;
    if (!template.isDeleted) {
      templates.push(template);
    }
  });
  return templates;
};

export const getCoursesRequest = async (
  teamId?: DBDocumentID
): Promise<DBDocument<Course>[]> => {
  if (!teamId) {
    return [];
  }
  const q = query(
    coursesCollection,
    where("teamId", "==", teamId),
    orderBy("startsAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  const courses: DBDocument<Course>[] = [];
  querySnapshot.forEach((doc) => {
    const course = doc.data() as DBDocument<Course>;
    if (!course.isDeleted) {
      courses.push(course);
    }
  });
  return courses;
};

export const getCourseByIdRequest = async (
  courseId: DBDocumentID
): Promise<DBDocument<Course>> => {
  const course = (
    await getDoc(doc(coursesCollection, courseId))
  ).data() as DBDocument<Course> | null;

  if (!course) throw new Error(SystemErrors.CourseNotFound);

  return course;
};

export const saveCourseRequest = async (
  course: DBDocument<Course>,
  uid: UID
): Promise<{ success: boolean }> => {
  if (!course) return { success: false };
  const now = Timestamp.now().seconds * SECONDS;

  const newCourse = {
    ...course,
    id: course.id || doc(coursesCollection).id,
    isDeleted: course.isDeleted || false,
    createdAt: course.createdAt || now,
    updatedAt: now,
    createdBy: course.createdBy || uid,
    updatedBy: uid,
  };

  try {
    const courseRef = doc(coursesCollection, newCourse.id);
    await setDoc(courseRef, newCourse);
    return { success: true };
  } catch (error) {
    console.error("Error saving course:", error);
    return { success: false };
  }
};

export const deleteCourseRequest = async (
  courseId: DBDocumentID
): Promise<{ success: boolean }> => {
  if (!courseId) {
    return { success: false };
  }
  const courseRef = doc(coursesCollection, courseId);

  try {
    const courseSnap = await getDoc(courseRef);
    if (!courseSnap.exists()) {
      throw new Error(SystemErrors.CourseNotFound);
    }

    const courseData = courseSnap.data() as Course;
    const participants = courseData.participants || {};

    const meetingIds = Object.values(participants)
      .flat()
      .map((participant) => participant.meetingId);

    await updateDoc(courseRef, { isDeleted: true });

    const updatePromises = meetingIds.map(async (meetingId) => {
      const meetingRef = doc(meetingsCollection, meetingId);
      return updateDoc(meetingRef, { status: MeetingStatus.DELETED });
    });

    await Promise.all(updatePromises);

    return { success: true };
  } catch (error) {
    console.error("Error deleting course or related meetings:", error);
    return { success: false };
  }
};

export const getActivityTemplateByIdRequest = async (
  id: DBDocumentID
): Promise<DBDocument<QuestRoomTemplate>> => {
  const template = (
    await getDoc(doc(templatesCollection, id))
  ).data() as DBDocument<QuestRoomTemplate> | null;

  if (!template) throw new Error("Template doesn't exist");

  return template;
};

export const getActivitiesRequest = async (
  teamId?: DBDocumentID
): Promise<DBDocument<Activity>[]> => {
  if (!teamId) {
    return [];
  }
  const q = query(
    activitiesCollection,
    where("teamId", "==", teamId),
    where("isDeleted", "!=", true),
    orderBy("isDeleted"),
    orderBy("name")
  );
  const querySnapshot = await getDocs(q);
  const activities: DBDocument<Activity>[] = [];
  querySnapshot.forEach((doc) => {
    const activity = doc.data() as DBDocument<Activity>;
    activities.push(activity);
  });
  return activities;
};

export const getActivityByIdRequest = async (
  id: DBDocumentID
): Promise<DBDocument<Activity>> => {
  const activity = (
    await getDoc(doc(activitiesCollection, id))
  ).data() as DBDocument<Activity> | null;

  if (!activity) throw new Error("Activity doesn't exist");

  return activity;
};

export const getPublicActivitiesRequest = async (): Promise<
  DBDocument<Activity>[]
> => {
  const q = query(
    activitiesCollection,
    where("isPublic", "==", true),
    where("isDeleted", "!=", true),
    orderBy("isDeleted"),
    orderBy("name")
  );
  const querySnapshot = await getDocs(q);
  const activities: DBDocument<Activity>[] = [];
  querySnapshot.forEach((doc) => {
    const activity = doc.data() as DBDocument<Activity>;
    activities.push(activity);
  });
  return activities;
};

export const getMeeting = async (
  _uid: UID,
  teamId: DBDocumentID,
  id: DBDocumentID
): Promise<DBDocument<Meeting> | null> => {
  const meeting = (
    await getDoc(doc(meetingsCollection, id))
  ).data() as DBDocument<Meeting> | null;
  return meeting?.teamId === teamId ? meeting : null;
};

export const getMeetingByIdRequest = async (
  id: DBDocumentID
): Promise<DBDocument<Meeting>> => {
  const meeting = (
    await getDoc(doc(meetingsCollection, id))
  ).data() as DBDocument<Meeting> | null;

  if (!meeting) throw new Error(SystemErrors.MeetingNotFound);

  return meeting;
};

export const getMeetingsByIdsRequest = async (
  ids: DBDocumentID[]
): Promise<DBDocument<Meeting>[]> => {
  if (ids.length === 0) {
    return [];
  }
  const q = query(meetingsCollection, where("id", "in", ids));
  const querySnapshot = await getDocs(q);
  const meetings: DBDocument<Meeting>[] = [];
  querySnapshot.forEach((doc) => {
    const meeting = doc.data() as DBDocument<Meeting>;
    meetings.push(meeting);
  });
  return meetings;
};

export const subToMeeting = (
  id: DBDocumentID,
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(meetingsCollection, id);

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

export const subToMeetingMetadata = (
  id: DBDocumentID,
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(
    meetingsMetadataCollection,
    id
  );

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

const getMeetingMetadataRequest = async (
  meetingId: DBDocumentID
): Promise<MeetingMetadata> => {
  const q = query(
    meetingsMetadataCollection,
    where("meetingId", "==", meetingId)
  );
  const querySnapshot = await getDocs(q);
  let meetingMetadata: MeetingMetadata | null = null;

  querySnapshot.forEach((doc) => {
    meetingMetadata = doc.data() as MeetingMetadata;
  });

  if (!meetingMetadata) {
    const meeting = await getMeetingByIdRequest(meetingId);
    if (!meeting.activityIds?.length)
      throw new Error("Meeting had no activities");
    const activity = await getActivityByIdRequest(meeting.activityIds[0]);

    const newMeetingMetadata = {
      meetingId,
      metadata: {
        [activity.id]: createActivityMetadata(activity),
      },
    };

    const newMeetingMetadataRef = doc(meetingsMetadataCollection, meetingId);
    await setDoc(newMeetingMetadataRef, newMeetingMetadata);

    meetingMetadata = {
      ...newMeetingMetadata,
    };
  }

  return meetingMetadata;
};

export const getMeetingsMetadataRequest = async (
  meetingsId: DBDocumentID[]
): Promise<MeetingMetadata[]> => {
  if (meetingsId.length === 0) {
    return [];
  }
  const q = query(
    meetingsMetadataCollection,
    where("meetingId", "in", meetingsId)
  );
  const querySnapshot = await getDocs(q);
  const meetingsMetadata: MeetingMetadata[] = [];
  querySnapshot.forEach((doc) => {
    const meetingMetadata = doc.data() as MeetingMetadata;
    meetingsMetadata.push(meetingMetadata);
  });
  return meetingsMetadata;
};

export const addUserMeepleRequest = async (
  meetingId: DBDocumentID,
  uid: UID
) => {
  const meeting = await getMeetingByIdRequest(meetingId);
  if (!meeting?.activityIds?.length) return;

  const { type: activityType, id: activityId } = await getActivityByIdRequest(
    meeting.activityIds[0]
  );

  if (activityType !== ActivityType.FREE_MAP) return;

  const meetingMetadata = await getMeetingMetadataRequest(meetingId);
  const { metadata } = meetingMetadata as {
    metadata: Record<DBDocumentID, FreeMapActivityMetadata>;
  };

  const existingMeeple = metadata[activityId].meeples.find(
    ({ uid: meepleOwnerId }) => meepleOwnerId === uid
  );
  if (existingMeeple) return;

  metadata[activityId].meeples.push({
    uid,
    x: getRandomFloat(0, 5),
    y: getRandomFloat(0, 8),
    updatedAt: Timestamp.now(),
  });

  const meetingMetaDataRef = doc(meetingsMetadataCollection, meetingId);

  await updateDoc(meetingMetaDataRef, {
    metadata,
  });
};

export const createMeetingMetadataRequest = async (meetingId: DBDocumentID) => {
  const meetingMetadata: MeetingMetadata = {
    meetingId,
    metadata: {},
  };
  const meeting = await getMeetingByIdRequest(meetingId);
  if (meeting?.activityIds?.length) {
    const activity = await getActivityByIdRequest(meeting.activityIds[0]);
    meetingMetadata.metadata[activity.id] = createActivityMetadata(activity);
  }

  const newMeetingMetadataRef = doc(meetingsMetadataCollection, meetingId);
  await setDoc(newMeetingMetadataRef, meetingMetadata);
};

export const deleteActivityRequest = async (
  activity: Activity
): Promise<{ success: boolean }> => {
  const activityToSave = deepCopy(activity);
  activityToSave.isDeleted = true;
  const { data } = await saveActivityFunction({
    activity: activityToSave,
    isForcedChange: true,
  });
  if (!data) {
    throw new Error("Error deleting activity");
  }
  const { success } = data as { success: boolean };
  return { success };
};

export const saveActivityTemplateRequest = async (
  params: SaveActivityTemplateParams
): Promise<{ success: boolean }> => {
  const { template, backgroundImages, solvedImages, unsolvedImages, user } =
    params;
  if (!template) return { success: false };
  const now = Timestamp.now().seconds * SECONDS;
  const createdBy = user?.displayName || "";
  const updatedBy = user?.displayName || "";

  let newTemplate = {
    ...template,
    id: template.id || doc(templatesCollection).id,
    createdAt: template.createdAt || now,
    updatedAt: now,
    createdBy,
    updatedBy,
  };

  const bgUploadPromise = uploadActivityTemplateImages({
    template: newTemplate,
    images: backgroundImages || [],
    itemType: "views",
  });
  const uploadPromises: Promise<void>[] = [];
  solvedImages.forEach((solvedImagesView, solvedIndex) => {
    if (solvedImagesView.length > 0) {
      const promise = uploadInteractiveElementImages({
        template: newTemplate,
        images: solvedImagesView,
        index: solvedIndex,
        solved: true,
        itemType: "items",
      });
      uploadPromises.push(promise);
    }
  });
  unsolvedImages.forEach((unsolvedImagesView, unsolvedIndex) => {
    if (unsolvedImagesView.length > 0) {
      const promise = uploadInteractiveElementImages({
        template: newTemplate,
        images: unsolvedImagesView,
        index: unsolvedIndex,
        solved: false,
        itemType: "items",
      });
      uploadPromises.push(promise);
    }
  });
  try {
    const [bgUploadImages] = await Promise.all([
      bgUploadPromise,
      ...uploadPromises,
    ]);
    bgUploadImages.forEach((link) => {
      if (!link) return;
      const updatedViews = newTemplate.views.map((view, index) => {
        if (index === link.elementIdx) {
          return {
            ...view,
            backgroundImage: link.downloadLink,
          };
        } else {
          return view;
        }
      });
      newTemplate = {
        ...newTemplate,
        views: updatedViews,
      };
    });
    const activityTemplateRef = doc(templatesCollection, newTemplate.id);
    await setDoc(activityTemplateRef, newTemplate, { merge: true });
    return { success: true };
  } catch (error) {
    throw new Error("Error uploading activity template images:" + error);
  }
};

export const deleteActivityTemplateRequest = async (
  templateId: string
): Promise<{ success: boolean }> => {
  if (!templateId) {
    throw new Error("Error deleting activity template");
  }
  const activityTemplateRef = doc(templatesCollection, templateId);

  await updateDoc(activityTemplateRef, {
    isDeleted: true,
  });
  return { success: true };
};

const uploadInteractiveElementImages = async (
  params: InteractiveElementUploadParams
): Promise<void> => {
  const imagesLinks = await uploadActivityTemplateImages(params);
  const updatedViews = params.template.views.map((view, index) => {
    if (index === params.index) {
      const updatedInteractiveElements = view.interactiveElements.map(
        (interactiveElement, idx) => {
          const matchingImageLink = imagesLinks.find(
            (link) => link.elementIdx === idx
          );
          if (matchingImageLink) {
            return {
              ...interactiveElement,
              [params.solved ? "imageSolved" : "imageUnsolved"]:
                matchingImageLink.downloadLink,
            };
          } else {
            return interactiveElement;
          }
        }
      );
      return {
        ...view,
        interactiveElements: updatedInteractiveElements,
      };
    } else {
      return view;
    }
  });

  params.template.views = updatedViews;
};
// --------------------------------------------------------
// Storage
// --------------------------------------------------------
const uploadFile = async (
  path: string,
  fileData: File
): Promise<string | null> => {
  const storageRef = ref(firebaseStorage, path);
  const uploadResult = await uploadBytesResumable(storageRef, fileData);
  const fileLink = await getDownloadURL(uploadResult.ref);
  return fileLink;
};

const uploadPuzzleImages = async (
  teamId: string,
  imagesList: PreuploadImageFile[]
): Promise<ImageLink[]> => {
  const downloadLinks: { elementIdx: number; downloadLink: string }[] = [];
  for (const imageData of imagesList) {
    const filePath = `activities/${teamId}/activity-image-${Date.now()}`;
    const downloadLink = await uploadFile(filePath, imageData.file);
    downloadLinks.push({
      elementIdx: imageData.elementIdx,
      downloadLink: downloadLink || "",
    });
  }
  return downloadLinks;
};

const uploadActivityMessageImage = async (
  teamId: string,
  image: PreuploadImageFile
): Promise<{ downloadLink: string }> => {
  const filePath = `activities/${teamId}/activity-message-image-${Date.now()}`;
  const downloadLink = await uploadFile(filePath, image.file);
  return { downloadLink: downloadLink || "" };
};

const uploadAvatarImage = async (
  uid: string,
  avatar: File
): Promise<{ downloadLink: string }> => {
  const filePath = `avatars/${uid}/avatar-image-${Date.now()}`;
  const downloadLink = await uploadFile(filePath, avatar);
  return { downloadLink: downloadLink || "" };
};

const uploadActivityTemplateImages = async (
  params: InteractiveElementUploadParams
): Promise<ImageLink[]> => {
  const promises: Promise<ImageLink>[] = [];
  params.images.forEach((imageData, index) => {
    const viewIndex = params.itemType === "views" ? index : params.index;
    const promise = (async () => {
      const fileExtension = imageData.file.name.split(".").pop() || "unknown";
      let prefix = "";
      switch (params.template.type) {
        case ActivityType.QUEST_ROOM:
          prefix = "quest-room";
          break;
        case ActivityType.FREE_MAP:
          prefix = "free-map";
          break;
        default:
          throw new Error("Unsupported activity type");
      }
      const filePath = `${prefix}-assets/${params.itemType}/${params.template.id}/view-number-${viewIndex}/${prefix}-item-${imageData.elementIdx}${params.solved ? "-solved" : ""}.${fileExtension}`;
      const downloadLink = await uploadFile(filePath, imageData.file);
      return {
        elementIdx: imageData.elementIdx,
        downloadLink: downloadLink || "",
      };
    })();
    promises.push(promise);
  });
  const downloadLinks = await Promise.all(promises);
  return downloadLinks;
};
