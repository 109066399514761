import React, { FunctionComponent, useEffect } from "react";
import "./Loading.css";

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  fullscreen?: boolean;
}

export const Loading: FunctionComponent<LoadingProps> = ({
  fullscreen,
  className,
  ...props
}) => {
  useEffect(() => {
    if (fullscreen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [fullscreen]);

  return fullscreen ? (
    <div
      {...props}
      className={`loading-fullscreen d-flex align-items-center justify-content-center z-index-highest ${
        className || ""
      }`}
    >
      <div className="spinner-border spinner-border-sm" role="status" />
    </div>
  ) : (
    <div className={className} {...props}>
      <div className="spinner-border spinner-border-sm" role="status" />
    </div>
  );
};
