import { FunctionComponent } from "react";
import { Rectangle } from "../../types/activities/common";

export type Visibility = "visible" | "hidden" | "collapse";

export interface InteractiveElementProps
  extends React.HTMLAttributes<HTMLDivElement> {
  index: number;
  image?: string;
  visibility: Visibility;
  rectangle: Rectangle;
}

export const InteractiveElement: FunctionComponent<InteractiveElementProps> = ({
  index,
  visibility,
  children,
  rectangle,
  style,
  image,
  ...otherProps
}) => {
  const { endX, endY, startX, startY } = rectangle;
  return (
    <div
      key={`interactive-element-${index}`}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        gridColumn: `${startX} / span ${endX - startX + 1}`,
        gridRow: `${startY} / span ${endY - startY + 1}`,
        visibility: `${visibility}`,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};
