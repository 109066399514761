import React, { FunctionComponent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { signUserOut } from "../../services/firebase";
import { MainPane } from "../../components/MainPane/MainPane";
import { NavigationPane } from "../../components/NavigationPane/NavigationPane";
import { NavigationPaneButton } from "../../components/NavigationPane/NavigationPaneButton";
import { PageNotFound } from "../../components/PageNotFound/PageNotFound";
import { Animation } from "../../components/Animation/Animation";
import { Overview } from "./pages/Overview/Overview";
import { Activities } from "./pages/Activities/Activities";
import { Meetings } from "./pages/Meetings/Meetings";
import { Settings } from "./pages/Settings/Settings";
import { Templates } from "./pages/Templates/Templates";
import { Courses } from "./pages/Courses/Courses";
import { UpsertMeetingModal } from "./pages/Meetings/UpsertMeetingModal";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { UpsertActivityModal } from "./pages/Activities/UpsertActivityModal";
import { isLogoutDialogOpenAtom } from "../../state/atoms/ui";
import { Dialog } from "../../components/Dialog/Dialog";
import { Icon, Icons } from "../../components/Icons/Icon";
import { UpsertActivityTemplateModal } from "./pages/Templates/UpsertActivityTemplateModal";
import { UpsertCourseModal } from "./pages/Courses/UpsertCourseModal";
import { AppRoutes } from "../../services/consts";
import { useGetUser } from "../../services/users";

type ManagementProps = React.HTMLAttributes<HTMLDivElement>;

export const Management: FunctionComponent<ManagementProps> = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const { data: signedInUser } = useGetUser(signedInUserId);
  const setIsLogoutDialogOpen = useSetRecoilState(isLogoutDialogOpenAtom);

  return (
    <div>
      <div className="d-flex m-0 p-0">
        <div className="m-0 p-0">
          <NavigationPane>
            <div className="p-2 align-self-start mb-0">
              <NavigationPaneButton
                onClick={() => navigate(AppRoutes.MGMT_APP)}
                selected={pathname === AppRoutes.MGMT_APP}
              >
                <Icon icon={Icons.HOME} />
              </NavigationPaneButton>
              {signedInUser?.isCreator && (
                <NavigationPaneButton
                  className="mt-3"
                  onClick={() =>
                    navigate(`${AppRoutes.MGMT_APP}${AppRoutes.TEMPLATES}`)
                  }
                  selected={pathname.startsWith(
                    `${AppRoutes.MGMT_APP}${AppRoutes.TEMPLATES}`
                  )}
                >
                  <Icon icon={Icons.TEMPLATES} />
                </NavigationPaneButton>
              )}
              <NavigationPaneButton
                className="mt-3"
                onClick={() =>
                  navigate(`${AppRoutes.MGMT_APP}${AppRoutes.ACTIVITIES}`)
                }
                selected={pathname.startsWith(
                  `${AppRoutes.MGMT_APP}${AppRoutes.ACTIVITIES}`
                )}
              >
                <Icon icon={Icons.ACTIVITIES} />
              </NavigationPaneButton>
              <NavigationPaneButton
                className="mt-3"
                onClick={() =>
                  navigate(`${AppRoutes.MGMT_APP}${AppRoutes.MEETINGS}`)
                }
                selected={pathname.startsWith(
                  `${AppRoutes.MGMT_APP}${AppRoutes.MEETINGS}`
                )}
              >
                <Icon icon={Icons.MEETINGS} />
              </NavigationPaneButton>
              <NavigationPaneButton
                className="mt-3"
                onClick={() =>
                  navigate(`${AppRoutes.MGMT_APP}${AppRoutes.COURSES}`)
                }
                selected={pathname.startsWith(
                  `${AppRoutes.MGMT_APP}${AppRoutes.COURSES}`
                )}
              >
                <Icon icon={Icons.COURSES} />
              </NavigationPaneButton>
              <NavigationPaneButton
                className="mt-3"
                onClick={() =>
                  navigate(`${AppRoutes.MGMT_APP}${AppRoutes.SETTINGS}`)
                }
                selected={pathname.startsWith(
                  `${AppRoutes.MGMT_APP}${AppRoutes.SETTINGS}`
                )}
              >
                <Icon icon={Icons.SETTINGS} />
              </NavigationPaneButton>
              <NavigationPaneButton
                className="mt-3"
                selected={false}
                onClick={() => {
                  setIsLogoutDialogOpen(true);
                }}
              >
                <Icon icon={Icons.LOGOUT} />
              </NavigationPaneButton>
            </div>
          </NavigationPane>
        </div>
        <div className="m-0 p-0 w-100">
          <MainPane>
            <Animation animateOn={pathname}>
              <Routes location={location}>
                <Route path="/" element={<Overview />} />
                <Route path={AppRoutes.TEMPLATES} element={<Templates />} />
                <Route path={AppRoutes.ACTIVITIES} element={<Activities />} />
                <Route path={AppRoutes.MEETINGS} element={<Meetings />} />
                <Route path={AppRoutes.COURSES} element={<Courses />} />
                <Route path={AppRoutes.SETTINGS} element={<Settings />} />
                <Route
                  path="*"
                  element={<PageNotFound goTo={AppRoutes.MGMT_APP} />}
                />
              </Routes>
            </Animation>
          </MainPane>
        </div>
      </div>
      <UpsertActivityTemplateModal />
      <UpsertActivityModal />
      <UpsertMeetingModal />
      <UpsertCourseModal />
      <Dialog
        header="Are you sure you want to logout?"
        state={isLogoutDialogOpenAtom}
        onApprove={signUserOut}
        approveText="Logout"
        cancelText="Cancel"
      />
    </div>
  );
};
