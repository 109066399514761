import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { InputFile } from "../../../../components/Input/InputFile";
import { InputTextArea } from "../../../../components/Input/InputTextArea";
import {
  activityQuestionsImagesAtom,
  upsertActivityFormValuesAtom,
} from "../../../../state/atoms/activities";
import { Question } from "../../../../types/activities/common";
import { FileTypes } from "../../../../types/input";
import { ImagePreview } from "../../../../components/ImagePreview/ImagePreview";
import { Button } from "../../../../components/Button/Button";
import { isActivityElementFormModalOpenAtom } from "../../../../state/atoms/ui";

export const ActivityElementForm: FunctionComponent<{
  elementIdx: number;
}> = ({ elementIdx }) => {
  const [upsertActivityFormValues, setUpsertActivityFormValues] =
    useRecoilState(upsertActivityFormValuesAtom);
  const [activityQuestionsImages, setActivityQuestionsImages] = useRecoilState(
    activityQuestionsImagesAtom
  );

  const setIsElementFormModalOpen = useSetRecoilState(
    isActivityElementFormModalOpenAtom
  );

  const [question, setQuestion] = useState<Question>(
    upsertActivityFormValues?.puzzles[elementIdx]?.question || {
      text: "",
      imgSrc: "",
    }
  );

  const [answer, setAnswer] = useState(
    upsertActivityFormValues?.puzzles[elementIdx]?.answers[0]?.text || ""
  );

  const [successMessage, setSuccessMessage] = useState(
    upsertActivityFormValues?.puzzles[elementIdx]?.successMessage
  );

  const [failureMessage, setFailureMessage] = useState(
    upsertActivityFormValues?.puzzles[elementIdx]?.failureMessage
  );

  useEffect(() => {
    if (!upsertActivityFormValues?.templateId) return;
    setUpsertActivityFormValues({
      ...upsertActivityFormValues,
      puzzles: upsertActivityFormValues.puzzles.map((puzzle, puzzleIdx) => {
        return puzzleIdx === elementIdx
          ? {
              ...puzzle,
              question,
              answers: answer ? [{ text: answer, isCorrect: true }] : [],
              successMessage,
              failureMessage,
            }
          : puzzle;
      }),
    });
    // eslint-disable-next-line
  }, [question, answer, successMessage, failureMessage]);

  return (
    <div className="mb-4">
      <div>
        Question
        <InputTextArea
          value={question.text}
          onChange={(evt) => {
            setQuestion((prevState) => ({
              ...prevState,
              text: evt.target.value,
            }));
          }}
          className="mb-2"
        />
        {question.imgSrc ? (
          <ImagePreview
            imgSrc={question.imgSrc}
            onRemove={() => {
              setQuestion((prevState) => ({
                ...prevState,
                imgSrc: "",
              }));
            }}
          />
        ) : (
          <InputFile
            src={question.imgSrc}
            acceptFile={[FileTypes.IMAGE]}
            onChange={async (ev) => {
              if (ev.target.files) {
                const files = Array.from(ev.target.files);
                if (files.length === 0) return;
                setQuestion((prevState) => ({
                  ...prevState,
                  imgSrc: URL.createObjectURL(files[0]),
                }));
                setActivityQuestionsImages([
                  ...activityQuestionsImages,
                  { elementIdx, file: files[0] },
                ]);
              }
            }}
          >
            <p className="btn btn-outline-secondary w-100">Upload image</p>
          </InputFile>
        )}
      </div>
      <div className="mt-2">
        Answer
        <InputTextArea
          value={answer}
          onChange={(evt) => {
            setAnswer(evt.target.value);
          }}
        />
      </div>

      <div className="mt-2">
        Success Message
        <InputTextArea
          placeholder="This is correct! Good job!"
          value={successMessage}
          onChange={(evt) => {
            setSuccessMessage(evt.target.value);
          }}
        />
      </div>

      <div className="mt-2">
        Failure Message
        <InputTextArea
          placeholder="Nope. Better luck next time."
          value={failureMessage}
          onChange={(evt) => {
            setFailureMessage(evt.target.value);
          }}
        />
        <Button
          onClick={() => setIsElementFormModalOpen(false)}
          className="float-end mt-4"
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
