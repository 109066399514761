import React, { FunctionComponent } from "react";

type InputTextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

export const InputTextArea: FunctionComponent<InputTextAreaProps> = ({
  className,
  children,
  onChange,
  ...props
}) => {
  const resize = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e;
    if (!target) return;
    target.style.height = "auto";
    target.style.height = target.scrollHeight + "px";
    onChange?.(e);
  };

  return (
    <textarea
      rows={1}
      className={`form-control w-100 overflow-hidden ${className}`}
      {...props}
      onChange={resize}
    >
      {children}
    </textarea>
  );
};
