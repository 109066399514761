import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { DBDocument, DBDocumentID } from "../../types/document";
import { AppUser } from "../../types/user";
import {
  Meeting as MeetingType,
  MeetingMetadata,
  MeetingStatus,
} from "../../types/meeting";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { isEndMeetingDialogOpenAtom } from "../../state/atoms/ui";
import { logger } from "../../services/logger";
import {
  createMeetingMetadataRequest,
  subToMeeting,
  subToMeetingMetadata,
  toggleMeetingParticipation,
} from "../../services/firebase";
import { useWindowDimensions } from "../../hooks";
import { Loading } from "../../components/Loading/Loading";
import { Button } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { CoverPage } from "../../components/CoverPage/CoverPage";
import { MeetingControlsContainer } from "./MeetingControlsContainer";
import { Participant } from "../../components/Participant/Participant";
import { ActivityPlayer } from "../../components/ActivityPlayer/ActivityPlayer";
import {
  useEndMeeting,
  useStartMeeting,
  useAddUserMeeple,
} from "../../services/meetings";
import {
  contextMeetingAtom,
  contextMeetingMetadataAtom,
} from "../../state/atoms/meetings";
import { Unsubscribe } from "firebase/firestore";

type MeetingProps = React.HTMLAttributes<HTMLDivElement>;

export const Meeting: FunctionComponent<MeetingProps> = () => {
  const { meetingId } = useParams();
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const [isEndMeetingDialogOpen, setIsEndMeetingDialogOpen] = useRecoilState(
    isEndMeetingDialogOpenAtom
  );

  const [contextMeeting, setContextMeeting] =
    useRecoilState(contextMeetingAtom);
  const [contextMeetingMetadata, setMeetingMetadata] = useRecoilState(
    contextMeetingMetadataAtom
  );

  const [isSignedInUserHost, setIsSignedInUserHost] = useState(false);

  const { windowWidth, windowHeight } = useWindowDimensions();
  const minSide = Math.min(windowWidth, windowHeight);
  const participantSize = minSide / 8 - 2;
  const participantSizeVw = (participantSize / windowWidth) * 100;
  const containerWidth = ((windowHeight - participantSize - 64) * 12) / 9;

  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus | null>(
    null
  );
  const [meetingActivityId, setMeetingActivityId] = useState<string | "">("");

  const { participants = [] } = contextMeeting || { participants: [] };

  const currentParticipant = participants.find((p) => p.uid === signedInUserId);

  const { mutateAsync: handleStartMeeting, isLoading: isStarting } =
    useStartMeeting();

  const { mutateAsync: handleEndMeeting, isLoading: isEnding } =
    useEndMeeting();

  const { mutateAsync: handleAddUserMeeple, isLoading: isAddingMeeple } =
    useAddUserMeeple(meetingId);

  useEffect(() => {
    if (contextMeeting?.activityIds?.[0]) {
      setMeetingActivityId(contextMeeting.activityIds[0]);
    }
  }, [contextMeeting]);

  useEffect(() => {
    logger.debug("Meeting.tsx, useEffect#1 fired");
    let unsubFromMeeting: Unsubscribe;
    let unsubFromMeetingMetadata: Unsubscribe;

    const subToMeetingInfo = async () => {
      if (meetingId && signedInUserId) {
        unsubFromMeeting = subToMeeting(meetingId as DBDocumentID, (doc) => {
          if (doc.exists()) {
            const meeting = doc.data() as DBDocument<MeetingType>;
            logger.debug("Meeting doc updated", doc.data());
            setMeetingStatus(meeting.status || null);
            setContextMeeting(meeting);
            setIsSignedInUserHost(meeting.hostId === signedInUserId);
          } else {
            setMeetingStatus(MeetingStatus.DELETED);
          }
        });

        const subscribeToMeetingMetadata = () =>
          subToMeetingMetadata(meetingId as DBDocumentID, async (doc) => {
            if (doc.exists()) {
              const meetingMetadata = doc.data() as DBDocument<MeetingMetadata>;
              logger.debug("Meeting metadata doc updated", doc.data());
              setMeetingMetadata(meetingMetadata);
            } else {
              await createMeetingMetadataRequest(meetingId);
              unsubFromMeetingMetadata = subscribeToMeetingMetadata();
            }
          });

        unsubFromMeetingMetadata = subscribeToMeetingMetadata();
      }
    };
    subToMeetingInfo();
    return () => {
      unsubFromMeeting?.();
      unsubFromMeetingMetadata?.();
    };
  }, [meetingId, setContextMeeting, setMeetingMetadata, signedInUserId]);

  useEffect(() => {
    const toggleParticipation = async () => {
      if (
        meetingId &&
        meetingStatus === MeetingStatus.IN_PROGRESS &&
        signedInUserId
      ) {
        if (!currentParticipant) {
          logger.debug("User is not a participant, asking to join");
          const { success } = await toggleMeetingParticipation(
            signedInUserId,
            meetingId,
            true
          );

          if (!success) return;
          await handleAddUserMeeple({
            uid: signedInUserId,
          });
        }
      }
    };
    toggleParticipation();
  }, [
    contextMeeting,
    signedInUserId,
    contextMeetingMetadata,
    currentParticipant,
    handleAddUserMeeple,
    meetingId,
    meetingStatus,
  ]);

  const isStartingMeeting = isStarting || isAddingMeeple;

  if (!signedInUserId || !meetingId || !meetingStatus) {
    return <Loading fullscreen />;
  }

  if (meetingStatus !== MeetingStatus.IN_PROGRESS) {
    let coverPageContent = <></>;
    switch (meetingStatus) {
      case MeetingStatus.SCHEDULED:
        coverPageContent = (
          <>
            <h2>This meeting has not started yet</h2>
            {isSignedInUserHost ? (
              <Button
                btnSize="lg"
                className="mt-2"
                disabled={isStartingMeeting}
                onClick={async () => {
                  if (contextMeeting) {
                    await handleStartMeeting({ meetingId });
                  }
                }}
              >
                {isStartingMeeting ? <Loading /> : "Start meeting"}
              </Button>
            ) : (
              <h4>
                <i>
                  The room will appear immediately when the host starts the
                  meeting
                </i>
              </h4>
            )}
          </>
        );
        break;
      case MeetingStatus.COMPLETED:
        coverPageContent = (
          <>
            <h2>This meeting has ended</h2>
            <h4>
              <i>We hope to see you again soon!</i>
            </h4>
          </>
        );
        break;
      case MeetingStatus.DELETED:
        coverPageContent = (
          <>
            <h2>This meeting does not exist</h2>
            <h4>
              <i>Please contact the meeting host for details</i>
            </h4>
          </>
        );
        break;
      default:
        coverPageContent = (
          <>
            <h2>Something went completely wrong</h2>
            <h4>
              <i>Please contact the support to fix this</i>
            </h4>
          </>
        );
        break;
    }

    return <CoverPage>{coverPageContent}</CoverPage>;
  }

  const renderParticipants = (participants: AppUser[]) => {
    return participants.slice(0, 8).map((participant, idx) => {
      const participantIdx = idx;
      return (
        <div
          key={`participant-${participantIdx}`}
          style={{
            width: `${participantSizeVw}vw`,
            height: `${participantSizeVw}vw`,
            padding: `${participantSizeVw * 0.05}vw`,
          }}
        >
          <Participant
            className="ar-1-1"
            user={participant}
            activityId={meetingActivityId}
          />
        </div>
      );
    });
  };

  return (
    <div className="p-0">
      <div className="meeting">
        <div className="align-self-center">
          <div
            className="d-flex justify-content-center p-0"
            style={{
              minHeight: `${participantSizeVw}vw`,
            }}
          >
            {renderParticipants(participants)}
          </div>
          <div className="p-0">
            <div
              className="m-auto"
              style={{
                maxWidth: `${containerWidth}px`,
              }}
            >
              <ActivityPlayer activityId={meetingActivityId} />
            </div>
          </div>
        </div>
      </div>
      <MeetingControlsContainer>
        <div
          style={{
            width: `${containerWidth}px`,
            padding: `${participantSizeVw * 0.04}vw`,
          }}
          className="position-relative m-auto"
        >
          <div className="row p-0 m-0">
            {isSignedInUserHost && (
              <div className="col p-0 align-self-end">
                <Button
                  btnType="danger"
                  className="float-end"
                  disabled={isEnding}
                  onClick={() => {
                    setIsEndMeetingDialogOpen(true);
                  }}
                >
                  {isEnding ? <Loading /> : "End meeting"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </MeetingControlsContainer>
      {isEndMeetingDialogOpen && (
        <Dialog
          header="Are you sure you want to end the meeting?"
          state={isEndMeetingDialogOpenAtom}
          onApprove={async () => {
            if (contextMeeting) {
              await handleEndMeeting({ meetingId });
            }
          }}
          approveText="Yes"
          cancelText="No"
        />
      )}
    </div>
  );
};
