import React, { FunctionComponent, useEffect } from "react";
import { Timestamp } from "firebase/firestore";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import DateTimeRangePicker from "../../../../components/DateTimeRangePicker/DateTimeRangePicker";

import { logger } from "../../../../services/logger";
import { useSaveMeeting } from "../../../../services/meetings";
import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import { isUpsertMeetingModalOpenAtom } from "../../../../state/atoms/ui";
import { upsertMeetingFormValuesAtom } from "../../../../state/atoms/meetings";
import { canUpsertMeetingAtom } from "../../../../state/selectors/meetings";
import { HOURS, SECONDS } from "../../../../types/time";
import { Meeting, MeetingStatus } from "../../../../types/meeting";
import { Modal } from "../../../../components/Modal/Modal";
import { InputText } from "../../../../components/Input/InputText";
import { Button } from "../../../../components/Button/Button";
import { Loading } from "../../../../components/Loading/Loading";
import { InputSelect } from "../../../../components/Input/InputSelect";
import {
  useGetActivities,
  useGetPublicActivities,
} from "../../../../services/activities";
import { notificationsAtom } from "../../../../state/atoms/notifications";
import { NotificationType } from "../../../../types/notification";
import { Team } from "../../../../types/team";
import { AppUser } from "../../../../types/user";
import { useNavigate } from "react-router-dom";
import { selectedActivityIdAtom } from "../../../../state/atoms/activities";
import { Activity } from "../../../../types/activities/activity";
import { AppRoutes } from "../../../../services/consts";
import { useGetUser } from "../../../../services/users";

type UpsertMeetingModalProps = React.HTMLAttributes<HTMLDivElement>;

type UpsertMeetingFormProps = React.HTMLAttributes<HTMLDivElement>;

export const getInitialUpsertMeetingFormValues = (
  team: Team,
  user: AppUser,
  meeting?: Meeting | null,
  activityId?: string | null
): Meeting => ({
  id: meeting?.id || "",
  teamId: team.id,
  hostId: user.uid,
  name: meeting?.name || "",
  activityIds: activityId ? [activityId] : meeting?.activityIds || [],
  description: meeting?.description || "",
  plannedStartTimestamp:
    meeting?.plannedStartTimestamp ||
    Timestamp.now().seconds * SECONDS + 1 * HOURS,
  plannedEndTimestamp:
    meeting?.plannedEndTimestamp ||
    Timestamp.fromMillis(Timestamp.now().seconds * SECONDS + 2 * HOURS)
      .seconds * SECONDS,
  status: meeting?.status || MeetingStatus.SCHEDULED,
});

export const UpsertMeetingForm: FunctionComponent<
  UpsertMeetingFormProps
> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const [isUpsertMeetingModalOpen, setIsUpsertMeetingModalOpen] =
    useRecoilState(isUpsertMeetingModalOpenAtom);
  const selectedActivityId = useRecoilValue(selectedActivityIdAtom);
  const [upsertMeetingFormValues, setUpsertMeetingFormValues] = useRecoilState(
    upsertMeetingFormValuesAtom
  );
  const canUpsertMeeting = useRecoilValue(canUpsertMeetingAtom);

  const setNotificationDetails = useSetRecoilState(notificationsAtom);

  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);

  useEffect(() => {
    logger.debug("CreatingMeetingModal.tsx, useEffect#1 fired");
    if (isUpsertMeetingModalOpen && signedInUser && contextTeam) {
      setUpsertMeetingFormValues(
        getInitialUpsertMeetingFormValues(
          contextTeam,
          signedInUser,
          upsertMeetingFormValues,
          selectedActivityId
        )
      );
    }
    // eslint-disable-next-line
  }, [
    isUpsertMeetingModalOpen,
    signedInUser,
    contextTeam,
    selectedActivityId,
    setUpsertMeetingFormValues,
  ]);

  const { isLoading: isFetchingActivities, data: activities } =
    useGetActivities(contextTeam?.id);

  const { isLoading: isFetchingPublicActivities, data: publicActivities } =
    useGetPublicActivities();

  const allActivities: Activity[] = [
    ...(activities || []),
    ...(publicActivities || []),
  ];

  const { mutateAsync: handleSaveMeeting, isLoading: isSaving } =
    useSaveMeeting();

  const navigate = useNavigate();

  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!signedInUser || !upsertMeetingFormValues || !canUpsertMeeting) return;
    if (!upsertMeetingFormValues.activityIds?.length) {
      setNotificationDetails((prevState) => [
        ...prevState,
        {
          header: "Missing required fields",
          body: "Please choose an activity",
          type: NotificationType.Danger,
        },
      ]);
      return;
    }
    await handleSaveMeeting(upsertMeetingFormValues);
    setUpsertMeetingFormValues(null);
    setIsUpsertMeetingModalOpen(false);
    navigate(`${AppRoutes.MGMT_APP}${AppRoutes.MEETINGS}`);
  };

  const isLoading =
    isFetchingActivities ||
    isFetchingPublicActivities ||
    isSaving ||
    isLoadingUser;

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return upsertMeetingFormValues ? (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div>
          Title
          <InputText
            className="mt-1 mb-3"
            value={upsertMeetingFormValues.name}
            disabled={isLoading}
            onChange={(evt) =>
              setUpsertMeetingFormValues({
                ...upsertMeetingFormValues,
                name: evt.target.value,
              })
            }
          />
        </div>
        <div>
          Activity
          <InputSelect
            className="mt-1 mb-3"
            disabled={isLoading}
            value={upsertMeetingFormValues.activityIds?.[0] || ""}
            onChange={(evt) => {
              const activityId = evt.target.value;
              const selectedActivity = allActivities.find(
                (activity) => activity.id === activityId
              );
              if (!selectedActivity) return;
              setUpsertMeetingFormValues({
                ...upsertMeetingFormValues,
                activityIds: evt.target.value ? [evt.target.value] : [],
              });
            }}
          >
            <option value="">Select an activity</option>
            {allActivities?.map((activity) => (
              <option
                key={`activity-${activity.id}`}
                value={activity.id}
                title={activity.name}
                className={activity.name ? "" : "text-muted fst-italic"}
              >
                {activity.name || "Untitled activity"}
              </option>
            ))}
          </InputSelect>
        </div>
        <DateTimeRangePicker
          startTime={upsertMeetingFormValues.plannedStartTimestamp}
          endTime={upsertMeetingFormValues.plannedEndTimestamp}
          onChange={(startTime: number, endTime: number) => {
            setUpsertMeetingFormValues({
              ...upsertMeetingFormValues,
              plannedStartTimestamp: startTime,
              plannedEndTimestamp: endTime,
            });
          }}
          disabled={isLoading}
        />
        <div>
          <Button
            className="float-end"
            disabled={!canUpsertMeeting || isLoading}
            type="submit"
          >
            {isSaving ? (
              <Loading />
            ) : (
              <span>{upsertMeetingFormValues.id ? "Save" : "Create"}</span>
            )}
          </Button>
        </div>
      </form>
    </>
  ) : (
    <></>
  );
};

export const UpsertMeetingModal: FunctionComponent<
  UpsertMeetingModalProps
> = () => {
  const [upsertMeetingFormValues, setUpsertMeetingFormValues] = useRecoilState(
    upsertMeetingFormValuesAtom
  );
  const setSelectedActivityId = useSetRecoilState(selectedActivityIdAtom);
  const [isUpsertMeetingModalOpen, setIsUpsertMeetingModalOpen] =
    useRecoilState(isUpsertMeetingModalOpenAtom);

  const onClose = () => {
    setIsUpsertMeetingModalOpen(false);
    setUpsertMeetingFormValues(null);
    setSelectedActivityId(null);
  };

  const isEditing = !!upsertMeetingFormValues?.id;
  return (
    <Modal
      preventCloseOnBackdropClick
      isOpen={isUpsertMeetingModalOpen}
      header={<h3>{isEditing ? "Edit meeting" : "New meeting"}</h3>}
      body={<UpsertMeetingForm />}
      onClose={() => onClose()}
    />
  );
};
