import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useGetActivityById } from "../../services/activities";
import {
  useMarkQuestionAsAnswered,
  useMarkSystemMessageAsSeen,
} from "../../services/meetings";
import { useGetActivityTemplateById } from "../../services/activity-templates";
import {
  contextMeetingAtom,
  contextMeetingMetadataAtom,
} from "../../state/atoms/meetings";
import {
  isCongratulationsModalOpenAtom,
  isGreetingModalOpenAtom,
  isQuestModalOpenAtom,
} from "../../state/atoms/ui";

import { Loading } from "../Loading/Loading";
import { QuestionModal } from "../QuestModal/QuestionModal";

import "./ActivityPlayer.css";
import { ActivityMessageModal } from "../ActivityMessageModal/ActivityMessageModal";
import { chosenElementIdxAtom } from "../../state/atoms/activities";
import { QuestRoomActivityPlayer } from "./QuestRoomActivityPlayer";
import {
  ActivityMessageModalTypes,
  ActivityType,
} from "../../types/activities/activity";
import { QuestRoomInteractiveElement } from "../../types/activities/quest-room";
import { FreeMapInteractiveElement } from "../../types/activities/free-map";
import { FreeMapActivityPlayer } from "./FreeMapActivityPlayer";
import { signedInUserIdAtom } from "../../state/atoms/auth";
import { useGetUser } from "../../services/users";
import { SystemMessagesType } from "../../types/meeting";

interface ActivityPlayerProps extends React.HTMLAttributes<HTMLDivElement> {
  activityId: string;
}

export const ActivityPlayer: FunctionComponent<ActivityPlayerProps> = ({
  className,
  activityId,
}) => {
  const setIsQuestModalOpen = useSetRecoilState(isQuestModalOpenAtom);
  const setIsCongratulationsModalOpen = useSetRecoilState(
    isCongratulationsModalOpenAtom
  );
  const setIsGreetingModalOpen = useSetRecoilState(isGreetingModalOpenAtom);
  const contextMeeting = useRecoilValue(contextMeetingAtom);
  const contextMeetingMetadata = useRecoilValue(contextMeetingMetadataAtom);
  const chosenElementIdx = useRecoilValue(chosenElementIdxAtom);
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const { data: signedInUser, isLoading: isLoadingUser } =
    useGetUser(signedInUserId);

  const [isAllDone, setIsAllDone] = useState(
    contextMeetingMetadata?.metadata?.[activityId]?.puzzlesState?.every(
      ({ isSolved }) => isSolved
    ) || false
  );

  const {
    data: activity,
    isFetching: isFetchingActivity,
    refetch: refetchActivity,
  } = useGetActivityById(activityId);

  const {
    data: activityTemplate,
    isFetching: isFetchingActivityTemplate,
    refetch: refetchActivityTemplate,
  } = useGetActivityTemplateById(activity?.templateId || "");

  const { mutateAsync: markGreetingModalStatusAsSeen } =
    useMarkSystemMessageAsSeen(
      signedInUserId,
      SystemMessagesType.GREETING,
      contextMeeting?.id,
      activityId
    );

  const { mutateAsync: markSuccessModalStatusAsSeen } =
    useMarkSystemMessageAsSeen(
      signedInUserId,
      SystemMessagesType.SUCCESS,
      contextMeeting?.id,
      activityId
    );

  const { mutateAsync: handleMarkQuestionAsAnswered } =
    useMarkQuestionAsAnswered(contextMeeting?.id);

  const isEmptyGreetingMessage =
    !activity?.greetingMessage.imgSrc && !activity?.greetingMessage.text;

  const hasSeenGreetingModal = contextMeetingMetadata?.metadata[
    activityId
  ]?.systemMessages?.some(
    (systemMessage) =>
      systemMessage.uid === signedInUserId && systemMessage.hasSeenGreetingModal
  );

  const hasSeenSuccessModal = contextMeetingMetadata?.metadata[
    activityId
  ]?.systemMessages?.some(
    (systemMessage) =>
      systemMessage.uid === signedInUserId && systemMessage.hasSeenSuccessModal
  );

  useEffect(() => {
    if (activityId) {
      refetchActivity();
    }
    if (activity) {
      refetchActivityTemplate();
    }
  }, [activityId, activity, refetchActivity, refetchActivityTemplate]);

  useEffect(() => {
    if (!contextMeetingMetadata || !activityId || !signedInUser) return;

    const puzzlesState =
      contextMeetingMetadata.metadata?.[activityId]?.puzzlesState;
    let currPuzzleState;

    if (
      puzzlesState &&
      chosenElementIdx !== null &&
      chosenElementIdx !== undefined
    ) {
      currPuzzleState = puzzlesState[chosenElementIdx];
    }

    if (!currPuzzleState) return;

    if (
      currPuzzleState.isSolved &&
      currPuzzleState.solvedBy?.uid !== signedInUser.uid
    ) {
      setIsQuestModalOpen(false);
    }
  }, [
    contextMeetingMetadata,
    signedInUser,
    activityId,
    chosenElementIdx,
    setIsQuestModalOpen,
  ]);

  useEffect(() => {
    if (activity && !isEmptyGreetingMessage && !hasSeenGreetingModal) {
      setIsGreetingModalOpen(true);
      markGreetingModalStatusAsSeen();
    }
  }, [
    activity,
    isEmptyGreetingMessage,
    activityId,
    hasSeenGreetingModal,
    markGreetingModalStatusAsSeen,
    setIsGreetingModalOpen,
  ]);

  useEffect(() => {
    if (
      !contextMeetingMetadata ||
      !contextMeeting?.id ||
      !activity ||
      hasSeenSuccessModal
    )
      return;

    const isEmptyCongratulationsMessage =
      !activity.congratulationsMessage.imgSrc &&
      !activity.congratulationsMessage.text;
    if (isEmptyCongratulationsMessage) return;

    const puzzleStateArray =
      contextMeetingMetadata.metadata?.[activityId]?.puzzlesState || [];
    const isAllDone =
      puzzleStateArray.length > 0 &&
      puzzleStateArray.every(({ isSolved }) => isSolved);
    setIsAllDone(isAllDone);
    if (!isAllDone) return;

    setIsQuestModalOpen(false);
    setIsCongratulationsModalOpen(true);
    markSuccessModalStatusAsSeen();
  }, [
    contextMeetingMetadata,
    contextMeeting,
    activity,
    activityId,
    hasSeenSuccessModal,
    markSuccessModalStatusAsSeen,
    setIsQuestModalOpen,
    setIsCongratulationsModalOpen,
  ]);

  const handleCorrectAnswer = async () => {
    if (
      !contextMeeting?.id ||
      (!chosenElementIdx && chosenElementIdx !== 0) ||
      !signedInUser?.uid
    ) {
      return;
    }

    await handleMarkQuestionAsAnswered({
      activityId,
      questionIdx: chosenElementIdx,
      uid: signedInUser.uid,
    });
  };

  const firstView = activityTemplate?.views?.[0];

  const isLoading =
    !activityTemplate ||
    !activity ||
    isFetchingActivityTemplate ||
    isFetchingActivity ||
    !firstView ||
    isLoadingUser;

  if (isLoading)
    return (
      <Loading className="position-absolute top-50 start-50 translate-middle" />
    );

  const { backgroundImage, interactiveElements } = firstView;

  const getActivityPlayer = () => {
    switch (activityTemplate.type) {
      case ActivityType.FREE_MAP:
        return (
          <FreeMapActivityPlayer
            activity={activity}
            backgroundImage={backgroundImage}
            interactiveElements={
              interactiveElements as FreeMapInteractiveElement[]
            }
          />
        );
      case ActivityType.QUEST_ROOM:
      default:
        return (
          <QuestRoomActivityPlayer
            activity={activity}
            backgroundImage={backgroundImage}
            interactiveElements={
              interactiveElements as QuestRoomInteractiveElement[]
            }
          />
        );
    }
  };

  return (
    <div className={`activity-player ${className || ""}`}>
      {getActivityPlayer()}
      <QuestionModal
        puzzle={activity?.puzzles[chosenElementIdx || 0]}
        onSolveQuestion={handleCorrectAnswer}
      />
      <ActivityMessageModal
        messageData={
          !isAllDone
            ? activity.greetingMessage
            : activity.congratulationsMessage
        }
        type={
          !isAllDone
            ? ActivityMessageModalTypes.GREETING_MESSAGE
            : ActivityMessageModalTypes.CONGRATULATIONS_MESSAGE
        }
      />
    </div>
  );
};
