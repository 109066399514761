import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "../../components/Input/InputText";
import { useGetMeetingById } from "../../services/meetings";
import { signUserOut } from "../../services/firebase";

export const JoinMeeting: FunctionComponent = () => {
  const navigate = useNavigate();
  const [meetingId, setMeetingId] = useState("");

  const {
    data: meeting,
    isFetching: isFetchingMeeting,
    refetch: refetchMeeting,
  } = useGetMeetingById(meetingId);

  useEffect(() => {
    if (!meeting) return;
    navigate(meetingId);
  }, [meeting, navigate, meetingId]);

  return (
    <>
      <div className="container vh-100 d-flex">
        <div className="row w-100 vh-25 m-0 align-self-center justify-content-center">
          <form
            className="form d-flex flex-column w-50"
            onSubmit={async (e) => {
              e.preventDefault();
              if (!meetingId) return;
              await refetchMeeting();
            }}
          >
            <div>
              <span>Enter meeting ID</span>
              <InputText
                placeholder="Meeting ID"
                disabled={isFetchingMeeting}
                onChange={(e) => setMeetingId(e.target.value)}
                className="form-control text-truncate mt-1 mb-3"
              />
            </div>
            <button className="btn btn-primary" disabled={isFetchingMeeting}>
              Join
            </button>
            <div
              className="mt-4 btn btn-link align-self-center"
              onClick={signUserOut}
            >
              Logout
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
