import { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "../../../../components/Modal/Modal";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { isUpsertActivityTemplateModalOpenAtom } from "../../../../state/atoms/ui";
import { signedInUserIdAtom } from "../../../../state/atoms/auth";
import {
  upsertActivityTemplateFormValuesAtom,
  activityTemplateBackgroundImageAtom,
  questRoomInteractiveElementSolvedImagesAtom,
  questRoomInteractiveElementUnsolvedImagesAtom,
} from "../../../../state/atoms/templates";
import { InputText } from "../../../../components/Input/InputText";
import { Button } from "../../../../components/Button/Button";
import {
  ActivityType,
  PreuploadImageFile,
} from "../../../../types/activities/activity";
import {
  InputSelect,
  InputSelectOption,
} from "../../../../components/Input/InputSelect";
import { InputFile } from "../../../../components/Input/InputFile";
import { FileTypes } from "../../../../types/input";
import { Map } from "../../../../components/Map/Map";
import placeholder from "../../../../assets/images/background-placeholder.png";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { InteractiveElementInUpsertForm } from "../Activities/UpsertActivityModal";
import { ToggleButtonGroup } from "../../../../components/Button/ToggleButtonGroup";
import {
  OverlayButtons,
  SelectionCoordinates,
} from "../../../../components/OverlayButtons/OverlayButtons";
import {
  ActivityTemplate,
  InteractiveElement,
  InteractiveElementType,
} from "../../../../types/activities/common";
import { getRandomColor } from "../../../../utils/colors";
import { QuestRoomInteractiveElement } from "../../../../types/activities/quest-room";
import { useSaveActivityTemplate } from "../../../../services/activity-templates";
import Tippy from "@tippyjs/react";
import { Loading } from "../../../../components/Loading/Loading";
import { InputSwitch } from "../../../../components/Input/InputSwitch";
import dice1 from "../../../../assets/images/free-map-images/dice/1.png";
import dice2 from "../../../../assets/images/free-map-images/dice/2.png";
import dice3 from "../../../../assets/images/free-map-images/dice/3.png";
import dice4 from "../../../../assets/images/free-map-images/dice/4.png";
import dice5 from "../../../../assets/images/free-map-images/dice/5.png";
import dice6 from "../../../../assets/images/free-map-images/dice/6.png";
import cardsE from "../../../../assets/images/free-map-images/deck/cards-e.png";
import cardsQ from "../../../../assets/images/free-map-images/deck/cards-q.png";
import { DBDocument } from "../../../../types/document";
import { useGetUser } from "../../../../services/users";

type UpsertActivityTemplateModalProps = React.HTMLAttributes<HTMLDivElement>;
type UpsertActivityTemplateFormProps = React.HTMLAttributes<HTMLDivElement>;

const activityTypeOptions: InputSelectOption[] = [
  { value: ActivityType.QUEST_ROOM, label: "Quest Room" },
  { value: ActivityType.FREE_MAP, label: "Free Map" },
];

const FreeMapElementTypeOptions: InputSelectOption[] = [
  { value: InteractiveElementType.Deck, label: "Deck" },
  { value: InteractiveElementType.Dice, label: "Dice" },
];

export const UpsertActivityTemplateForm: FunctionComponent<
  UpsertActivityTemplateFormProps
> = () => {
  const [
    upsertActivityTemplateFormValues,
    setUpsertActivityTemplateFormValues,
  ] = useRecoilState(upsertActivityTemplateFormValuesAtom);

  const [activityTemplateBackgroundImage, setActivityTemplateBackgroundImage] =
    useRecoilState(activityTemplateBackgroundImageAtom);

  const [backgroundImage, setBackgroundImage] = useState<string>(
    upsertActivityTemplateFormValues?.views[0]?.backgroundImage || ""
  );

  const [isShowSolved, setIsShowSolved] = useState(false);
  const { interactiveElements } =
    upsertActivityTemplateFormValues?.views[0] || {};
  const isFreeMap =
    upsertActivityTemplateFormValues?.type === ActivityType.FREE_MAP;
  const setIsUpsertActivityTemplateModalOpen = useSetRecoilState(
    isUpsertActivityTemplateModalOpenAtom
  );
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const { data: signedInUser } = useGetUser(signedInUserId);
  const isDisabled = !!isFreeMap || !interactiveElements?.length;
  const isEditing = !!upsertActivityTemplateFormValues?.id;
  const [areAllElementsUploaded, setAreAllElementsUploaded] =
    useState<boolean>(true);
  const isSubmitButtonDisabled = !areAllElementsUploaded || !backgroundImage;
  const [questRoomSolvedImages, setQuestRoomSolvedImages] = useRecoilState(
    questRoomInteractiveElementSolvedImagesAtom
  );
  const [questRoomUnsolvedImages, setQuestRoomUnsolvedImages] = useRecoilState(
    questRoomInteractiveElementUnsolvedImagesAtom
  );
  const [selectedElementType, setSelectedElementType] =
    useState<InteractiveElementType>(InteractiveElementType.Deck);
  const [isFreeMapUploaded, setIsFreeMapUploaded] = useState<boolean>(false);
  const handleButtonClick = (value: string): void => {
    setIsShowSolved(value === "solved");
  };
  const {
    mutateAsync: saveActivityTemplate,
    isLoading: isSavingActivityTemplate,
  } = useSaveActivityTemplate();

  const handleDeleteElement = (indexToDelete: number) => {
    if (upsertActivityTemplateFormValues) {
      const updatedTemplate: DBDocument<ActivityTemplate> = {
        ...upsertActivityTemplateFormValues,
        views: upsertActivityTemplateFormValues.views.map((view) => ({
          ...view,
          interactiveElements: view.interactiveElements.filter(
            (_, index) => index !== indexToDelete
          ),
        })),
      };

      setUpsertActivityTemplateFormValues(updatedTemplate);
    }
  };

  const handleUploadElementImage = (
    file: File,
    index: number,
    isSolved: boolean
  ) => {
    if (upsertActivityTemplateFormValues) {
      const updatedTemplate: DBDocument<ActivityTemplate> = {
        ...upsertActivityTemplateFormValues,
        views: upsertActivityTemplateFormValues.views.map((view) => ({
          ...view,
          interactiveElements: view.interactiveElements.map((element, idx) => {
            if (idx === index) {
              if (isSolved) {
                return {
                  ...element,
                  imageSolved: URL.createObjectURL(file),
                };
              } else {
                return {
                  ...element,
                  imageUnsolved: URL.createObjectURL(file),
                };
              }
            }
            return element;
          }),
        })),
      };

      setUpsertActivityTemplateFormValues(updatedTemplate);
    }
  };

  const handleUploadBackgroundImage = async (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ev.target.files) {
      const files = Array.from(ev.target.files);
      if (files.length === 0) return;

      setUpsertActivityTemplateFormValues(
        (prevValues: DBDocument<ActivityTemplate> | null) => {
          if (!prevValues) return null;
          const updatedViews = [
            {
              backgroundImage: URL.createObjectURL(files[0]),
              interactiveElements:
                prevValues?.views[0]?.interactiveElements || [],
            },
          ];
          return {
            ...prevValues,
            views: updatedViews,
          };
        }
      );
      setBackgroundImage(URL.createObjectURL(files[0]));
      setActivityTemplateBackgroundImage({
        elementIdx: 0,
        file: files[0],
      });
    }
  };

  const saveInteractiveElement = (
    newInteractiveElement: InteractiveElement
  ) => {
    if (upsertActivityTemplateFormValues) {
      const updatedValues = { ...upsertActivityTemplateFormValues };
      const view = updatedValues.views[0];
      if (view && view.interactiveElements) {
        const updatedInteractiveElements = [
          ...view.interactiveElements,
          newInteractiveElement,
        ];
        const updatedView = {
          ...view,
          interactiveElements: updatedInteractiveElements,
        };
        const updatedValuesWithView = {
          ...updatedValues,
          views: updatedValues.views.map((v, index) =>
            index === 0 ? updatedView : v
          ),
        };
        setUpsertActivityTemplateFormValues(updatedValuesWithView);
      }
    }
  };

  const onSelectionChange = (selection: SelectionCoordinates) => {
    if (upsertActivityTemplateFormValues) {
      if (isFreeMap) {
        const diceImages = [dice1, dice2, dice3, dice4, dice5, dice6];
        const cardsImages = [cardsQ, cardsE];
        const images =
          selectedElementType === InteractiveElementType.Dice
            ? diceImages.map((imageData) => ({
                image: imageData,
              }))
            : cardsImages.map((imageData) => ({
                image: imageData,
                type: imageData.includes("cards-e") ? "action" : "question",
              }));

        const newFreeMapInteractiveElement = {
          type: selectedElementType,
          rectangle: {
            startX: selection.startX,
            startY: selection.startY,
            endX: selection.endX,
            endY: selection.endY,
          },
          images,
        };
        saveInteractiveElement(newFreeMapInteractiveElement);
      } else {
        const newQuestRoomInteractiveElement = {
          type: InteractiveElementType.Puzzle,
          rectangle: {
            startX: selection.startX,
            startY: selection.startY,
            endX: selection.endX,
            endY: selection.endY,
          },
        };
        saveInteractiveElement(newQuestRoomInteractiveElement);
      }
    }
  };

  const onSaveActivityTemplate = async (
    evt?: React.FormEvent<HTMLFormElement>
  ) => {
    evt?.preventDefault();
    const backgroundImages: PreuploadImageFile[] | null =
      activityTemplateBackgroundImage
        ? [activityTemplateBackgroundImage]
        : null;

    await saveActivityTemplate({
      template: upsertActivityTemplateFormValues,
      backgroundImages,
      solvedImages: [questRoomSolvedImages],
      unsolvedImages: [questRoomUnsolvedImages],
      user: signedInUser || null,
    });
    setUpsertActivityTemplateFormValues(null);
    setActivityTemplateBackgroundImage(null);
    setQuestRoomSolvedImages([]);
    setQuestRoomUnsolvedImages([]);
    setIsUpsertActivityTemplateModalOpen(false);
    setIsFreeMapUploaded(false);
    setSelectedElementType(InteractiveElementType.Deck);
  };

  useEffect(() => {
    if (!upsertActivityTemplateFormValues)
      setUpsertActivityTemplateFormValues({
        id: "",
        name: "",
        description: "",
        type: ActivityType.QUEST_ROOM,
        complexity: 0,
        views: [],
        isDeleted: false,
        createdBy: "",
        updatedBy: "",
        isPublished: true,
      });

    const isIncompleteUpload = interactiveElements?.some((element) => {
      if (!isFreeMap) {
        const currQuestElement = element as QuestRoomInteractiveElement;
        return !(
          currQuestElement.imageSolved && currQuestElement.imageUnsolved
        );
      }
      return false;
    });
    setAreAllElementsUploaded(!isIncompleteUpload);

    if (interactiveElements && isFreeMap) {
      const deckPresent = interactiveElements.some(
        (element) => element.type === InteractiveElementType.Deck
      );
      const dicePresent = interactiveElements.some(
        (element) => element.type === InteractiveElementType.Dice
      );
      setSelectedElementType(
        deckPresent && !dicePresent
          ? InteractiveElementType.Dice
          : !deckPresent && dicePresent
            ? InteractiveElementType.Deck
            : selectedElementType
      );
      setIsFreeMapUploaded(deckPresent && dicePresent);
    }
  }, [
    upsertActivityTemplateFormValues,
    interactiveElements,
    isFreeMap,
    selectedElementType,
    setUpsertActivityTemplateFormValues,
  ]);

  return upsertActivityTemplateFormValues ? (
    <form className="form" onSubmit={onSaveActivityTemplate}>
      <div>
        Name
        <InputText
          className="mt-1 mb-3"
          value={upsertActivityTemplateFormValues.name}
          disabled={isSavingActivityTemplate}
          onChange={(evt) =>
            setUpsertActivityTemplateFormValues({
              ...upsertActivityTemplateFormValues,
              name: evt.target.value,
            })
          }
        />
      </div>
      <div>
        Type
        <InputSelect
          className="mb-3"
          disabled={
            !!upsertActivityTemplateFormValues.id || isSavingActivityTemplate
          }
          value={upsertActivityTemplateFormValues.type}
          onChange={(evt) => {
            const selectedType = evt.target.value as ActivityType;
            setUpsertActivityTemplateFormValues({
              ...upsertActivityTemplateFormValues,
              type: selectedType,
            });
          }}
        >
          {activityTypeOptions.map(({ value, label }, idx) => (
            <option key={`activity-type-option-${idx}`} value={value}>
              {label}
            </option>
          ))}
        </InputSelect>
      </div>
      <div className="d-flex gap-1 mb-2">
        <ToggleButtonGroup
          isDisabled={isDisabled || isSavingActivityTemplate}
          buttons={[
            {
              value: "unsolved",
              children: "Unsolved",
              tooltipProps: {
                content: "There are no solvable elements to show",
              },
            },
            {
              value: "solved",
              children: "Solved",
              tooltipProps: {
                content: "There are no solvable elements to show",
              },
            },
          ]}
          onChange={handleButtonClick}
        />
      </div>
      {isFreeMap && (
        <div>
          <div className="d-flex gap-1">
            Element type
            <Tippy content="Deck: 2x3 area; Dice: 2x2 area">
              <div>
                <Icon icon={Icons.INFO} />
              </div>
            </Tippy>
          </div>
          <InputSelect
            className="mb-3"
            disabled={isFreeMapUploaded || isSavingActivityTemplate}
            value={selectedElementType}
            onChange={(evt) => {
              const selectedType = evt.target.value as InteractiveElementType;
              setSelectedElementType(selectedType);
            }}
          >
            {FreeMapElementTypeOptions.map(({ value, label }, idx) => {
              const isOptionTypeUploaded = interactiveElements?.some(
                (element) => element.type === value
              );
              return (
                <option
                  key={`free-map-interactive-element-type-option-${idx}`}
                  value={value}
                  disabled={isOptionTypeUploaded}
                >
                  {label}
                </option>
              );
            })}
          </InputSelect>
        </div>
      )}
      <div className="position-relative w-75 mb-2 d-flex flex-column">
        <Map className="mb-1" backgroundImage={backgroundImage || placeholder}>
          {backgroundImage &&
            interactiveElements?.map((element, index) => {
              const { imageSolved, imageUnsolved } =
                element as QuestRoomInteractiveElement;

              return (
                <InteractiveElementInUpsertForm
                  key={`interactive-element-${index}`}
                  activityType={upsertActivityTemplateFormValues.type}
                  element={element}
                  index={index}
                  showSolved={isShowSolved}
                  style={{
                    outline: `4px ${getRandomColor({ luminosity: "light" })} dashed`,
                    outlineOffset: "-2px",
                  }}
                >
                  <div
                    className="position-absolute d-flex align-items-center rounded z-2 p-05"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.75)",
                    }}
                  >
                    {upsertActivityTemplateFormValues.type ===
                      ActivityType.QUEST_ROOM && (
                      <div>
                        <InputFile
                          src=""
                          acceptFile={[FileTypes.IMAGE]}
                          disabled={isSavingActivityTemplate}
                          onChange={(ev) => {
                            if (ev.target.files) {
                              const files = Array.from(ev.target.files);
                              if (files.length === 0) return;
                              handleUploadElementImage(files[0], index, false);
                              setQuestRoomUnsolvedImages([
                                ...questRoomUnsolvedImages,
                                { elementIdx: index, file: files[0] },
                              ]);
                            }
                          }}
                        >
                          <div
                            className={`pointer text-${imageUnsolved ? "success" : "secondary"}`}
                            style={{
                              lineHeight: "1",
                            }}
                          >
                            <Icon icon={Icons.UNSOLVED} />
                          </div>
                        </InputFile>
                        <InputFile
                          src=""
                          acceptFile={[FileTypes.IMAGE]}
                          disabled={isSavingActivityTemplate}
                          onChange={(ev) => {
                            if (ev.target.files) {
                              const files = Array.from(ev.target.files);
                              if (files.length === 0) return;
                              handleUploadElementImage(files[0], index, true);
                              setQuestRoomSolvedImages([
                                ...questRoomSolvedImages,
                                { elementIdx: index, file: files[0] },
                              ]);
                            }
                          }}
                        >
                          <div
                            className={`pointer text-${imageSolved ? "success" : "secondary"}`}
                            style={{
                              lineHeight: "1",
                            }}
                          >
                            <Icon icon={Icons.SOLVED} />
                          </div>
                        </InputFile>
                      </div>
                    )}
                    <div
                      className="pointer text-danger"
                      style={{
                        lineHeight: "1",
                      }}
                      onClick={() => handleDeleteElement(index)}
                    >
                      <Icon icon={Icons.DELETE} />
                    </div>
                  </div>
                </InteractiveElementInUpsertForm>
              );
            })}
          <OverlayButtons onSelectionChange={onSelectionChange} />
        </Map>
      </div>
      <div className="mb-3">
        {backgroundImage ? (
          <Button
            btnType="danger"
            btnSize="sm"
            onClick={() => setBackgroundImage("")}
            disabled={isSavingActivityTemplate}
          >
            <Icon icon={Icons.DELETE} />
          </Button>
        ) : (
          <InputFile
            src={backgroundImage}
            acceptFile={[FileTypes.IMAGE]}
            disabled={isSavingActivityTemplate}
            onChange={async (ev) => {
              handleUploadBackgroundImage(ev);
            }}
          >
            <p className="btn btn-outline-secondary">Upload background image</p>
          </InputFile>
        )}
      </div>
      <div className="d-flex gap-4 justify-content-end align-items-center">
        <InputSwitch
          id="publishCheckbox"
          checked={upsertActivityTemplateFormValues?.isPublished}
          onChange={(event) => {
            setUpsertActivityTemplateFormValues({
              ...upsertActivityTemplateFormValues,
              isPublished: event.target.checked,
            });
          }}
        >
          Publish
        </InputSwitch>
        <Button
          type="submit"
          disabled={isSubmitButtonDisabled || isSavingActivityTemplate}
        >
          {isSavingActivityTemplate ? (
            <Loading />
          ) : (
            <span>{isEditing ? "Save" : "Create"}</span>
          )}
        </Button>
      </div>
    </form>
  ) : (
    <></>
  );
};

export const UpsertActivityTemplateModal: FunctionComponent<
  UpsertActivityTemplateModalProps
> = () => {
  const [
    upsertActivityTemplateFormValues,
    setUpsertActivityTemplateFormValues,
  ] = useRecoilState(upsertActivityTemplateFormValuesAtom);
  const isEditing = !!upsertActivityTemplateFormValues?.id;
  const [
    isUpsertActivityTemplateModalOpen,
    setIsUpsertActivityTemplateModalOpen,
  ] = useRecoilState(isUpsertActivityTemplateModalOpenAtom);

  return (
    <Modal
      preventCloseOnBackdropClick
      isOpen={isUpsertActivityTemplateModalOpen}
      header={
        <h3>
          {isEditing ? "Edit activity template" : "New activity template"}
        </h3>
      }
      body={<UpsertActivityTemplateForm />}
      onClose={() => {
        setIsUpsertActivityTemplateModalOpen(false);
        setUpsertActivityTemplateFormValues(null);
      }}
    />
  );
};
