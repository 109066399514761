import React, { FunctionComponent } from "react";

type InputSwitchProps = React.InputHTMLAttributes<HTMLInputElement>;

export const InputSwitch: FunctionComponent<InputSwitchProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <div className={`form-check form-switch ${className}`}>
      <label className="form-check-label">
        <input className="form-check-input" type="checkbox" {...restProps} />
        {children}
      </label>
    </div>
  );
};
