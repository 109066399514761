import { SECONDS } from "./time";

export const INFO_TOAST_SHOW_TIME = 5 * SECONDS;
export const ERROR_TOAST_SHOW_TIME = 10 * SECONDS;
export const ERROR_TOAST_DEFAULT_BODY =
  "Please try refreshing the page or contact us for help";

export type SignUpModalData = {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

export type SignInModalData = {
  email: string;
  password: string;
};
