import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { Activity, PreuploadImageFile } from "../types/activities/activity";
import {
  saveActivityRequest,
  deleteActivityRequest,
  getActivitiesRequest,
  getActivityByIdRequest,
  getPublicActivitiesRequest,
} from "./firebase";
import { logger } from "./logger";
import { queryClient } from "..";
import { StatePrimaryKeys } from "./consts";
import { DBDocument } from "../types/document";

export const useGetActivities = (
  teamId?: string,
  options?: UseQueryOptions<DBDocument<Activity>[], Error>
) =>
  useQuery<DBDocument<Activity>[], Error>(
    [StatePrimaryKeys.Activities],
    () => getActivitiesRequest(teamId),
    {
      onError: (error) => {
        logger.error("Error in fetching activities", error);
      },
      ...options,
    }
  );

export const useSaveActivity = (
  teamId: string,
  options?: UseMutationOptions<
    { activityId: string },
    Error,
    {
      newActivityData: Activity | null;
      imagesList: PreuploadImageFile[];
      congratulationsImage: PreuploadImageFile | null;
      greetingImage: PreuploadImageFile | null;
    }
  >
) =>
  useMutation<
    { activityId: string },
    Error,
    {
      newActivityData: Activity;
      imagesList: PreuploadImageFile[];
      isForcedChange?: boolean;
      congratulationsImage: PreuploadImageFile | null;
      greetingImage: PreuploadImageFile | null;
    }
  >(
    [StatePrimaryKeys.Activities],
    (activityData) => {
      if (!teamId) throw new Error("No team id was provided");
      const {
        newActivityData,
        imagesList,
        isForcedChange = false,
        congratulationsImage,
        greetingImage,
      } = activityData;
      return saveActivityRequest(
        teamId,
        newActivityData,
        imagesList,
        isForcedChange,
        congratulationsImage,
        greetingImage
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([StatePrimaryKeys.PublicActivities]);
        queryClient.invalidateQueries([StatePrimaryKeys.Activities]);
      },
      onError: (error) => {
        logger.error("Error in creating new activity", error);
      },
      ...options,
    }
  );

export const useDeleteActivity = () =>
  useMutation<{ success: boolean }, Error, { activity: Activity }>(
    [StatePrimaryKeys.Activities],
    (mutationData: { activity: Activity }) => {
      const { activity } = mutationData;
      if (!activity) throw new Error("No activity data was provided");
      return deleteActivityRequest(activity);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([StatePrimaryKeys.PublicActivities]);
        queryClient.invalidateQueries([StatePrimaryKeys.Activities]);
      },
      onError: (error, mutationData) => {
        const { activity } = mutationData;
        logger.error(`Error deleting activity with id ${activity.id}`, error);
      },
    }
  );

export const useGetActivityById = (
  activityId: string,
  options?: UseQueryOptions<DBDocument<Activity>, Error>
) =>
  useQuery<DBDocument<Activity>, Error>(
    [StatePrimaryKeys.Activities, activityId],
    () => getActivityByIdRequest(activityId),
    {
      onError: (error) => {
        logger.error("Error in fetching activity", error);
      },
      enabled: false,
      ...options,
    }
  );

export const useGetPublicActivities = (
  options?: UseQueryOptions<DBDocument<Activity>[], Error>
) =>
  useQuery<DBDocument<Activity>[], Error>(
    [StatePrimaryKeys.PublicActivities],
    getPublicActivitiesRequest,
    {
      onError: (error) => {
        logger.error("Error in fetching public activities", error);
      },
      ...options,
    }
  );
