import React, { FunctionComponent, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  contextTeamAtom,
  signedInUserIdAtom,
} from "../../../../state/atoms/auth";
import {
  isUpsertCourseModalOpenAtom,
  isDeleteCourseDialogOpenAtom,
} from "../../../../state/atoms/ui";
import { Button } from "../../../../components/Button/Button";
import { Card } from "../../../../components/Card/Card";
import { Loading } from "../../../../components/Loading/Loading";
import { notificationsAtom } from "../../../../state/atoms/notifications";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { Icon, Icons } from "../../../../components/Icons/Icon";
import { upsertCourseFormValuesAtom } from "../../../../state/atoms/courses";
import { NotificationType } from "../../../../types/notification";

import { useDeleteCourse, useGetCourses } from "../../../../services/courses";
import { Course } from "../../../../types/course";
import { baseUrl, dateLocaleFormat } from "../../../../services/utils";
import { AppRoutes } from "../../../../services/consts";

type CoursesProps = React.HTMLAttributes<HTMLDivElement>;

export const Courses: FunctionComponent<CoursesProps> = () => {
  const signedInUserId = useRecoilValue(signedInUserIdAtom);
  const contextTeam = useRecoilValue(contextTeamAtom);
  const setNotificationDetails = useSetRecoilState(notificationsAtom);
  const setIsUpsertCourseModalOpen = useSetRecoilState(
    isUpsertCourseModalOpenAtom
  );
  const setUpsertCourseFormValues = useSetRecoilState(
    upsertCourseFormValuesAtom
  );
  const setIsDeleteCourseDialogOpen = useSetRecoilState(
    isDeleteCourseDialogOpenAtom
  );

  const [chosenCourse, setChosenCourse] = useState<Course | null>(null);

  const { isLoading, data: courses } = useGetCourses(contextTeam?.id);

  const { mutateAsync: handleDeleteCourse } = useDeleteCourse();

  if (!signedInUserId || !contextTeam) {
    return <></>;
  }

  return (
    <div>
      <div className="row">
        <div className="col py-0">
          <h4>Courses</h4>
        </div>
        <div className="col py-0">
          <Button
            className="float-end"
            onClick={() => setIsUpsertCourseModalOpen(true)}
          >
            Create
          </Button>
        </div>
      </div>
      <Card className="mt-4 mt-md-2">
        {isLoading ? (
          <Loading />
        ) : courses && courses.length > 0 ? (
          <>
            <div className="row my-2">
              <div className="col-6 col-xl-2 font-weight-bold text-truncate">
                Title
              </div>
              <div className="d-none d-xl-block col-xl-2 font-weight-bold text-truncate">
                Participants
              </div>
              <div className="d-none d-xl-block col-xl-3 font-weight-bold text-truncate">
                Start date
              </div>
              <div className="d-none d-xl-block col-xl-3 font-weight-bold text-truncate">
                End date
              </div>
              <div className="col-6 col-xl-2 font-weight-bold text-truncate">
                Actions
              </div>
            </div>
            {courses.map((course) => {
              const { id, name, startsAt, endsAt, participants } = course;
              const participantCount = Object.keys(participants).length;
              const startDate = new Date(startsAt).toLocaleString(
                dateLocaleFormat
              );
              const endDate = new Date(endsAt).toLocaleString(dateLocaleFormat);

              return (
                <div key={`course-${id}`} className="row my-2">
                  <div className="col-6 col-xl-2 pt-1 align-self-center text-truncate">
                    <div
                      className="link-primary"
                      onClick={() => {
                        setUpsertCourseFormValues(course);
                        setIsUpsertCourseModalOpen(true);
                      }}
                    >
                      {name || <i className="text-muted">Untitled course</i>}
                    </div>
                  </div>
                  <div className="d-none d-xl-block col-xl-2 text-truncate align-self-center">
                    {participantCount}
                  </div>
                  <div className="d-none d-xl-block col-xl-3 pt-1 align-self-center text-truncate">
                    {startDate}
                  </div>
                  <div className="d-none d-xl-block col-xl-3 pt-1 align-self-center text-truncate">
                    {endDate}
                  </div>
                  <div className="col-6 col-xl-2 align-self-center">
                    <Button
                      tooltipProps={{ content: "Copy course URL" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${baseUrl}${AppRoutes.COURSE}/${id}`
                        );
                        setNotificationDetails((prevState) => [
                          ...prevState,
                          {
                            header: "Course URL copied to clipboard",
                            type: NotificationType.Info,
                          },
                        ]);
                      }}
                    >
                      <Icon icon={Icons.COPY} />
                    </Button>
                    <Button
                      tooltipProps={{ content: "Edit" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setUpsertCourseFormValues(course);
                        setIsUpsertCourseModalOpen(true);
                      }}
                    >
                      <Icon icon={Icons.EDIT} />
                    </Button>
                    <Button
                      tooltipProps={{ content: "Delete" }}
                      showTooltip
                      btnSize="sm"
                      btnType="secondary"
                      onClick={() => {
                        setChosenCourse(course);
                        setIsDeleteCourseDialogOpen(true);
                      }}
                    >
                      <Icon icon={Icons.DELETE} />
                    </Button>
                  </div>
                </div>
              );
            })}
            <Dialog
              header="Are you sure you want to delete this course?"
              state={isDeleteCourseDialogOpenAtom}
              onApprove={async () => {
                if (chosenCourse && chosenCourse.id) {
                  await handleDeleteCourse({ courseId: chosenCourse.id });
                }
              }}
              approveText="Delete"
              cancelText="Cancel"
              dangerous
            />
          </>
        ) : (
          <span className="text-muted text-center">
            <i>Click</i>
            <Button
              className="mx-2"
              onClick={() => setIsUpsertCourseModalOpen(true)}
              btnSize="sm"
            >
              Create
            </Button>
            <i>to create an course</i>
          </span>
        )}
      </Card>
    </div>
  );
};
