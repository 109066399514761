import { FunctionComponent } from "react";
import { FaGripVertical, FaRegMap } from "react-icons/fa";
import {
  RiAddLine,
  RiExternalLinkLine,
  RiCalendarTodoLine,
  RiFileCopyLine,
  RiCloseLine,
  RiDeleteBin6Line,
  RiEdit2Line,
  RiHomeLine,
  RiApps2Line,
  RiSettings3Line,
  RiLogoutBoxLine,
  RiInformationFill,
  RiErrorWarningFill,
  RiFileAddLine,
  RiBookMarkLine,
} from "react-icons/ri";
import { IoExtensionPuzzle, IoExtensionPuzzleOutline } from "react-icons/io5";

export enum Icons {
  DELETE = "delete",
  EDIT = "edit",
  SCHEDULE = "schedule",
  DRAGGABLE = "draggable",
  ADD = "add",
  CLOSE = "close",
  LINK = "link",
  COPY = "copy",
  INFO = "info",
  WARNING = "warning",
  HOME = "nav-home",
  ACTIVITIES = "nav-activities",
  MEETINGS = "nav-meetings",
  SETTINGS = "nav-settings",
  LOGOUT = "nav-logout",
  TEMPLATES = "templates",
  UNSOLVED = "unsolved",
  SOLVED = "solved",
  MAP = "map",
  COURSES = "course",
}

interface IconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: Icons;
  className?: string;
}

export const Icon: FunctionComponent<IconProps> = ({ icon, className }) => {
  switch (icon) {
    case Icons.DELETE:
      return <RiDeleteBin6Line className={className} />;
    case Icons.SCHEDULE:
      return <RiCalendarTodoLine className={className} />;
    case Icons.EDIT:
      return <RiEdit2Line className={className} />;
    case Icons.DRAGGABLE:
      return <FaGripVertical className={className} />;
    case Icons.ADD:
      return <RiAddLine className={className} />;
    case Icons.CLOSE:
      return <RiCloseLine className={className} />;
    case Icons.COPY:
      return <RiFileCopyLine className={className} />;
    case Icons.LINK:
      return <RiExternalLinkLine className={className} />;
    case Icons.HOME:
      return <RiHomeLine className={className} />;
    case Icons.ACTIVITIES:
      return <RiApps2Line className={className} />;
    case Icons.MEETINGS:
      return <RiCalendarTodoLine className={className} />;
    case Icons.SETTINGS:
      return <RiSettings3Line className={className} />;
    case Icons.LOGOUT:
      return <RiLogoutBoxLine className={className} />;
    case Icons.INFO:
      return <RiInformationFill className={className} />;
    case Icons.WARNING:
      return <RiErrorWarningFill className={className} />;
    case Icons.TEMPLATES:
      return <RiFileAddLine className={className} />;
    case Icons.UNSOLVED:
      return <IoExtensionPuzzleOutline className={className} />;
    case Icons.SOLVED:
      return <IoExtensionPuzzle className={className} />;
    case Icons.MAP:
      return <FaRegMap className={className} />;
    case Icons.COURSES:
      return <RiBookMarkLine className={className} />;
  }
};
