import React, { FunctionComponent, useEffect, useState } from "react";
import "./Participant.css";
import { AppUser } from "../../types/user";
import { subToUser } from "../../services/firebase";
import { DBDocument, DBDocumentID } from "../../types/document";
import { avatars, meeples } from "../../services/consts";
import { hashString } from "../../services/utils";
import { useGetActivityById } from "../../services/activities";
import { Loading } from "../Loading/Loading";
import { ActivityType } from "../../types/activities/activity";
import { Image } from "../Image/Image";

interface ParticipantProps extends React.HTMLAttributes<HTMLDivElement> {
  user: AppUser;
  activityId: DBDocumentID;
}

export const Participant: FunctionComponent<ParticipantProps> = ({
  user: userProp,
  className,
  activityId,
}) => {
  const avatarIdx = hashString(userProp.uid) % avatars.length;
  const [user, setUser] = useState<AppUser>(userProp);

  const { data: activity, isFetching: isFetchingActivity } =
    useGetActivityById(activityId);

  useEffect(() => {
    if (userProp?.uid) {
      const unsub = subToUser(userProp.uid, (doc) => {
        if (doc.exists()) {
          const user = doc.data() as DBDocument<AppUser>;
          setUser(user);
        }
      });
      return () => unsub();
    }
  }, [userProp?.uid]);

  if (isFetchingActivity) {
    return <Loading />;
  }

  return (
    <div
      className={`participant ${
        user?.displayName ? "opacity-100" : "opacity-25"
      } ${className || ""}`}
      title={user?.displayName || "Anonymous"}
    >
      {activity?.type === ActivityType.FREE_MAP && (
        <Image
          className="meeple-preview"
          src={meeples[hashString(user?.uid) % meeples.length]}
        />
      )}
      <div className="user-photo w-100 h-100">
        <Image
          className="w-100 h-100 rounded-4"
          src={user?.avatarUrl || avatars[avatarIdx]}
          alt={user?.displayName || "Anonymous"}
        />
      </div>
      {user && (
        <div className="identity z-index-lowest">
          <span>{user.displayName}</span>
        </div>
      )}
    </div>
  );
};
