import React, { FunctionComponent } from "react";
import "./Meeting.css";

type MeetingControlsContainerProps = React.HTMLAttributes<HTMLDivElement>;

export const MeetingControlsContainer: FunctionComponent<
  MeetingControlsContainerProps
> = ({ children }) => {
  return <div className="meeting-controls">{children}</div>;
};
