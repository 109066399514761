import React, { FunctionComponent, useState } from "react";
import "./OverlayButtons.css";
import { MAP_NUM_COLS, MAP_NUM_ROWS } from "../../services/consts";

export interface SelectionCoordinates {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
}

interface OverlayButtonsProps {
  onSelectionChange: (selection: SelectionCoordinates) => void;
}

export const OverlayButtons: FunctionComponent<OverlayButtonsProps> = ({
  onSelectionChange,
}) => {
  const [clickedButtons, setClickedButtons] = useState<boolean[][]>(
    Array.from({ length: MAP_NUM_COLS }, () => Array(MAP_NUM_ROWS).fill(false))
  );
  const [startX, setStartX] = useState<number | null>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const [endX, setEndX] = useState<number | null>(null);
  const [endY, setEndY] = useState<number | null>(null);

  const handleMouseMovement = (
    columnIndex: number,
    rowIndex: number,
    isOver: boolean
  ): void => {
    if (startX !== null && startY !== null && endX === null) {
      const newClickedButtons = [...clickedButtons];
      const minX = Math.min(columnIndex, startX);
      const maxX = Math.max(columnIndex, startX);
      const minY = Math.min(rowIndex, startY);
      const maxY = Math.max(rowIndex, startY);

      for (let x = minX; x <= maxX; x++) {
        for (let y = minY; y <= maxY; y++) {
          newClickedButtons[x - 1][y - 1] = isOver;
        }
      }
      setClickedButtons(newClickedButtons);
    }
  };

  const handleButtonClick = (columnIndex: number, rowIndex: number): void => {
    if (startX === null || startY === null) {
      setStartX(columnIndex);
      setStartY(rowIndex);
    } else if (endY === null) {
      setEndX(columnIndex);
      setEndY(rowIndex);
      onSelectionChange({ startX, startY, endX: columnIndex, endY: rowIndex });
    } else {
      resetSelection();
      setStartX(columnIndex);
      setStartY(rowIndex);
      setEndX(null);
      setEndY(null);
    }
  };

  const resetSelection = (): void => {
    const newClickedButtons = clickedButtons.map((row) => row.map(() => false));
    setClickedButtons(newClickedButtons);
  };

  const columns = Array.from({ length: MAP_NUM_COLS }, (_, i) => i + 1);
  const rows = Array.from({ length: MAP_NUM_ROWS }, (_, j) => j + 1);

  const buttons = columns
    .map((i) =>
      rows.map((j) => (
        <button
          style={{
            top: `${(j - 1) * (100 / MAP_NUM_ROWS)}%`,
            left: `${(i - 1) * (100 / MAP_NUM_COLS)}%`,
            width: `${100 / MAP_NUM_COLS}%`,
            height: `${100 / MAP_NUM_ROWS}%`,
            backgroundColor: clickedButtons[i - 1][j - 1]
              ? "rgba(255, 255, 255, 0.5)"
              : undefined,
          }}
          key={`button-${i}-${j}`}
          className="overlay-btn"
          onMouseOver={() => handleMouseMovement(i, j, true)}
          onMouseOut={() => handleMouseMovement(i, j, false)}
          onClick={(event) => {
            event.preventDefault();
            handleButtonClick(i, j);
          }}
        />
      ))
    )
    .flat();

  return <>{buttons}</>;
};
