import React, { FunctionComponent } from "react";

type InputTextProps = React.InputHTMLAttributes<HTMLInputElement>;

export const InputText: FunctionComponent<InputTextProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <input
      type="text"
      className={`form-control text-truncate ${className}`}
      {...props}
    >
      {children}
    </input>
  );
};
